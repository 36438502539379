/* eslint-disable max-len */
import styled from "@emotion/styled";
import Icon from "components/Icon";
import { Column, Row } from "components/Layout";
import useReportPageEvents from "hooks/useReportPageEvents";
import { useNavigate } from "react-router-dom";
import colors from "styles/colors";

const PrivacyPolicyWrapper = styled.div`
  flex: 1;
  width: 100%;
  background: ${colors.white100};
  color: ${colors.black80};
  padding: 1rem;
  font-size: 14px;
  overflow-y: scroll;

  > h1,
  h2,
  h3,
  h4 {
    color: ${colors.black80};
  }

  thead td {
    font-weight: bold;
  }
`;

export function PrivacyPolicyPage() {
  const navigate = useNavigate();
  useReportPageEvents();

  return (
    <Column style={{ height: "100%" }}>
      <Row align="ml" padding="1.5rem 1rem" gap="1rem">
        <Icon name="arrow-left" onClick={() => navigate(-1)} />
        <h3>Privacy Policy</h3>
      </Row>

      {/* prettier-ignore */}
      <PrivacyPolicyWrapper>
        <h1>GamePortal Privacy Policy</h1>

        <p><b>Effective Date: June 25, 2023</b></p>

        <p><b>Table of Contents:</b></p>

        <ol type="A">
          <li>About Us.</li>
          <li>Scope.</li>
          <li>Information We Collect.</li>
          <li>How We Use the Information We Collect.</li>
          <li>Maximum Information Retention Period.</li>
          <li>How We Share Information.</li>
          <li>How to Control Information.</li>
          <li>Children.</li>
          <li>State Privacy Rights.</li>
          <li>Changes to This Privacy Policy.</li>
          <li>Contact Us.</li>
        </ol>

        <ol>
          <li>
            <h2>About Us</h2>
          </li>
        </ol>

        <p>GamePortal (the "App") is an app operated by ironSource Ltd. The App provides users with an app store focused on mobile games which users can download to their devices.</p>

        <ol>
          <li>
            <h2>Scope</h2>
          </li>
        </ol>

        <p>This Privacy Policy describes our privacy practices with respect to information we process through your use of the App.</p>

        <p>If you are a resident of California or Virginia, please see "State Privacy Rights" section of this privacy policy for information related to to privacy laws in these states.</p>

        <p>
          For purposes of the California Consumer Privacy Act (the "CCPA"), this Privacy Policy serves as both the Privacy Policy and Notice at Collection of Personal Information.
        </p>

        <ol>
          <li>
            <h2>Information We Collect</h2>
          </li>
        </ol>

        <ol>
          <li>
            <b>Information collected by the App -</b>
          </li>
        </ol>

        <p><u>Device identifiers</u>: Advertising ID, an ID unique to the App, and IP address. The term Advertising ID refers to the Google Advertising ID. The Advertising ID is a resettable persistent identifier generated by Google.</p>
        <p><u>Device information</u>: The App collects technical information about a device, such as the device CPU, device manufacturer, device model, device RAM, device available storage, device locale, network type (wifi/cellular data), operating system version, SIM MCC MNC, network operator name, and other general technical details about a device.</p>

        <p><u>App usage</u>: The App collects data about your use of the app, included searches you performed from within the App.</p>
        <p><u>Age and Gender</u>: We may ask you for your general age and gender. You are not required to provide this information in order to use the App, but this may allow us to provide you with more relevant app recommendations.</p>

        <ol>
          <li>
            <h2>How We Use the Information We Collect:</h2>
          </li>
        </ol>

        <p>
          In order to provide the service, we use the information we collect in different ways. Below is a general description of how we use information across our services:
        </p>

        <ol>
          <li>
            <b>Providing you with the services included in the App</b> - we use information to provide you with services that are part of the App.
          </li>
        </ol>

        <ol>
          <li>
            <b>Customization of the App </b> - We use information collected from a device to ensure that the experience delivered via the App is customized to a device type, and country.
          </li>
        </ol>

        <ol>
          <li>
            <b>Analytics </b> - We use information to generate aggregated statistics about the usage of the App.
          </li>
        </ol>

        <ol>
          <li>
            <b>Internal Operations </b> - We use information for internal operations, such as debugging, support, security, providing updates to our application and improving our services and the App.
          </li>
        </ol>

        <ol>
          <li>Compliance with legal obligations - We may use information to comply with applicable legal obligations.</li>
        </ol>

        <ol>
          <li>
            <h2>Maximum Information Retention Period:</h2>
          </li>
        </ol>

        <p>
          We retain information for as long as necessary to fulfil the purposes for which we collected such information, except if required otherwise by law. We may store information for longer periods for archiving or statistical purposes in which case we will take appropriate measures to ensure that the information is used solely for this purpose.
        </p>

        <ol>
          <li>
            <h2>How We Share Information:</h2>
          </li>
        </ol>

        <ol>
          <li>
            <b>Our corporate affiliates </b> - from which we receive services, such as IT, security, storage, delivery of campaigns, and other internal operations.
          </li>
        </ol>

        <ol>
          <li>
            <b>Service providers </b> - We share information with our service providers for the purpose of receiving services such as reporting, and storage.
          </li>
        </ol>

        <p>
          We may also disclose information if we have a good faith belief that disclosure of such information is reasonably necessary to: (i) comply with any applicable law, regulation, legal process or governmental request; (ii) enforce our agreements and policies, including investigations of potential violations thereof; (iii) investigate, detect, prevent, or take action regarding illegal activities or other wrongdoing, suspected fraud or security issues; (iv) to establish or exercise our rights to defend against legal claims; (v) prevent harm to the rights, property or safety of us, our users, yourself or any third party; (vi) for the purpose of collaborating with law enforcement agencies or in case we find it necessary in order to enforce intellectual property or other legal rights; or (vii) when we are undergoing any change in control, including by means of merger, acquisition or purchase of all or substantially all of our assets.
        </p>

        <ol>
          <li>
            <h2>How You Can Control Information:</h2>
          </li>
        </ol>

        <p>
          <b>Erasure and access requests</b> - You may submit deletion and access requests through the following process: Go to Settings -&gt; Apps -&gt; Application List -&gt; GamePortal -&gt; Personal Data. Please note that we will process your request subject to verification and authentication of your identity.
        </p>

        <ol>
          <li>
            <h2>Children</h2>
          </li>
        </ol>

        <p>
          If you believe your child has provided us with personal information, you may contact us at <a href="mailto:games-support@is.com">games-support@is.com</a> and we will make reasonable efforts to delete the information from our records.
        </p>

        <ol>
          <li>
            <h2>
              <b>State Privacy Rights</b>
            </h2>
          </li>
        </ol>

        <p> California and Virginia residents have certain rights under state privacy laws with respect to personal data we collect. If you are a resident of such states, this section of the privacy policy contains disclosures required by law and explains rights that may be available to you.
        </p>
        <p>
          This section applies only to personal data that is subject to the state laws noted above.
        </p>

        <p>
          <b>Personal Data Collected and Disclosed</b>: In the past 12 months, we have collected, and disclosed for business purposes the following categories of personal data. We do not knowingly sell, nor do we share or process for targeted advertising purposes, personal data about consumers under the age of 16.
        </p>

        <table>
          <thead>
            <tr>
              <td>Categories of Personal InformationData</td>
              <td>Disclosed for business purposes to the following categories of third parties:</td>
              <td>Sold to the following categories of third parties (including sharing for targeted advertising purposes):</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Personal and online identifiers (such as first and last name, email address, or unique online identifiers)</td>
              <td>See the "How We Share Information" Section</td>
              <td>Not sold.</td>
            </tr>
            <tr>
              <td>Commercial or transactions information (like records of products or services purchased, obtained, or considered)</td>
              <td>See "How We Share Information" Section</td>
              <td>Not sold.</td>
            </tr>
            <tr>
              <td>Internet or other electronic network activity information (such as browsing history, search history, interactions with a website, email, application, or advertisement)</td>
              <td>See the "How We Share Information" Section</td>
              <td>Not sold.</td>
            </tr>
            <tr>
              <td>Geolocation information</td>
              <td>See the "How We Share Information" Section</td>
              <td>Not sold.</td>
            </tr>
            <tr>
              <td>Other information about you that is linked to the personal information above</td>
              <td>See the "How We Share Information" Section</td>
              <td>Not sold</td>
            </tr>
          </tbody>
        </table>

        <p>
          <b>Categories of Sources</b> - As described in the "About Us" section above, we collect personal data from the following categories of sources:
        </p>

        <p>Directly through the App.</p>
        <p>
          <b>Our Purposes for Processing Personal Data</b> - We use and disclose the personal data we collect as further described above in the "How We Use the Information We Collect"and the "How We Share Information" Sections.
        </p>

        <p>
          <b>Recipients of Personal Data</b> - We share personal data with the following third parties:
        </p>
        <p>App developers.</p>

        <p>
          <b>Your Rights Regarding Personal Data</b> - Residents of certain states have rights with respect to the personal data collected by service providers like ironSource. You may be able to exercise the following rights regarding your personal data, subject to certain exceptions and limitations:
        </p>

        <ul>
          <li>
            The right to confirm whether we are processing personal data about you.
          </li>
          <li>
            The right to correct inaccuracies in the personal data we have collected about you.
          </li>
          <li>
            The right to know the categories and specific pieces of personal data we collect, use, and disclose, the categories of sources from which we collected your personal data, our purposes for collecting or selling your personal data, the categories of your personal data that we have disclosed for our business purposes, and the categories of third parties with which we have disclosed personal data.
          </li>
          <li>
            The right to request that we delete the personal data we have collected from you or maintain about you.
          </li>
          <li>
            The right not to receive discriminatory treatment for the exercise of the above privacy rights.
          </li>
        </ul>

        <p>
          While some states provide residents with rights related to "sensitive" personal data, ironSource does not collect this type of personal data.
        </p>

        <p>
          <b>Exercise your rights</b> - To exercise any of the above rights,
          please contact us at the following email address:
          <a href="mailto:games-support@is.com">games-support@is.com</a>.
          Further, you may submit deletion and access requests through the
          following process: Go to Settings -&gt; Apps -&gt; Application List
          -&gt; GamePortal -&gt; Personal Data. Please note that we will process
          your request subject to verification and authentication of your
          identity.
        </p>

        <p>
          Depending on your state, if you have submitted a request that you
          believe we have not fulfilled, you may contact us to appeal our
          decision by replying to our response to your request, or by contacting
          us at
          <a href="mailto:games-support@is.com">games-support@is.com</a>.
        </p>

        <p>
          <b>Verification Process and Required Information</b> - Note that we
          may need to request additional information from you to verify your
          identity or process your request, although you will not be required to
          create an account with us to submit a request or have it fulfilled. We
          will require you to provide, at a minimum, your email address and
          state of residence. We will also collect information that identifies
          your device when you submit a request, in order to help verify your
          identity.
        </p>

        <p>
          <b>Authorized Agent</b> - You may designate an authorized agent to
          make a CCPA request on your behalf by verifying your identity and
          providing the agent with written permission to make the request on
          your behalf.
        </p>

        <ol>
          <li>
            <h2>Changes to This Privacy Policy:</h2>
          </li>
        </ol>

        <p>
          We may revise this Privacy Policy from time to time. The most current
          version of the policy will govern our processing of your information
          and can be found by going to Settings -&gt; Apps -&gt; Application
          List -&gt; GamePortal -&gt; Privacy Policy. By continuing to access or
          use an app containing our services after those changes become
          effective, you agree to be bound by the revised Privacy Policy.
        </p>

        <ol>
          <li>
            <h2>Contact Us - Data Protection and Privacy</h2>
          </li>
        </ol>

        <p>ironSource Ltd.</p>
        <a href="mailto:games-support@is.com">games-support@is.com</a>
        <p>121 Menachem Begin Rd., Tel Aviv, Israel</p>
      </PrivacyPolicyWrapper>
    </Column>
  );
}
