import { AnimatePresence, motion } from "framer-motion"
import _, { times } from "lodash"
import { reportConversion } from "modules/reporting"
import {
  createRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { AppInfo } from "types"
import { GlobalStateContext } from "../GlobalState"
import BackBtn from "./BackBtn"
import { Row } from "./Layout"
import css from "./ScreenshotGallery.module.scss"
import Skeleton from "./Skeleton"

interface ScreenshotGalleryProps {
  app?: AppInfo
  images?: string[]
  padding?: string
}

function getImageRefs(images: string[]) {
  return _.fromPairs(images.map(k => [k, createRef<HTMLImageElement>()]))
}


const ScreenshotGallery = ({ app, images, padding = "0 1.5rem lg" }: ScreenshotGalleryProps) => {
  const { getCampaignForApp } = useContext(GlobalStateContext)
  const galleryImages = images?.length ? images : times(5, i => i.toString())
  const { setShouldScroll } = useContext(GlobalStateContext)
  const [selectedImage, setSelectedImage] = useState<string>()
  const [shouldObserveImages, setShouldObserveImages] = useState(true)
  const previewImageWrapperRef = useRef<HTMLDivElement>(null)
  const imageRefs = useMemo(() => getImageRefs(galleryImages), [galleryImages])
  const previewImageRefs = useMemo(
    () => getImageRefs(galleryImages),
    [galleryImages]
  )

  // Scroll to selected image
  useEffect(() => {
    if (selectedImage) {
      imageRefs[selectedImage].current?.scrollIntoView({
        behavior: "instant",
        block: "center",
      })
    }
  }, [selectedImage, imageRefs])

  useEffect(() => {
    setShouldObserveImages(false)
    _.each(previewImageRefs, img => {
      new ResizeObserver((event, observer) => {
        if (!img.current) return
        if (img.current.naturalWidth > 0) {
          observer.disconnect()
          previewImageWrapperRef.current!.scrollLeft = 0
        }
      }).observe(img.current as HTMLImageElement)
    })
  }, [
    galleryImages,
    previewImageWrapperRef,
    _.every(previewImageRefs, img => !!img.current),
    shouldObserveImages,
  ])

  // Handle back button - close gallery if it's open
  useEffect(() => {
    setShouldScroll(!selectedImage) // disable scroll when gallery is open

    function handlePopState() {
      if (selectedImage) {
        window.history.forward()
        closeGallery()
      }
    }

    window.addEventListener("popstate", handlePopState)

    return () => {
      window.removeEventListener("popstate", handlePopState)
    }
  }, [selectedImage, setShouldScroll])

  function openGallery(img: string) {
    setSelectedImage(img)
    if (app) {
      const campaign = getCampaignForApp(app.id)
      reportConversion(app.packageName, "game page screenshot clicked", {
        app,
        campaign,
      })
    }
  }

  function closeGallery() {
    setSelectedImage(undefined)
  }

  return (
    <>
      <Row
        className={css.screenshotGallery}
        padding={padding}
        gap={14}
        ref={previewImageWrapperRef}
      >
        {!images?.length &&
          galleryImages.map(i => (
            <Skeleton key={i} className={css.img} variant="rounded">
              <img
                style={{ width: 512, height: 288 }}
                ref={previewImageRefs[i]}
              />
            </Skeleton>
          ))}
        {images?.length &&
          images.map((img, i) => (
            <img
              className={css.img}
              key={i}
              src={img}
              alt=""
              referrerPolicy="no-referrer"
              onClick={() => openGallery(img)}
              ref={previewImageRefs[img]}
            />
          ))}
      </Row>

      <AnimatePresence>
        {selectedImage && (
          <motion.div
            className={css.fullScreenshotGallery}
            initial={{ opacity: 0, scale: 0.95, filter: "blur(10px)" }}
            animate={{ opacity: 1, scale: 1, filter: "blur(0px)" }}
            exit={{ opacity: 0, scale: 0.95, filter: "blur(10px)" }}
          >
            <BackBtn className={css.backBtn} onClick={closeGallery} />

            <Row className={css.images} padding="0 0 0 1.5rem" gap={18} col={1}>
              {images?.map((img, i) => (
                <img
                  key={i}
                  alt={i.toString()}
                  className={css.img}
                  src={img}
                  ref={imageRefs[img]}
                />
              ))}
            </Row>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default ScreenshotGallery
