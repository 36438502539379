import CTABtn from "components/CTABtn"
import { Column } from "components/Layout"
import { Subtitle } from "components/base/Typography"
import Modal from "react-modal"
import { useNavigate } from "react-router-dom"
import colors from "styles/colors"

interface PrivacyManagementModalProps {
  modalOpen: boolean
  email: string
  type: string
}

export default function PrivacyManagementModal({ email, modalOpen, type }: PrivacyManagementModalProps) {
  const navigate = useNavigate()

  return <Modal
    isOpen={modalOpen}
    style={{
      overlay: { padding: '1.5rem' },
      content: { padding: '2rem', textAlign: 'center', borderRadius: '1.25rem' }
    }}
  >
    <Column gap={22}>
      <Column gap={8}>
        <Subtitle size="l">{type === 'delete' ? 'Deletion request received' : 'Data request received'}</Subtitle>
        <Subtitle style={{ color: colors.textLight }}>{`An email has been sent to ${email} with instructions`}</Subtitle>
      </Column>
      <CTABtn type="large" onClick={() => navigate('/discover')} style={{ margin: '0 58px' }}>Ok, got it!</CTABtn>
    </Column>
  </Modal>
}