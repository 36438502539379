import { CircularProgress } from "@mui/material"
import { generateDataTestId } from "modules/utils"

export default function Loader({ style, dataTestId }: {
  style?: React.CSSProperties
  dataTestId?: string
}) {
  return (
    <div
      // @ts-ignore
      col={1}
      style={{ alignSelf: "center", width: "100%", height: "100%", ...style }}
      sf="mc"
      data-testid={generateDataTestId({ prefix: "Loader", dataTestId })}
    >
      <CircularProgress />
    </div>
  )
}
