import * as Sentry from "@sentry/react";
import * as React from "react";
import ReactDOM from "react-dom/client";
import { log } from "./modules/logs";
import * as utils from "./modules/utils";
import reportWebVitals from "./reportWebVitals";
import theme from "./styles/material-theme";

// Theme
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

// App
import { GlobalState } from "GlobalState";
import ErrorGeneral from "components/errors/ErrorGeneral";
import { inRange } from "lodash";
import { getClientDescriptor } from "modules/communication/communication";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import preloadAssets from "./modules/assetsPreload";
import * as nativeAPI from "./modules/nativeAPI";

// Allow Sentry in staging and production only
let dsn = undefined;
let sentryIntegrations = [];

if (utils.isStaging() || utils.isProd()) {
  dsn =
    "https://b7460897d21f41bf9d6dde9185d72381@o488710.ingest.sentry.io/4505464835866624";

  // BrowserTracing
  if (localStorage.__enableSentryBrowserTracing) {
    sentryIntegrations.push(
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          "localhost",
          /^http:\/\/games-api\.gems.io/,
          /^https:\/\/games-api\.isappcloud.com/,
        ],
      }),
    );
  }
  if (localStorage.__enableSentryReplay) {
    sentryIntegrations.push(new Sentry.Replay());
  }
}
Sentry.init({
  dsn: localStorage.__sentryDSN || dsn,

  // Version and Environment identification
  environment: utils.envName(),
  release: `gamestore-client@${process.env.REACT_APP_VERSION || "0.0.0"}`,

  //
  integrations: sentryIntegrations,
  // Session Replay - Not Approved for use in the meanwhile
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  // Performance Monitoring
  // TODO: Reduce production sample rate when we finish the MVP
  tracesSampleRate: utils.isProd() ? 1.0 : 1.0, // Capture 100% of the transactions, reduce in production!
  sampleRate: utils.isProd() ? 1.0 : 1.0, // Capture 100% of the errors

  beforeSend(event, hint) {
    const error = hint.originalException as any;
    if (error?.httpStatusCode && !inRange(error?.httpStatusCode, 400, 499)) {
      return null;
    }
    return event;
  },

  // Filter our breadcrumbs
  beforeBreadcrumb(breadcrumb, hint) {
    const { category, data } = breadcrumb;
    // Ignore preload fetch requests
    if (category === "fetch" && data?.url.startsWith("/")) return null;

    return breadcrumb;
  },

  ...JSON.parse(localStorage.__setryConfigOverrides || "{}"),
});

// TODO: FIX THIS: https://stackoverflow.com/questions/62810553/how-to-fix-trustedhtml-assignment-error-with-angular-innerhtml
if (window.trustedTypes && window.trustedTypes.createPolicy) {
  window.trustedTypes.createPolicy("default", {
    createHTML: (string, sink) => string,
  });
}

preloadAssets()
  .then(nativeAPI.init)
  .then(({ packages, deliveries }) => {
    console.log("hi from preloadAssets");
    // set user on sentry
    Sentry.setUser({
      id: getClientDescriptor().uid,
    });

    // Initialize App
    const rootElement = document.getElementById("root");
    if (!rootElement) throw new Error("Failed to find the root element");
    const root = ReactDOM.createRoot(rootElement);

    root.render(
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack, resetError }) => (
          <ErrorGeneral
            error={error}
            componentStack={componentStack}
            resetError={resetError}
          />
        )}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <GlobalState packages={packages} deliveries={deliveries}>
              <React.StrictMode>
                <App />
              </React.StrictMode>
            </GlobalState>
          </BrowserRouter>
        </ThemeProvider>
      </Sentry.ErrorBoundary>,
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (utils.isDev()) {
  reportWebVitals(log);
}
