import {
  Elements,
  LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsync, useEffectOnce } from "react-use";
import BackBtn from "../components/BackBtn";
import CTABtn from "../components/CTABtn";
import { Column, Row } from "../components/Layout";
import { closeWindow } from "../modules/nativeAPI";
import {
  StoreItem,
  createPaymentIntent,
  getIapItems,
} from "../modules/paymentsApi";
import colors from "../styles/colors";
import { IApItemCardLarge } from "./AppIAPStorePage";

const stripePromise = loadStripe(
  "pk_test_51NdVxODKJrhMp06fXRRnVUY2Oh5XP0wrEkGk8bbNIKDU7avVJpHoWxzMQ8z1gXbNAYXmAPPgq6fwLb7cgKhxLBxH00NSv7khyK",
);

export function StripeCheckout() {
  const queryParams = new URLSearchParams(window.location.search);
  const isVipStore = queryParams.get("vipStore") === "true";
  const navigate = useNavigate();

  const { itemId, playerUid } = useParams();

  useEffectOnce(() => {
    if (sessionStorage.purchaseSucceeded === "true") {
      sessionStorage.removeItem("purchaseSucceeded");
      navigate(-1);
    }
  });

  const { loading, error, value } = useAsync(async () => {
    const items: StoreItem[] = await getIapItems(9999, isVipStore);
    const playerUidParsed = parseInt(playerUid!);
    const itemIdParsed = parseInt(itemId!);

    const paymentIntentResponse = await createPaymentIntent(
      itemIdParsed!,
      playerUidParsed,
    );
    const item = items.find((item: StoreItem) => item.id === parseInt(itemId!));
    return { item, ...paymentIntentResponse };
  });

  // @ts-ignore
  const item = value?.item;
  if (error) {
    return <>Error</>;
  }
  if (loading) {
    return (
      <Column style={{ width: "100%", padding: "24px" }} gap={16}>
        <Row align={"sb"} style={{ width: "100%" }}>
          <BackBtn
            style={{ boxShadow: "none" }}
            backgroundColor={colors.transparent}
          ></BackBtn>
        </Row>

        <IApItemCardLarge
          item={undefined}
          onCardClicked={item => console.log("clicked" + item?.id)}
          appId={"9999"}
          buyBtnDisabled={true}
        ></IApItemCardLarge>
      </Column>
    );
  }
  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: value?.clientSecret,

        appearance: {
          theme: "stripe",
          labels: "above",
          variables: {
            colorPrimary: "#0570de",
            colorBackground: "#2D2552",
            colorText: "#D5CEFF",
            colorDanger: "#df1b41",
            fontFamily: "Ideal Sans, system-ui, sans-serif",
            spacingUnit: "4px",
            borderRadius: "8px",
            // See all possible variables below
          },
        },
      }}
    >
      <CheckoutForm item={item} purchaseGuid={value?.purchaseGuid} />
    </Elements>
  );
}

function CheckoutForm({
  item,
  purchaseGuid,
  skeleton = false,
}: {
  item?: StoreItem;
  purchaseGuid?: string;
  skeleton?: boolean;
}) {
  const isOnGame = window.location.pathname.includes("on_game");
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!item) return;

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    //current domain
    const domain = window.location.origin;
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${domain}/app/9999/store/purchase_res/${purchaseGuid}${
          isOnGame ? "/on_game" : ""
        }?success=true&iapItemId=${item.id}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error?.type === "card_error" || error?.type === "validation_error") {
      setMessage(error.message ?? "An unexpected error occurred.");
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const onExitButtonClicked = () => {
    closeWindow();
  };

  return (
    <Column style={{ width: "100%", padding: "24px" }} gap={16}>
      <Row align={"sb"} style={{ width: "100%" }}>
        <BackBtn
          style={{ boxShadow: "none" }}
          backgroundColor={colors.transparent}
        ></BackBtn>
      </Row>

      <IApItemCardLarge
        item={item}
        onCardClicked={item => console.log("clicked" + item?.id)}
        appId={"9999"}
        buyBtnDisabled={true}
      ></IApItemCardLarge>
      <form id="payment-form" onSubmit={handleSubmit}>
        <LinkAuthenticationElement
          options={{
            defaultValues: { email: "no@email.com" },
          }}
        ></LinkAuthenticationElement>
        <div style={{ height: "16px" }}></div>

        <PaymentElement
          id="payment-element"
          options={{
            layout: "tabs",
            fields: {
              billingDetails: {
                name: "auto",
                address: {
                  country: "auto",
                },
              },
            },
          }}
        />

        <CTABtn
          type={"large"}
          style={{
            width: "100%",
            pointerEvents: isLoading ? "none" : "auto",
            marginTop: "24px",
          }}
          // onClick={onClickHandler}
          iconRight={isLoading ? "progress" : "/img/ic_lock.svg"}
          iconSize={22}
        >
          {isLoading ? "Processing..." : "Pay"}
        </CTABtn>
      </form>
      {message && <div id="payment-message">{message}</div>}
    </Column>
  );
}
