import { CSSProperties } from "react"
import { useNavigate } from "react-router-dom"
import colors from "styles/colors"
import Icon from "./Icon"

interface BackBtnProps {
  className?: string
  style?: CSSProperties
  onClick?: () => void
  backgroundColor?: string
}

function BackBtn({ className, style, onClick, backgroundColor }: BackBtnProps) {
  const navigate = useNavigate()

  return (
    <div
      className={className}
      style={{
        width: 36,
        height: 36,
        borderRadius: "50%",
        ...style,
      }}
      onClick={() => (onClick ? onClick() : navigate(-1))}
      data-testid="back-btn"
    >
      <Icon
        name="arrow-left"
        bg="circle"
        color="white"
        bgColor={backgroundColor || colors.backgroundDark}
        size={36}
      />
    </div>
  );
}

export default BackBtn
