import Modal from "react-modal"
import colors from "styles/colors"

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement(document.getElementById("root") as HTMLElement)
Modal.defaultStyles.overlay = {
  backgroundColor: `${colors.black80}`,
  padding: "1rem",
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}

Modal.defaultStyles.content = {
  WebkitOverflowScrolling: "touch",
  outline: "none",
  overflow: "auto",
  backgroundColor: colors.cardsBackground,
  boxShadow: `0 0 1rem 1px ${colors.black50}`,
  borderRadius: "1rem",
  padding: "2rem",
  width: "100%",
  maxWidth: 320,
}
