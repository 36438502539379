import { Tab, Tabs } from "@mui/material"
import { CardType, Layout } from "consts"
import { SyntheticEvent, useState } from "react"
import { IApp, ICollection, ITab } from "types"
import Collection from "./Collection"

interface PageTabsLayoutProps {
  tab: ITab
  collections: ICollection[]
  loading?: boolean
}

const PageTabsLayout = ({ tab, collections, loading }: PageTabsLayoutProps) => {
  const [currentTab, setTab] = useState(0)
  const handleTabChange = (_e: SyntheticEvent, val: number) => setTab(val)

  const collection = collections[currentTab];

  return (
    <>
      {collections.length > 1 && (
        <Tabs value={currentTab} onChange={handleTabChange} centered>
          {collections.map((collection: any, i: number) => (
            <Tab key={i} label={collection.title} value={i} />
          ))}
        </Tabs>
      )}

      <div
        style={{ height: "100%", overflowY: "scroll", padding: "0.75rem 0 0" }}
      >
        <Collection
          showTitle
          id={collection.id}
          total={collection.total}
          limit={collection.limit}
          title={collection.title}
          layout={Layout.CHART}
          cardType={CardType.DEFAULT}
          placements={collection.placements as IApp[]}
          // reporting
          tab={tab}
          collection={collection}
          loading={loading}
        />
      </div>
    </>
  )
}

export default PageTabsLayout
