import { RefObject, useEffect, useState } from "react"

export default function useScrollIntoView(elRef?: RefObject<HTMLDivElement>) {
  const [isInView, setIsInView] = useState(false)

  useEffect(() => {
    const el = elRef?.current
    if (!el) return

    const observer = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        setIsInView(isIntersecting)
      },
      {
        threshold: [1],
      }
    )

    observer.observe(el)

    // 👇️ remove the event listener when component unmounts
    return () => {
      observer.unobserve(el)
    }
  }, [elRef])

  return isInView
}
