
import { CHANNELS, EVENTS, REPONSE_TYPE, ROUTES, STREAMS } from "consts"
import _ from "lodash"
import { IDelivery, IDeliveryToken, IInstalledPackage } from "types"
import { NativeClientError } from "./communication"

// prettier-ignore
const clientDescriptor = { ll: ["en_US"], rt: true, dmo: "sdk_gphone64_arm64", clto: "3", dp: "sdk_gphone64_arm64", auuid: "de316de4-b913-4c1f-8fcf-2fc9f6efe61d", cvc: "10200000", uid: "93a0544b-e888-4369-9318-6c1fb6050084", osv: "12", son: "T-Mobile", puid: "de316de4-b913-4c1f-8fcf-2fc9f6efe61d", dpd: 2.625, osfp: "google/sdk_gphone64_arm64/emulator64_arm64:12/SE1B.220616.004/9527646:userdebug/dev-keys", svc: "305021", sv: "3.5.1.11", dcsa: ["arm64-v8a"], os: "Android", d: "emulator64_arm64", osvc: "32", clt: "1681029872556", apiv: 20150330, cv: "1.0.0.0.1", dma: "Google", db: "google", cid: "com.aura.gamestore", no: "T-Mobile", dts: "6240665600", smm: "310260", nt: "wifi", das: "5440233472", so: "T-Mobile", l: "en_US", dr: "MemTotal: 2013212 kB", nmm: "310260", pr: "gamestore", prvn: "1.0.0.0.1", prvc: 10000001, ab: "hutchison", ac: "hutchison", ae: "androids_2", dabt: "827", grt: "214", vrt: "958", obb_supported: false, af: "gamestore", ajsias: true, afsias: true, sis: false, gaid: "a5cf13b1-477d-4cb7-9809-e8f4250fd7d2", late: "false", auid: "999999999-9999-9999-9999-999999999999" }
const MOCK_API_VERSION = 10201

export let deviceState: {
  mockingSpeed: number
  autoGrantPermissions: boolean

  networkType: string
  grantedPermissions: string[]
  totalStorage: number
  usedStorage: number
  installedPackages: IInstalledPackage[]
  deliveries: IDelivery[]
  analytics: {
    mapping?: any
    userProperties: { [key: string]: string }
    sessionProperties: { [key: string]: string }
  }
}

export function resetState(overrides: Partial<typeof deviceState> = {}) {
  deviceState = {
    mockingSpeed: 1000,
    autoGrantPermissions: true,

    networkType: "wifi",
    grantedPermissions: [],
    totalStorage: 1000000000, // 1GB
    usedStorage: 100000000, // 100MB
    installedPackages: [
      {
        packageName: "com.installed.package",
        versionCode: 1000,
      },
    ],
    deliveries: [],
    analytics: {
      userProperties: {},
      sessionProperties: {},
    },
    ...overrides,
  }
}

// passed in by communication.js module in the init function
export let handleMockMassage: Function

export async function init(handleMockMassageHandler: Function) {
  if (!deviceState) resetState()

  handleMockMassage = handleMockMassageHandler
  dispatchMockEvent({
    route: ROUTES.INIT,
    apiVersion: MOCK_API_VERSION,
    clientDescriptor,
  })
}

export function dispatchMockEvent(messageContent: any) {
  handleMockMassage({
    ports: [
      {
        postMessage: postMessageHandler,
      },
    ],
    data: JSON.stringify({
      communicationChannelName: CHANNELS.MAIN,
      messageContent,
    }),
  })
}

function postMessageHandler(msg: string) {
  // we wrap it in a timeout to simulate async
  setTimeout(() => {
    const { route, requestId, data: params } = JSON.parse(msg)

    let success = true
    let res
    try {
      const mockResponseData = mockResponses[route](params)
      res = {
        data: mockResponseData,
      }
    } catch (error) {
      success = false
      res = {
        err: {
          code: (<NativeClientError>error).code,
          msg: (<NativeClientError>error).message,
        },
      }
    }

    dispatchMockEvent({
      success,
      route,
      requestId,
      ...res,
    })
  }, 10)
}

export const mockResponses: { [key: string]: Function } = {
  [ROUTES.DISPLAY_INFO]() {
    return { statusBarHeight: 30 };
  },

  [ROUTES.OPEN_STORE](params: { deeplink_path: string }) {
    if (process.env.NODE_ENV === "test") return {}; // Skip in tests
    else if (window && window.open) {

      const deeplinkPath = params.deeplink_path
      console.log("open store url", deeplinkPath)
      window.open('/' + deeplinkPath, "_blank");
    }
    return {}
  },

  [ROUTES.OPEN_URL](params: { url: string }) {
    try {
      // Check if url is valid
      if (new URL(params.url)) {
        if (process.env.NODE_ENV === "test") return {} // Skip in tests
        else if (window && window.open) window.open(params.url, "_blank")
      }
      return {}
    } catch (e) {
      throw new NativeClientError(100, "Invalid url")
    }
  },

  //
  [ROUTES.DEVICE_NETWORK]() {
    return { network: { type: deviceState.networkType } }
  },

  //
  [STREAMS.PACKAGE_STATUS](params: { packages?: string[] }) {
    return {
      packages: deviceState.installedPackages,
    }
  },

  //
  [STREAMS.PACKAGE_DELIVERY_STATUS](params: { packages?: string[] }) {
    return {
      deliveries: deviceState.deliveries,
    }
  },

  //
  [ROUTES.PACKAGE_DELIVER](params: {
    token: string // (base64-json string)
    reportProps: any
    deliveryProps: {
      installingMessage: string
      installSuccessMessage: string
      allowedOverMobileData: boolean
      allowedOverRoaming: boolean
    }
  }) {
    const { delivery_methods, catalog_app_id: packageName } = <IDeliveryToken>(
      JSON.parse(atob(params.token))
    )
    const { version_code: versionCode, size } = delivery_methods[0].properties

    // simulate delivery error
    if (packageName === "error-out") {
      throw new NativeClientError(200, "DELIVERY_ERROR")
    }

    // simulate insufficient storage
    if (size + deviceState.usedStorage > deviceState.totalStorage) {
      throw new NativeClientError(201, "INSUFFICIENT_STORAGE")
    }

    // delivery steps, each will be dispatched in a delay to simulate the real flow
    // Mock the delivery based on the packageName
    let flow = [
      ["queued"],
      ["downloading", 0],
      ["downloading", 0.33],
      ["downloading", 0.66],
      ["downloading", 1],
      ["installing"],
      ["installed"],
    ]

    if (packageName === "com.install.failed") {
      flow = [
        ["queued"],
        ["downloading", 0],
        ["downloading", 0.33],
        ["downloading", 0.66],
        ["downloading", 1],
        ["installing"],
        ["failed"],
      ]
    } else if (packageName === "com.install.cancelled") {
      flow = [
        ["queued"],
        ["downloading", 0],
        ["downloading", 0.33],
        ["cancelled"],
      ]
    } else if (packageName === "com.install.error") {
      flow = [
        ["queued"],
        ["downloading", 0],
        ["downloading", 0.33],
        ["downloading", 0.66],
        ["error"],
      ]
    }

    flow.forEach((status, index) => {
      setTimeout(() => {
        const type = status[0]
        const progress =
          status[1] !== undefined
            ? {
              totalSizeInBytes: size,
              downloadedSizeInBytes: size * <number>status[1],
            }
            : undefined

        dispatchMockEvent({
          route: STREAMS.PACKAGE_DELIVERY_STATUS,
          requestId: `${REPONSE_TYPE.STREAM}:${EVENTS.DELIVERY_STATUS}`,
          data: {
            deliveries: [
              {
                packageName,
                versionCode,
                status: {
                  type,
                  progress,
                },
              },
            ],
          },
        })
      }, index * deviceState.mockingSpeed)
    })

    return {}
  },

  //
  [ROUTES.PACKAGE_DELIVERY_CANCEL](params: { packageName: string }) {
    // Params: {"packageName":"com.android.chrome"}}

    if (params.packageName === "mid.install.package")
      throw new NativeClientError(200, "install already in progress")

    return {}
    // return {"route":"","requestId":"","err":{"code":200,"msg":"DELIVERY_ERROR"},"success":false}
  },

  //
  [ROUTES.PACKAGE_LAUNCH](params: { packageName: string }) {
    // Params: {"packageName":"com.android.chrome"}}
    return { packageName: params.packageName }
  },

  //
  [ROUTES.ANALYTICS_EVENT_REPORT](params: {
    category: string
    action: string
    props: { [key: string]: string }
  }) {
    return {}
  },

  //
  [ROUTES.ANALYTICS_CONVERSION_REPORT](params: {
    packageName: string
    funnelStep: string
    props: { [key: string]: string }
  }) {
    return {}
  },

  //
  [ROUTES.ANALYTICS_CONVERSION_ADD_MAPPING](params: {
    map: { [key: string]: string }
  }) {
    deviceState.analytics.mapping = params.map
    return {}
  },

  //
  [ROUTES.ANALYTICS_USER_ADD_DIMENSION](params: {
    key: string
    value: string
  }) {
    deviceState.analytics.userProperties[params.key] = params.value
    return {}
  },

  //
  [ROUTES.ANALYTICS_SESSION_ADD_DIMENSION](params: {
    key: string
    value: string
  }) {
    deviceState.analytics.sessionProperties[params.key] = params.value
    return {}
  },

  //
  [ROUTES.PERMISSION_STATUS]({ permission }: { permission: string }) {
    return {
      status: {
        type: _.includes(deviceState.grantedPermissions, permission)
          ? "granted"
          : "denied",
      },
    }
  },

  //
  [ROUTES.PERMISSION_GRANT]({ permission }: { permission: string }) {
    if (deviceState.autoGrantPermissions) {
      if (!_.includes(deviceState.grantedPermissions, permission)) {
        deviceState.grantedPermissions.push(permission)
      }
    }

    return {
      status: {
        type: _.includes(deviceState.grantedPermissions, permission)
          ? "granted"
          : "denied",
      },
    }
  },
}
