import { Subtitle } from 'components/base/Typography'
import { isProd } from 'modules/utils'
import css from './ArticleVideo.module.scss'

export default function ArticleVideo({ content }: { content: string }) {
  if (!content) {
    return isProd() ? null : <Subtitle size="s" sx={{ background: 'red' }}>App video does not exist.</Subtitle>
  }

  return <iframe
    src={content}
    width="100%"
    className={css.articleVideo}
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowFullScreen
  >
  </iframe>
}
