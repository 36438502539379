import { DELIVERY_STATES, EVENTS } from "consts"
import * as eventsManager from "modules/eventsManager"
import { log } from "modules/logs"
import {
  Dispatch,
  FC,
  MutableRefObject,
  ReactNode,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { ICampaign, IDelivery, IInstalledPackage } from "types"

export const GlobalStateContext = createContext<IGlobalState>(
  {} as IGlobalState
)

interface GlobalStateProps {
  packages?: any[]
  deliveries?: any[]
  children: ReactNode
}

type ScrollRestorationRoutes = Record<string, number>

export interface IGlobalState {
  apps: any
  campaignForApps: { [appId: string]: ICampaign }
  showBottomNavBar: boolean
  setShowBottomNavBar: Dispatch<boolean>
  shouldScroll: boolean
  setShouldScroll: Dispatch<boolean>
  tabId: number | undefined
  setTabId: Dispatch<number | undefined>
  currentModal: string | null
  currentModalParams: any
  openModal: (modalName: string, params: any) => void
  closeModal: () => void
  scrollContainerRef: MutableRefObject<any>
  scrollRestorationRoutes: ScrollRestorationRoutes
  setScrollRestorationRoute: (pathname: string, scrollTop: number) => void
  clearCampaignForApp: (appId: number) => void
  setCampaignForApp: (appId: number, campaign: ICampaign) => void
  getCampaignForApp: (appId: number) => ICampaign
  wasTrackingImpressionFired: (impressionLink: string) => boolean
  markImpressionFired: (impressionLink: string) => void
}

export const GlobalState: FC<GlobalStateProps> = ({
  packages,
  deliveries,
  children,
}: any) => {
  const [apps, setApps] = useState<any>({})
  const [campaignForApps] = useState<{ [appId: string]: ICampaign }>({})
  const [firedImpressions] = useState<Set<string>>(new Set())
  const [showBottomNavBar, setShowBottomNavBar] = useState(true)
  const [shouldScroll, setShouldScroll] = useState(true)
  const scrollContainerRef = useRef()
  const [scrollRestorationRoutes, setScrollRestorationRoutes] =
    useState<ScrollRestorationRoutes>({})
  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [currentModalParams, setCurrentModalParams] = useState<any>(null)
  const [tabId, setTabId] = useState<number | undefined>()

  const value = {
    apps,
    campaignForApps,

    showBottomNavBar,
    setShowBottomNavBar,

    shouldScroll,
    setShouldScroll,

    tabId,
    setTabId,

    currentModal,
    currentModalParams,

    openModal(modalName: string, params: any) {
      setCurrentModal(modalName)
      setCurrentModalParams(params)
    },
    closeModal() {
      setCurrentModal(null)
      setCurrentModalParams(null)
    },


    clearCampaignForApp(appId: number) {
      delete campaignForApps[appId];
    },
    setCampaignForApp(appId: number, campaign: ICampaign) {
      if (!campaign) return
      log(`Set campaign ${campaign.id} for app ${appId}`)
      campaignForApps[appId] = campaign;
    },
    getCampaignForApp(appId: number): ICampaign {
      return campaignForApps[appId];
    },
    wasTrackingImpressionFired(impressionLink: string) {
      return firedImpressions.has(impressionLink);
    },
    markImpressionFired(impressionLink: string) {
      firedImpressions.add(impressionLink);
    },
    // TODO: Why dis broken???
    scrollContainerRef,
    scrollRestorationRoutes,
    setScrollRestorationRoute(pathname: string, scrollTop: number) {
      setScrollRestorationRoutes(prev => {
        prev = {
          ...prev,
          [pathname]: scrollTop,
        }
        if (!scrollTop) delete prev[pathname]
        return prev
      })
    },
  }

  useEffect(() => {
    function onDeliveryStatus(data: { deliveries: IDelivery[] }) {
      const { deliveries } = data

      setApps((prev: any) => {
        const packages: any = {}
        deliveries.forEach((delivery: any) => {
          const { packageName, versionCode, status } = delivery

          const { type: state, progress } = status

          if (state === DELIVERY_STATES.failed) {
            delete prev[packageName]
            return
          }

          const { totalSizeInBytes, downloadedSizeInBytes } = progress || {}
          const progressPcent: number = progress
            ? (downloadedSizeInBytes / totalSizeInBytes) * 100
            : 0

          log(`**** package ${packageName}, has state: ${state}, versionCode: ${versionCode}, progress: ${progressPcent}`)

          packages[packageName] = {
            ...prev[packageName],
            deliveryProcessState: state,
            deliveryProcessVersionCode: versionCode,
            progress: progressPcent,
          }
        })

        const newState = {
          ...prev,
          ...packages,
        }

        return newState
      })
    }

    function onPackagesStatus(data: { packages: IInstalledPackage[] }) {
      const { packages } = data
      setApps((previousAppsStatus: any) => {
        const packageNamesWithRelevantStatus: Array<string> = Object.keys(previousAppsStatus).reduce((acc: Array<string>, packageName) => {
          if (previousAppsStatus[packageName].deliveryProcessState !== DELIVERY_STATES.installed) {
            acc.push(packageName)
          }
          return acc
        }, [])

        // we want to take the status of apps which is not installed into consideration. 
        // For example, sendToNative status should show a spinner, so we should update its status
        log("**** packageNamesWithRelevantStatus", packageNamesWithRelevantStatus)
        const newState: { [key: string]: any } = {}

        packageNamesWithRelevantStatus.forEach((packageName: string) => {
          newState[packageName] = {
            deliveryProcessState: previousAppsStatus[packageName].deliveryProcessState,
          }
        })
        packages.forEach(({ packageName, versionCode }: any) => {
          newState[packageName] = {
            deliveryProcessState: DELIVERY_STATES.installed,
            installedVersionCode: versionCode,
          }
        })
        return newState
      })
    }

    function onModalOpen(data: any) {
      const { modalName, params } = data
      setCurrentModal(modalName)
      setCurrentModalParams(params)
    }

    function onModalClose() {
      setCurrentModal(null)
      setCurrentModalParams(null)
    }

    onDeliveryStatus({ deliveries }) // set initial state on mount
    eventsManager.addListener(EVENTS.DELIVERY_STATUS, onDeliveryStatus)
    onPackagesStatus({ packages }) // set initial state on mount
    eventsManager.addListener(EVENTS.PACKAGES_STATUS, onPackagesStatus)

    // Modals
    eventsManager.addListener(EVENTS.MODAL_OPEN, onModalOpen)
    eventsManager.addListener(EVENTS.MODAL_CLOSE, onModalClose)

    return () => {
      eventsManager.removeListener(EVENTS.DELIVERY_STATUS, onDeliveryStatus)
      eventsManager.removeListener(EVENTS.PACKAGES_STATUS, onPackagesStatus)
      eventsManager.removeListener(EVENTS.MODAL_OPEN, onModalOpen)
      eventsManager.removeListener(EVENTS.MODAL_CLOSE, onModalClose)
    }
  }, [])

  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  )
}
