import { ROUTES } from "consts"
import _ from "lodash"
import { log } from "modules/logs"
import * as Communication from "./communication/communication"

export let currentPage: {
  enter: Date
  group: string
  title: string
  path: string
}

export function getCurrentPage() {
  return currentPage
}

export function setCurrentPage(path: string, title: string) {
  if (localStorage.__disableReporting) return

  log("analytics :: setCurrentPage", path, title)

  let group = "discover"
  if (path === "/search") group = "search"
  else if (path === "/play") group = "instant play"
  else if (path.startsWith("/app/")) group = "app"
  else if (path.startsWith("/articles/")) group = "article"

  currentPage = {
    enter: new Date(),
    group,
    title,
    path,
  }
}

const createItemProps = (props: any) => {
  let propTypeName;
  let prop;
 if (props?.embeddedVideo) {
    propTypeName = "video"
    prop = props.embeddedVideo
  } else if (props?.promoCard) {
    propTypeName = "promo_card"
    prop = props.promoCard
  } else if (props?.article) {
    propTypeName = "article"
    prop= props.article
  } else if (props?.eventCard) {
    propTypeName = "event_card"
    prop = props.eventCard
  }

  if(!propTypeName || !prop) return {};
  return {
    item_id: `${prop.id}_${propTypeName}`,
    item_name: prop.title,
    item_type: propTypeName,
    ...(prop?.dpId && {
      dp_id: prop.dpId
    }),
    ...(prop?.app && {
      event_label: prop?.app?.packageName,
    }),
  }
}

function extractReportEntities(props: { [key: string]: any } = {}) {


  if (props.app) {
    props = {
      ...props,
      app_id: props.app.id,
      app_name: props.app.title || props.app.locale.title,
      app_package_name: props.app.packageName,
      app_delivery_type: props.app.isPartnerApp ? "direct" : "google play",
      app_payment_type: props.app.price ? "paid" : "free",
      dp_id: props.app.dpId,
      // app_size:  // TODO: Get tab position
      // app_position:  // TODO: Get tab position
      // app_category:  // TODO: Get tab position
    }
    const campaign = props?.campaign
    props.app_monetization_type = campaign ? "monetized" : "organic"

    if (props.campaign) {
      props.campaign_id = campaign?.id
      props.dp_id = campaign?.dpId
    }
  }

  if (props.tab) {
    props = {
      ...props,
      tab_id: props.tab.id,
      tab_name: props.tab.name,
      // tab_position: props.tab.tab_position, // TODO: Get tab position
      tab_layout: props.tab.tabLayout,
    }
  }

  if (props.collection && props.collection.id) {
    props = {
      ...props,
      collection_id: props.collection.id,
      collection_name: props.collection.title,
      // app_collection_config: props.collection.config, // TODO: what is config?
      // app_collection_position: props.collection.position, // TODO: Get collection position
      app_collection_items_limit: props.collection.limit,
    }
  }

  if (props.playable) {
    props = {
      ...props,
      playable_id: props.playable.id,
      playable_guid: props.playable.gameGuid,
      playable_name: props.playable.title,
      dp_id: props.playable.dpId,
    }
  }

  props = {
    ...props,
    ...createItemProps(props)
  }

  if (currentPage) {
    props.page_group = currentPage.group
    props.page_path = currentPage.path
    props.page_title = currentPage.title
  }

  // Remove props that are not needed
  props = _.omit(props, [
    "app",
    "campaign",
    "promoCard",
    "tab",
    "collection",
    "playable",
    "article",
    "eventCard",
  ])

  // The native client expects all values to be strings
  for (const key in props) {
    props[key] = `${props[key]}`
  }
  return props
}

export async function addUserProperty(key: string, value: any): Promise<void> {
  if (localStorage.__disableReporting) return

  log(`analytics :: user/addDimension | ${key} > ${value}`)

  return Communication.send(
    ROUTES.ANALYTICS_USER_ADD_DIMENSION,
    `${key}${value}`,
    { key, value: `${value}` }
  )
}

export async function addSessionProperty(
  key: string,
  value: string
): Promise<void> {
  if (localStorage.__disableReporting) return

  log(`analytics :: session/addDimension | ${key} > ${value}`)

  return Communication.send(
    ROUTES.ANALYTICS_SESSION_ADD_DIMENSION,
    `${key}${value}`,
    { key, value: `${value}` }
  )
}

export async function reportEvent(
  category: string,
  action: string,
  props = {}
): Promise<void> {
  const data = {
    category,
    action,
    props: extractReportEntities({ ...props, item_category: category }),
  }
  if (localStorage.__disableReporting) return

  log(`analytics :: event | ${category} > ${action}`, data.props)
  return Communication.send(ROUTES.ANALYTICS_EVENT_REPORT, "", data)
}

export async function reportConversion(
  packageName: string,
  funnelStep: string,
  props: any = {}
): Promise<void> {
  const data = {
    packageName,
    funnelStep,
    props: extractReportEntities(props),
  }
  if (localStorage.__disableReporting) return

  log(`analytics :: conversion | ${packageName} > ${funnelStep}`, data.props)
  return Communication.send(ROUTES.ANALYTICS_CONVERSION_REPORT, "", data)
}

export async function addConversionColumnMapping(): Promise<void> {
  if (localStorage.__disableReporting) return

  log(`analytics :: add_conversion_column_mapping`)
  return Communication.send(
    ROUTES.ANALYTICS_CONVERSION_ADD_MAPPING,
    "callback:add_conversion_column_mapping",
    {
      map: {
        // operating_system_fingerprint	:36,
        // connection_type	:8,
        // notification_id	:100,
        // original_store_session_id	:101,
        // original_entry_point	:102,

        event_duration: 126,
        event_reason: 15,

        page_group: 103,
        page_title: 104,
        page_path: 105,

        tab_id: 106,
        tab_name: 107,
        tab_position: 108,
        tab_layout: 109,

        collection_id: 110,
        collection_name: 111,
        app_collection_config: 114,
        app_collection_position: 112,
        app_collection_items_limit: 119,

        app_id: 121,
        app_name: 122,
        app_position: 17,
        app_category: 44,
        app_size: 25,
        app_delivery_type: 127,
        app_payment_type: 144,

        playable_id: 123,
        playable_guid: 124,
        playable_name: 125,
        campaign_id: 1,
        dp_id: 137,
        app_monetization_type: 6,

        // url: 11,
        // retry_number: 10,

        // placement: 59,
        // feature: 4,
      },
    }
  )
}
