import AppCard from "components/AppCard";
import CloseButton from "components/CloseButton";
import Icon from "components/Icon";
import { Column, Row } from "components/Layout";
import { CardType } from "consts";
import useReportPageEvents, { PAGE_EVENT_DATA_TYPE } from "hooks/useReportPageEvents";
import Modal from "react-modal";
import { IAuraEvent } from "types";
import { Body, Caption, Subtitle } from "../base/Typography";
import css from './EventInfoModal.module.scss';

interface EventInfoModalProps {
  auraEvent: IAuraEvent,
  endTime: string,
  isOpen: boolean,
  closeModal: () => void
}

export default function EventInfoModal({ auraEvent, endTime, isOpen, closeModal }: EventInfoModalProps) {

  const { id, isPartnerApp, packageName, title, versionCode, score } = auraEvent.app

  if (isOpen) {
    useReportPageEvents({ event: auraEvent }, PAGE_EVENT_DATA_TYPE.EVENT)
  }

  return <Modal
    isOpen={isOpen}
    shouldCloseOnOverlayClick
    onRequestClose={closeModal}
    closeTimeoutMS={200}
    className={{ base: css.content, afterOpen: css.contentAfterOpen, beforeClose: css.contentBeforeClose }}
    overlayClassName={{ base: css.overlay, afterOpen: css.overlayAfterOpen, beforeClose: css.overlayBeforeClose }}
  >
    <Row align="r" className={css.closeButton}>
      <CloseButton onClose={closeModal} />
    </Row>
    <Column className={css.modalBody}>
      <img src={auraEvent.fullImageUrl} className={css.coverPhoto} />
      <Column align="mc" className={css.info}>
        <Row align="sb" className={css.badges}>
          <div className={css.eventBadge}>
            <Icon name="star-small" size={14} />
            <Caption>Event</Caption>
          </div>
          <div className={css.endTime}>
            <Icon name="clock" size={14} />
            <Caption>
              {`Ends on ${endTime}`}</Caption>
          </div>
        </Row>
        <Column>
          <Subtitle className={css.eventTitle} size="l">{auraEvent.title}</Subtitle>
          <Body className={css.desc} size="m">{auraEvent.description}</Body>
        </Column>
      </Column>
      <div className={css.divider}></div>
      <div onClick={closeModal}>
        <AppCard
          id={id}
          isPartnerApp={isPartnerApp}
          packageName={packageName}
          title={title}
          versionCode={versionCode}
          score={score}
          iconSize={52}
          cardType={CardType.DEFAULT}
            hideReadMore={true}
          app={auraEvent.app} />
      </div>
    </Column>
  </Modal>
}