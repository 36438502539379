import { FC, useEffect } from "react";
import colors from "styles/colors";
import * as utils from "../modules/utils";
import * as nativeApi from "../modules/nativeAPI";
import { useLocalStorage } from "react-use";

export const StatusBar: FC = () => {
  const [statusBarHeight, setStatusBarHeight] = useLocalStorage<number>(
    "statusBarHeight",
    undefined,
  );
  useEffect(() => {
    const isOnGame = location.pathname.includes("on_game");
    if (isOnGame) {
      setStatusBarHeight(0);
    } else {
      async function fetchStatusBarHeight() {
        try {
          const { statusBarHeight } = await nativeApi.getDisplayInfo();
          setStatusBarHeight(Math.max(statusBarHeight, 30));
        } catch (e) {
          setStatusBarHeight(30);
        }
      }

      fetchStatusBarHeight();
    }
  }, []);

  function getStatusBarBackground() {
    if (utils.isProd()) return colors.backgroundDark;
    if (utils.isOcBarbie()) return colors.backgroundDark;
    if (utils.isDev() || utils.isLocal()) return colors.yellow;
    if (process.env.REACT_APP_CUSTOM_ENV) return colors.pink;
    return colors.backgroundDark;
  }

  const statusBarBackground = getStatusBarBackground();

  function getStatusBarContent() {
    if (statusBarHeight == 0) return null;
    if (utils.isProd()) return null;
    if (!process.env.REACT_APP_VERSION) return null;
    return `v${process.env.REACT_APP_VERSION}`;
  }

  const statusBarContent = getStatusBarContent();

  return (
    <div
      style={{
        height: statusBarHeight ?? 0,
        background: statusBarBackground,
        color: "black",
        zIndex: 999999,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      data-testid={utils.generateDataTestId({ prefix: "status-bar" })}
    >
      {statusBarContent}
    </div>
  );
};
