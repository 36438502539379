import { RefObject, useEffect, useState } from "react"
import { IApp, AppInfo } from "types"
import { reportConversion } from "../modules/reporting"
import useScrollIntoView from "./useScrollIntoView"

export default function useReportAppImpressionEvent({
  data = {},
  app,
  elRef,
}: {
  data: any
  app?: IApp | AppInfo
  elRef?: RefObject<HTMLDivElement>
}) {
  const inView = useScrollIntoView(elRef)
  const [reportedInView, setReportedInView] = useState(false)

  useEffect(() => {
    if ((elRef && !inView) || reportedInView || !app) return

    setReportedInView(true)
    reportConversion(app.packageName, "impression", {
      app,
      ...data,
    })
  }, [app, reportedInView, inView, elRef])
}
