import { Subtitle, Title } from "components/base/Typography"
import { Column, Row } from "components/Layout"
import colors from "styles/colors"

export default function ArticleBottomLine({ content }: { content: string }) {
  return <Row
    padding="0 0 0 lg"
    align="sb"
    style={{
      background: colors.cardsBackground,
      borderRadius: 14,
    }}>
    <Column gap={2} padding="lg 0">
      <Title size="s" bold={true}>Bottom line</Title>
      <Subtitle>{content}</Subtitle>
    </Column>
    <img
      src="/img/bottom_line.png"
      width={132}
      height={114}
    />
  </Row>
}