import _ from "lodash"
import { HTMLAttributes, ReactNode, forwardRef, CSSProperties } from "react"

interface Alignment {
  alignItems?: string
  justifyContent?: string
}

interface RowProps extends HTMLAttributes<HTMLDivElement> {
  align?: string
  gap?: number | string
  col?: number | string
  padding?: string
  margin?: string
  style?: CSSProperties | undefined
  onClick?: () => void
  children?: ReactNode
  dataTestId?: string,
  className?: string
}

function translateAlign(align?: string): Alignment {
  if (!align) return {}

  const alignment: Alignment = {}
  align = align.toLowerCase()

  if (align.startsWith("t")) alignment.alignItems = "flex-start"
  if (align.startsWith("m")) alignment.alignItems = "center"
  if (align.startsWith("b")) alignment.alignItems = "flex-end"
  if (align.startsWith("stretch")) alignment.alignItems = "strecth"
  if (align.startsWith("baseline")) alignment.alignItems = "baseline"

  if (align.endsWith("l")) alignment.justifyContent = "flex-start"
  if (align.endsWith("c")) alignment.justifyContent = "center"
  if (align.endsWith("r")) alignment.justifyContent = "flex-end"
  if (align.endsWith("sb")) alignment.justifyContent = "space-between"
  if (align.endsWith("sa")) alignment.justifyContent = "space-around"
  if (align.endsWith("se")) alignment.justifyContent = "space-evenly"

  return alignment
}

export const spacings: Record<string, string> = {
  none: "0rem",
  xxs: "0.1333rem",
  xs: "0.333rem",
  sm: "0.666rem",
  md: "1rem",
  lg: "1.333rem",
  xl: "1.666rem",
  xxl: "2rem",
}
function translateSpace(space: number | string): string | undefined {
  if (spacings[space]) return spacings[space]
  if (_.isNumber(space)) return `${space}px`
  return space
}

function translateSpacing(space: string | string[]): string | undefined {
  if (_.isString(space) && space.indexOf(" ") > -1) space = space.split(" ")

  if (_.isArray(space)) {
    const res = space.map(translateSpace).join(" ")
    return res
  }

  return translateSpace(space)
}

export const Row = forwardRef<HTMLDivElement, RowProps>(function (
  {
    children,
    align,
    gap,
    col,
    padding,
    margin,
    style,
    dataTestId,
    onClick,
    ...props
  },
  ref,
) {
  return (
    <div
      onClick={onClick}
      ref={ref}
      style={{
        display: "flex",
        ...translateAlign(align),
        flex: col,
        ...(gap && { gap: translateSpace(gap) }),
        ...(margin && { margin: translateSpacing(margin) }),
        ...(padding && { padding: translateSpacing(padding) }),
        ...style,
      }}
      data-testid={dataTestId}
      {...props}
    >
      {children}
    </div>
  );
});

export const Column = forwardRef<HTMLDivElement, RowProps>(function (
  { style = {}, ...props },
  ref
) {
  return (
    <Row {...props} style={{ ...style, flexDirection: "column" }} ref={ref} />
  )
})
