import AppCard from "components/AppCard"
import { Column } from "components/Layout"
import { Subtitle } from "components/base/Typography"
import { IArticle } from "types"
import ArticleMarkdown from "./ArticleMarkdown"
import ArticleVideo from "./ArticleVideo"

interface ArticleAppBlockProps {
  content: string
  app: any
  article: IArticle // for reporting
}

export default function ArticleAppBlock({ content, app, article }: ArticleAppBlockProps) {
  return <Column gap={20}>
    <Subtitle size="l">{app.locale.title}</Subtitle>
    <ArticleVideo content={app.locale.video} />
    <ArticleMarkdown content={content} />
    <AppCard
      id={app.id}
      packageName={app.packageName}
      title={app.locale.title}
      score={app.score}

      // Delivery
      versionCode={app.versionCode}
      isPartnerApp={app.isPartnerApp}

      app={app}
      article={article}
    />
  </Column>
}
