import { reportEvent } from "modules/reporting"
import { RefObject, useEffect, useState } from "react"
import useScrollIntoView from "./useScrollIntoView"

export default function useReportScrolledIntoView(elRef: RefObject<HTMLDivElement>, category: string, action: string, data: any, reportPredicate?: Function) {
  const inView = useScrollIntoView(elRef)
  const [reportedInView, setReportedInView] = useState(false)

  useEffect(() => {
    if (!inView || reportedInView) return

    setReportedInView(true)
    if (!reportPredicate || reportPredicate(data)) {
      reportEvent(category, action, data)
    }
  }, [inView, reportedInView])

  return reportedInView
}
