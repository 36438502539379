import classNames from "classnames"
import { Column } from "components/Layout"
import { Subtitle } from "components/base/Typography"
import { useEffect, useRef, useState } from "react"
import { RelatedArticle } from "types"
import css from '../Collection.module.scss'
import ArticleCard from "./ArticleCard"

interface ArticleRelatedArticlesProps {
  content?: string
  relatedArticles: RelatedArticle[]
  titleSize?: "s" | "m" | "l",
  titleGap?: string,
  title?: string
}

export default function ArticleRelatedArticles({ content, relatedArticles, titleSize, titleGap = "14px", title = "Related Articles" }: ArticleRelatedArticlesProps) {
  const collectionScrollRef = useRef<HTMLDivElement>(null)
  const [scrollMileStones, setScrollMileStones] = useState<Record<number, boolean>>({})

  useEffect(() => {
    const el = collectionScrollRef?.current
    if (!el) return

    const updatePosition = (e: Event) => {
      const scrollPcent =
        el.scrollLeft / (el.scrollWidth - el.getBoundingClientRect().width)
      const normalized = Math.floor(Math.round(scrollPcent * 100) / 25)

      if (normalized > 0 && !scrollMileStones[normalized]) {
        setScrollMileStones({ ...scrollMileStones, [normalized]: true })
      }
    }

    el.addEventListener("scroll", updatePosition)

    // 👇️ remove the event listener when component unmounts
    return () => {
      el.removeEventListener("scroll", updatePosition)
    }
  }, [collectionScrollRef, scrollMileStones])

  return <Column gap={titleGap} className={css.collection} padding="0 1.5rem">
    <Subtitle size={titleSize || "s"}>{title}</Subtitle>
    <div
      ref={collectionScrollRef}
      className={classNames(css.appCards, css.horizontal)}
      style={{ display: 'flex' }}
    >
      {relatedArticles.map((article, index) => <ArticleCard key={index} {...article} />)}
    </div>

  </Column>
}