import AppInstallBtn from "components/AppInstallBtn";
import { Row } from "components/Layout";
import { AppInfo } from "types";
import css from "./AppInfoInstallBtn.module.scss";


export default function AppInfoInstallBtn({ app }: { app?: AppInfo }) {
  return <Row align="mt" className={css.installBtn} padding="lg 1.5rem">
    <AppInstallBtn
      appId={app?.id}
      packageName={app?.packageName}
      versionCode={app?.appDelivery?.versionCode}
      isPartnerApp={app?.isPartnerApp}
      type="large"
      app={app}
      originalPrice={app?.locale?.originalPrice}
      discountPercent={app?.locale?.discountPercent}
      price={app?.locale?.price}
    />
  </Row>
}