import Icon from "components/Icon";
import { times } from "lodash";
import { openUrl } from "modules/nativeAPI";
import { abbreviateNumber, humanFileSize } from "modules/utils";
import css from 'pages/AppInfoPage.module.scss';
import colors from "styles/colors";
import { AppInfo } from "types";
import { Column, Row, spacings } from "../Layout";
import { Subtitle } from "../base/Typography";
import { AppInfoField } from "./GameInfo";

const ICON_SIZE = 35
interface AdditionalDeveloperInfoProps {
  app?: AppInfo
}

export const appInfoFieldsSkeleton = times(4, () => ({} as AppInfoField))

const getAppInfoFields = (app: AppInfo): AppInfoField[] => app ? ([
  {
    icon: "version",
    label: "Version",
    value: app.appDelivery?.versionName,
  },
  {
    icon: "downloads",
    label: "Downloads",
    value: `${abbreviateNumber(parseInt(app.maxInstalls))}+ Downloads`,
  },
  {
    icon: "size",
    label: "Required OS",
    value:
      app.appDelivery?.size && humanFileSize(app.appDelivery?.size),
  },
  // {icon: "purchases", label: "In-App Purchases", value: "???"},
  { icon: "publisher", label: "Offered By", value: app.developer.name },
]).filter(e => e.value) : appInfoFieldsSkeleton

const getDeveloperInfoFields = (app: AppInfo): AppInfoField[] => app ? ([
  // { icon: "play", label: "Developer", value: app.developer.name },
  {
    icon: "website",
    label: "Website",
    value: (
      <u onClick={() => openUrl(app.developer.website)}>
        {app.developer.website}
      </u>
    ),
  },
  {
    icon: "email",
    label: "Email",
    value: (
      <u onClick={() => openUrl(`mailto: ${app.developer.email}`)}>
        {app.developer.email}
      </u>
    ),
  },
  { icon: "address", label: "Address", value: app.developer.address },
  {
    icon: "privacy",
    label: "Privacy Policy",
    value: (
      <u onClick={() => openUrl(app.locale.privacyPolicy)}>
        {app.locale.privacyPolicy}
      </u>
    ),
  },
]).filter(e => e.value) : appInfoFieldsSkeleton

export default function AdditionalDeveloperInfo({ app }: AdditionalDeveloperInfoProps) {
  return <>
    <Column padding="0 1.5rem" gap="24px">
      <Subtitle style={{ lineHeight: "22px" }}>Additional Info</Subtitle>
      {getAppInfoFields(app as AppInfo)
        .map(({ icon, label, value }, i) => (
          <Row key={i} align="mc" padding="0" gap="sm">
            <Icon
              name={icon}
              bg="circle"
              size={ICON_SIZE}
              color={colors.textLight}
              bgColor={colors.cardsBackground}
            />
            <Subtitle size="s" col="1" style={{ color: colors.textLight }} skeletonWidth={`calc(80% - ${ICON_SIZE}px - 2 * ${spacings.sm})`}>
              {label}
            </Subtitle>
            <Subtitle size="s" style={{ color: colors.textMedium }} className="ellipsis" skeletonWidth="20%">
              {value}
            </Subtitle>
          </Row>
        ))}
      <Subtitle style={{ lineHeight: "22px" }}>Developer Info</Subtitle>
      {getDeveloperInfoFields(app as AppInfo)
        .map(({ icon, label, value }) => (
          <Row key={label} align="mc" gap="sm">
            <Icon
              name={icon}
              bg="circle"
              size={35}
              color={colors.textLight}
              bgColor={colors.cardsBackground}
            />
            <div col={1} className={css.developerInfoField}>
              <Subtitle size="s" style={{ color: colors.textLight }} skeletonWidth="70%">{label}</Subtitle>
              <Subtitle size="s" style={{ color: colors.textMedium }} skeletonWidth="90%">{value}</Subtitle>
            </div>
          </Row>
        ))}
    </Column>
  </>
}