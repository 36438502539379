import CTABtn from "components/CTABtn"
import { Column } from "components/Layout"
import { Subtitle } from "components/base/Typography"
import * as gamesAPI from "modules/gamesAPI"
import { reportEvent } from "modules/reporting"
import { useState } from "react"
import colors from "styles/colors"
import texts from "styles/texts"

export default function ErrorGeneral({
  error,
  componentStack,
  resetError,
}: any) {
  const [counter, setCounter] = useState(0)

  reportEvent("errors", "web error screen shown", {
    event_reason: error.message,
  })

  let title = "Oops, this is embarrassing…"
  let reloadAction = () => window.location.reload()

  // Handle Known Errors
  if (error.url && error?.errorCode === 404) {
    if (/\/tabs\/[0-9]{1,4}\/feed/.test(error.url)) {
      title = "Tab could not be found"
      reloadAction = () => window.history.back()
    } else if (/\/tabs/.test(error.url)) {
      title = "Could not load tabs list"
      reloadAction = () => window.history.back()
    } else if (/\/apps/.test(error.url)) {
      title = "App could not be found"
      reloadAction = () => window.history.back()
    } else if (/\/collection/.test(error.url)) {
      title = "App Collection could not be found"
      reloadAction = () => window.history.back()
    }
  }

  function reload() {
    reportEvent("errors", "web error screen refresh clicked")

    // TODO: should we reload the current page or get back to the home page?
    // We can't always know the actual home page, it might be on a path like:
    // gems.com/versions/latest
    reloadAction()
  }

  function cleanErrorStack(errStack: string) {
    return errStack
      .replace(new RegExp(document.location.origin, "g"), "")
      .replace(/\/static\/js\//gi, "")
      .trim()
  }

  function formatError() {
    return [
      cleanErrorStack(error.stack),
      "",
      `== Component Stack:`,
      cleanErrorStack(componentStack),
      "",
      `== Version: ${process.env.REACT_APP_VERSION}`,
      `== uid: ${gamesAPI.userId()}`,
      `== sid: ${gamesAPI.sessionId()}`,
      `== Agent: ${window.navigator.userAgent}`,
    ].join("\n")
  }

  return (
    <Column
      align="mc"
      style={{ textAlign: "center", height: "100%" }}
      padding="2rem"
    >
      <Column align="mc" col={1} gap="1rem">
        {counter < 3 && (
          <img
            src="/img/error_general.png"
            width={180}
            height={180}
            alt="error occured"
            onClick={() => setCounter(counter + 1)}
          />
        )}
        <Subtitle variant="h4" size="l">{title}</Subtitle>
        <Subtitle variant="h5" size="m" style={{ color: colors.textMedium }}>
          It appears that something didn’t go as planned.
          <br />
          So sorry about that!
        </Subtitle>
        <CTABtn type="large" iconLeft="reload" onClick={reload} >
          Reload the app
        </CTABtn>
      </Column>

      {counter >= 3 && (
        <pre
          style={{
            textAlign: "left",
            whiteSpace: "pre-wrap",
            width: "100%",
            maxWidth: "100%",
          }}
        >
          {formatError()}
        </pre>
      )}

      <div style={{ font: texts.body, fontSize: 10, color: colors.textMedium }}>
        Version: {process.env.REACT_APP_VERSION}
        <br />
        Version Code: {gamesAPI.versionCode()}
      </div>
    </Column>
  )
}
