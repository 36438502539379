import { Chip } from "@mui/material"
import _ from "lodash"
import {
  FC,
  RefObject,
  createRef,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useState
} from "react"
import colors from "styles/colors"
import texts from "styles/texts"
import { Row, spacings } from "../Layout"
import Skeleton from "../Skeleton"

const GAP_SIZE = "xs"
interface AppInfoChipsProps {
  chips?: string[]
  maxWidth?: number
}

interface AppInfoChipProps {
  label: string
  onClick?: () => void
  hide?: boolean
}

type ChipRef = RefObject<HTMLDivElement>
type ChipRefs = Record<string, ChipRef>

const getNumberOfChipsToDisplay = (
  chipRefs: ChipRefs,
  plusWidgetRef: ChipRef,
  maxWidth?: number
): number => {
  if (!maxWidth) {
    return 0
  }

  const gapSize = Number(spacings[GAP_SIZE].replace("rem", "")) * 16
  const chipWidths = Object.values(chipRefs).map<number>(
    chipRef => chipRef.current?.offsetWidth || 0
  )
  const plusWidgetWidth = plusWidgetRef.current?.offsetWidth || 0

  let currentWidth = 0, chipsToDisplay = 0

  for (let i = 0; i < chipWidths.length; i++) {
    const hiddenChips = chipWidths.length - (i + 1)
    const chipSize = chipWidths[i] + gapSize

    const chipsTotalWidth = currentWidth + chipSize + (hiddenChips > 0 ? plusWidgetWidth : 0)
    if (chipsTotalWidth > maxWidth) {
      break
    }
    currentWidth = currentWidth + chipSize
    chipsToDisplay = chipsToDisplay + 1
  }

  return chipsToDisplay
}

const AppInfoChip = forwardRef<HTMLDivElement, AppInfoChipProps>(
  ({ label, onClick, hide }, ref) => {
    return (
      <Chip
        label={label}
        size="small"
        ref={ref}
        sx={{
          font: texts.caption,
          textTransform: "capitalize",
          background: colors.cardsBackground,
          color: colors.textMedium,
          height: 18,
          ...(hide && { visibility: 'hidden' })
        }}
        onClick={onClick}
      />
    )
  }
)

const AppInfoChips: FC<AppInfoChipsProps> = ({ chips, maxWidth }) => {
  const [shouldDisplayHiddenChips, setShouldDisplayHiddenChips] =
    useState(false)
  const [chipRefs, setChipsRefs] = useState<ChipRefs>()
  const plusWidgetRef = createRef<HTMLDivElement>()
  const [chipsToDisplay, setChipsToDisplay] = useState(chips?.length)

  useEffect(() => {
    if (chips?.length && maxWidth) {
      setChipsRefs(_.chain(chips).map(chip => [chip, createRef()]).fromPairs().value())
    }
  }, [chips, maxWidth])

  useLayoutEffect(() => {
    if (!chips || !chipRefs) return
    if (
      Object.values(chipRefs).every(chipRef => chipRef.current) &&
      !shouldDisplayHiddenChips
    ) {
      setChipsToDisplay(getNumberOfChipsToDisplay(chipRefs, plusWidgetRef, maxWidth))
    }
  }, [maxWidth, chipRefs, shouldDisplayHiddenChips, chips])

  if (!maxWidth) {
    return null
  }

  const getChipsToDisplay = (chips: string[], chipsToDisplay: number) => {
    const hiddenChips = chips.length - chipsToDisplay
    const chipsToRender = shouldDisplayHiddenChips
      ? chips
      : chips.slice(0, chipsToDisplay)
    const shouldDisplayPlusWidget = !shouldDisplayHiddenChips && !!hiddenChips

    return [
      ...chipsToRender.map(label => (
        <AppInfoChip key={label} label={label} ref={chipRefs?.[label]} />
      )),
      <AppInfoChip
        hide={!shouldDisplayPlusWidget}
        key={`+${hiddenChips}`}
        label={`+${hiddenChips}`}
        ref={plusWidgetRef}
        onClick={() => setShouldDisplayHiddenChips(true)}
      />,
    ]
  }

  if (!chips) {
    return <Skeleton width="65%" />
  }

  return (
    <Row
      align="ml"
      gap={GAP_SIZE}
      style={{
        maxWidth,
        ...(shouldDisplayHiddenChips && { overflowX: "scroll" }),
      }}
    >
      {getChipsToDisplay(chips, chipsToDisplay as number)}
    </Row>
  )
}

export default AppInfoChips
