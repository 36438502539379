import { DELIVERY_STATES, EVENTS, ModalTypes, ROUTES, STREAMS } from "consts"
import * as eventsManager from "modules/eventsManager"
import * as gamesAPI from "modules/gamesAPI"
import { log } from "modules/logs"
import { ICampaign } from "types"
import * as Communication from "./communication/communication"
import {
  addConversionColumnMapping,
  addUserProperty,
  reportEvent,
} from "./reporting"

export async function init() {
  console.log("hi nativeApi init");
  log("initializing communication channel with native client")
  await Communication.init()

  log("Subscribe to packages & delivery statuses")
  const [packages, deliveries] = await Promise.all([
    packagesStatusSubscribe(),
    packageDeliveryStatusSubscribe(),
    addConversionColumnMapping(),
  ])

  log("Communication init completed")

  return {
    packages: packages.packages,
    deliveries: deliveries.deliveries,
  }
}

async function isDeliveryApproved(packageName: string) {
  if (await hasPermission("install")) {
    log("install permission already granted - moving on...")
    return true
  }

  // Application dialog
  reportEvent("permission", "install permission dialog shown")
  if (!(await showInstallPermissionsModal())) {
    reportEvent("permission", "install permission dialog dismissed")
    log("install permission dialog dismissed")
    setPackageDeliveryStatus(packageName) // Clear delivery status
    return false
  }
  reportEvent("permission", "install permission dialog approved")
  log("install permission dialog approved")

  // native dialog / settings
  if (!(await requestPermission("install"))) {
    reportEvent("permission", "install permission rejected")
    log("install permission rejected")
    setPackageDeliveryStatus(packageName) // Clear delivery status
    return false
  }

  reportEvent("permission", "install permission granted")
  addUserProperty("install_permission_granted", 1)
  log("install permission granted")

  return true
}

// List installed packages and subscribe to changes
async function packagesStatusSubscribe(packages = null): Promise<any> {
  return Communication.streamSubscribe(
    STREAMS.PACKAGE_STATUS,
    EVENTS.PACKAGES_STATUS,
    { packages }
  )
}

// Subscribe to delivery status changes
async function packageDeliveryStatusSubscribe(packages = null): Promise<any> {
  return Communication.streamSubscribe(
    STREAMS.PACKAGE_DELIVERY_STATUS,
    EVENTS.DELIVERY_STATUS,
    { packages }
  )
}

function setPackageDeliveryStatus(
  packageName: string,
  type?: string,
  progress?: number
) {
  eventsManager.dispatch(EVENTS.DELIVERY_STATUS, {
    deliveries: [
      {
        packageName,
        status: { type, progress },
      },
    ],
  })
}

function showInstallPermissionsModal() {
  return new Promise(resolve => {
    function modalResponse(res: boolean) {
      eventsManager.dispatch(EVENTS.MODAL_CLOSE)
      resolve(res)
    }

    eventsManager.dispatch(EVENTS.MODAL_OPEN, {
      modalName: ModalTypes.INSTALL_PERMISSIONS,
      params: {
        onConfirm: () => modalResponse(true),
        onReject: () => modalResponse(false),
      },
    })
  })
}

export async function packageDeliver(
  appId: number,
  packageName: string,
  isUpdate = false,
  campaign?: ICampaign
) {
  // Request: token, delivery methods params (allowed on mobile data), report properties map \
  // Response: -
  setPackageDeliveryStatus(packageName, DELIVERY_STATES.sentToNative)

  const canDeliver = await isDeliveryApproved(packageName)

  if (canDeliver === false) {
    log(`delivery not approved`)
    return
  }

  const result = await gamesAPI.delivery(appId)
  if (result.code && result.code !== 200) {
    // TODO: show error to user
    log(`error when trying to deliver. ${JSON.stringify(result)}`)
    setPackageDeliveryStatus(packageName)
    return
  }

  if (campaign) {
    log(
      `app ${packageName}-${appId} token is enriched with campaign tracking links`
    )
    result.tracking_url = campaign.trackingUrl
    result.delivery_methods[0].properties.click_url = campaign.clickUrl
    result.delivery_methods[0].properties.impression_url =
      campaign.impressionUrl
  }

  const stringifiedToken = JSON.stringify(result)
  log(`delivery token: ${stringifiedToken}`)
  const base64EncodedToken = btoa(stringifiedToken)
  log(`delivery base64Token: ${base64EncodedToken}`)

  try {
    const action = isUpdate ? "update" : "install"
    const allowDownloadingOverMobileData =
      localStorage.__allowDownloadingOverMobileData === "true"

    const reportProps: any = {
      age: "20",
      gender: "female",
      app_monetization_type: campaign ? "monetized" : "organic",
    }

    if (campaign) {
      reportProps.campaign_id = campaign?.id.toString()
      reportProps.dp_id = campaign?.dpId
    }

    return await Communication.send(
      ROUTES.PACKAGE_DELIVER,
      `callback:${action}|${packageName}`,
      {
        token: base64EncodedToken,
        deliveryProps: {
          // installingMessage: "Fucking {0}", // (optional)
          // installSuccessMessage: "Fuck", // (optional)
          allowedOverMobileData: allowDownloadingOverMobileData,
          // allowedOverRoaming: true, // (optional, default=true)
        },
        reportProps,
      }
    )
  } catch (error) {
    const apkSize = result.delivery_methods[0]?.properties.size
    log(`delivery error: ${JSON.stringify(error)}`)
    if ((<Communication.NativeClientError>error).code === 201) {
      setPackageDeliveryStatus(packageName, DELIVERY_STATES.failed)

      eventsManager.dispatch(EVENTS.MODAL_OPEN, {
        modalName: ModalTypes.INSUFFICIENT_STORAGE,
        params: {
          size: apkSize,
        },
      })
    } else {
      setPackageDeliveryStatus(packageName, DELIVERY_STATES.failed)

      eventsManager.dispatch(EVENTS.MODAL_OPEN, {
        modalName: ModalTypes.UNKNOW_ERROR,
        params: {
          packageName,
          error,
        },
      })
    }
  }
}

export async function packageCancelDelivery(packageName: string) {
  log("packageCancelDelivery", packageName)

  return Communication.send(
    ROUTES.PACKAGE_DELIVERY_CANCEL,
    `callback:cancel|${packageName}`,
    { packageName }
  )
}
export async function closeWindow() {
  return Communication.send(ROUTES.CLOSE_WINDOW, "");
}

export async function packageLaunch(packageName: string) {
  setPackageDeliveryStatus(packageName, DELIVERY_STATES.launched)

  return Communication.send(
    ROUTES.PACKAGE_LAUNCH,
    `callback:launch|${packageName}`,
    {
      packageName,
    }
  )
}

export async function openStore(path: string) {
  return Communication.send(ROUTES.OPEN_STORE, `callback:openStore`, {
    deeplink_path: path,
  });
}

export async function deviceNetwork() {
  const res = await Communication.send(
    ROUTES.DEVICE_NETWORK,
    `callback:device/network`
  )
  return res.network.type
}

export async function openUrl(url: string) {
  return Communication.send(ROUTES.OPEN_URL, `callback:openURL|${url}`, {
    url,
  })
}

export async function hasPermission(permission: "install" | "notification") {
  const res = await Communication.send(
    ROUTES.PERMISSION_STATUS,
    `callback:permissionStatus|${permission}`,
    { permission }
  )

  return res.status.type === "granted"
}

export async function requestPermission(
  permission: "install" | "notification"
) {
  const res = await Communication.send(
    ROUTES.PERMISSION_GRANT,
    `callback:permissionGrant|${permission}`,
    { permission },
    (installPermissionRes: any) => {
      log(`install Permission Result: ${JSON.stringify(installPermissionRes)}`)
      return ["granted", "denied"].includes(installPermissionRes.status.type)
    }
  )

  if (res.status.type === "granted") {
    reportEvent("permission", "install permission granted")
    addUserProperty("install_permission_granted", 1)
    return true
  }
}

// Subscribe to delivery status changes
export async function getDisplayInfo(): Promise<{ statusBarHeight: number }> {
  return await Communication.send(
    ROUTES.DISPLAY_INFO,
    "callback:displayInfo",
  );
}