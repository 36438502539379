import { Typography, TypographyProps } from "@mui/material";
import colors from "styles/colors";
import texts from "styles/texts";
import Skeleton from "../Skeleton";

export interface TextProps {
  size?: 's' | 'm' | 'l'
  bold?: boolean
  underline?: boolean
  skeletonWidth?: string | number
  lineHeight?: string
}

export default function Text({ className, children, sx, bold, underline, lineHeight, skeletonWidth, ...props }: TypographyProps & Omit<TextProps, 'size'>) {
  return children ? <Typography
    {...props}
    className={className}
    children={children}
    sx={{
      color: colors.textWhite,
      ...sx,
      ...(bold && { fontWeight: 'bold' }),
      ...(underline && { textDecoration: 'underline' }),
      lineHeight
    }} /> :
    <Skeleton width={skeletonWidth || "50%"} className={className} />

}

export function Title({ size = 'm', sx, ...props }: TypographyProps & TextProps) {
  return <Text
    {...props}
    sx={{
      ...sx,
      font: texts[`title${size.toUpperCase()}`],

    }}
  />
}

export function Subtitle({ size = 'm', sx, bold, ...props }: TypographyProps & TextProps) {
  return <Text
    {...props}
    sx={{
      ...sx,
      font: texts[`subtitle${size.toUpperCase()}${bold ? 'Bold' : ''}`],
    }}
  />
}

export function Body({ size = 'm', sx, ...props }: TypographyProps & TextProps) {
  return <Text
    {...props}
    sx={{
      ...sx,
      font: texts[`body${size.toUpperCase()}`],
    }}
  />
}

export function Button({ size = 'l', sx, ...props }: TypographyProps & TextProps) {
  return <Text
    {...props}
    sx={{
      ...sx,
      font: texts[`button${size.toUpperCase()}`],
    }}
  />
}

export function Caption({ size, sx, ...props }: TypographyProps & TextProps) {
  return <Text
    {...props}
    sx={{
      ...sx,
      font: texts.caption,
    }}
  />
}
