import useReportScrolledIntoView from "hooks/useReportScrolledIntoView"
import { reportEvent } from "modules/reporting"
import { generateDataTestId } from "modules/utils"
import { useEffect, useRef } from "react"
import { IApp, IEmbeddedVideo } from "types"
import Skeleton from "./Skeleton"

interface VideoProps {
  embeddedVideo: IEmbeddedVideo
  // for reporting
  app?: IApp
  dataTestId?: string
}

const reportType = 'video'

export default function EmbeddedVideo({
  embeddedVideo,
  app,
  dataTestId,
}: VideoProps) {

  const videoRef = useRef<HTMLDivElement>(null)
  const playerRef = useRef<any>(null);

  useEffect(() => {
    const script = document.querySelector<HTMLScriptElement>('#youtube-iframe-api');
    if (!script) {
      const tag = document.createElement('script');
      tag.id = 'youtube-iframe-api';
      const trustedScriptURL = window.trustedTypes?.createPolicy('script-url', {
        createScriptURL: (url) => url
      });

      tag.src = trustedScriptURL?.createScriptURL('https://www.youtube.com/iframe_api') as unknown as string;

      // append the script to head
      document.head.appendChild(tag);
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (!window.YT) return;
    // @ts-ignore
    playerRef.current = new window.YT.Player(`youtube-player-${embeddedVideo.id}`, {
      height: '100%',
      width: '100%',
      videoId: embeddedVideo?.url.split('/').pop(),
      events: {
        onReady,
        onError: (e: any) => {
          console.log('youtube error', e);

        },
        onStateChange: (e: any) => {
          // https://developers.google.com/youtube/iframe_api_reference#Events 
          if (e.data === 1) { // video started playing
            reportEvent(
              "discover",
              "item clicked",
              {
                embeddedVideo,
                app,
                type: reportType,
              },
            );
          }
        }
      }
    });
    // @ts-ignore
  }, [window.YT])

  useReportScrolledIntoView(
    videoRef,
    "discover",
    "item impression",
    {
      embeddedVideo,
      app,
      type: reportType,
    },
  )

  if (!embeddedVideo) {
    return (
      <Skeleton
        variant="rounded"
        height={250}
        width={512}
        dataTestId={generateDataTestId({
          prefix: "video-skeleton",
          dataTestId,
        })}
      />
    )
  }
  const url = embeddedVideo?.url

  const onReady = (e: any) => {
    // if video title does not exist, it means the video failed to load
    if (!e.target.videoTitle) {
      reportEvent(
        "discover",
        "item load error",
        {
          embeddedVideo,
          app,
          type: reportType,
        },
      );
    }
  }

  return (
    <div id={`youtube-player-${embeddedVideo.id}`}
      style={{ width: '100%', height: '100%' }}
      ref={videoRef}
    >
    </div>
  )
}
