export const PACAKGE_NAME_FOR_TEST_APP_WITH_NO_ASSETS = "com.test.no.assets";

export function isNativeWebview() {
  return isAndroidWebview() || isIOSWebview();
}

export function isAndroidWebview() {
  // TODO: we need something stronger then this, the user agent is controlled from the native wrapper
  return navigator.userAgent.indexOf("; wv)") !== -1;
}

export function isIOSWebview() {
  // only ios's WKWebView has this object for sending messages to the native wrapper
  // https://developer.apple.com/documentation/webkit/wkscriptmessagehandler
  // @ts-ignore
  return !!window.webkit?.messageHandlers;
}

export const cdDebugKeys = [
  { name: "density", cdName: "dpd", type: "number" },
  { name: "locale", cdName: "l", type: "text" },
  { name: "uid", cdName: "uid", type: "text" },
  { name: "age", cdName: "ua", type: "number" },
  { name: "city", cdName: "ctc", type: "text" },
  { name: "deviceManufacturer", cdName: "dma", type: "text" },
  { name: "customer", cdName: "ctc", type: "text" },
  { name: "state", cdName: "state", type: "text" },
  { name: "abTestRange", cdName: "dabt", type: "number" },
  { name: "carrier", cdName: "smm", type: "text" },
  { name: "osVersion", cdName: "osv", type: "text" },
  { name: "deviceModel", cdName: "dmo", type: "text" },
  { name: "auid", cdName: "auid", type: "text" },
];

export function uuid() {
  // prefer native implementation if available
  try {
    return crypto.randomUUID();
  } catch (e) {
    // ignore
  }

  // fallback to browser built in random blob
  try {
    return URL.createObjectURL(new Blob([])).slice(-36);
  } catch (e) {
    // ignore
  }

  // fallback to Math.random
  const u = Date.now().toString(16) + Math.random().toString(16) + "0".repeat(16);
  return [
    u.substr(0, 8),
    u.substr(8, 4),
    "4000-8" + u.substr(13, 3),
    u.substr(16, 12),
  ].join("-");
}

export function setColorOpacity(hex: string, opacity: number) {
  // Return red to indicate error for invalid hex color
  if (!/^#(?:[0-9a-f]{3}){1,2}$/i.test(hex)) return `rgba(255, 0, 0, 1)`;

  // Convert shorthand hex form ("#03F") to full ("#0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  // Convert to rgb and add opacity
  const result = <RegExpExecArray>(
    /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  );
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function humanFileSize(bytes: number, si = true, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) return `${bytes} B`;

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return `${Number(bytes.toFixed(dp))} ${units[u]}`;
}

export function abbreviateNumber(number: number) {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(number);
}

export async function sleep(timeout: number): Promise<void> {
  return new Promise(resolve => {
    setTimeout(() => resolve(), timeout);
  });
}

export async function waitUntil(condition: () => boolean) {
  while (!condition()) {
    await sleep(10);
  }
}

const lottieCache: { [key: string]: any } = {};
export function setLottieCache(url: string, value: any) {
  lottieCache[url] = value;
}

export function fetchLottie(url: string) {
  return lottieCache[url] || url;
}

export function isLocal() {
  return /localhost/gi.test(document.location.hostname) && !isTest();
}

export function isDev() {
  // return process.env.NODE_ENV === "development"
  return /app.gems.io/gi.test(document.location.hostname) && !isTest();
}

export function isStaging() {
  return /aura-gamestore-dev\.isappcloud\.com/gi.test(
    document.location.hostname,
  );
}

export function isOcBarbie() {
  return /aura-gamestore-oc-barbie\.isappcloud\.com/gi.test(
    document.location.hostname,
  );
}

export function is2js() {
  return /web-client\.games\.j2s\.co\.il/gi.test(document.location.hostname);
}

export function isProd() {
  return /aura-gamestore\.isappcloud\.com/gi.test(document.location.hostname);
}

export function isTest() {
  return process.env.REACT_APP_ENV === "e2e";
}

export function envName() {
  if (isProd()) return "production";
  if (isStaging()) return "staging";
  return "local";
}

export function generateDataTestId({
  prefix,
  dataTestId,
  properties,
}: {
  prefix: string;
  dataTestId?: string;
  properties?: string[];
}) {
  if (isProd()) return;

  return `${prefix.replaceAll(" ", "-")}${dataTestId
    ? `-${dataTestId.replaceAll(" ", "-")}`
    : properties?.length
      ? `-${properties
        .map(property => property?.replaceAll(" ", "-"))
        .join("-")}`
      : ""
    }`.toLowerCase();
}

export function parseArticleLines(line: string): string[] {
  return line.split("\n").map(line => line.replace(/^[\s|-]+/, ""));
}

export function assetURL(
  packageName: string,
  assetType:
    | "icon"
    | "headerImage"
    | "videoImage"
    | "videoPreview"
    | `screenshots/${string}`,
) {
  if (!packageName) return "";

  const type = assetType === "videoPreview" ? "video" : "image";

  if (isTest()) {
    if (packageName === PACAKGE_NAME_FOR_TEST_APP_WITH_NO_ASSETS) {
      return `/noPath/NoImageInPath.png`;
    }

    return `/test/${assetType}.png`;
  }

  let transformations = "";
  if (assetType === "icon") {
    transformations = "w_64,h_64/";
  } else if (
    assetType === "headerImage" ||
    assetType === "videoPreview" ||
    assetType === "videoImage"
  ) {
    transformations = "c_fill,w_512,h_250/";
  }

  let ext = "";
  if (assetType === "videoPreview") {
    ext = isIOSWebview() ? ".mp4" : ".webm";
  } else {
    ext = isIOSWebview() ? ".png" : ".webp";
  }

  return `https://cloudinary-res-games.isappcloud.com/${type}/upload/${transformations}v1/aura-games/app_assets/${packageName}/default/${assetType}${ext}`;
}

export function formatEventDate(time: string): string {
  const dateObject = new Date(time);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const formattedDay = day < 10 ? "0" + day : day;
  const formattedMonth = month < 10 ? "0" + month : month;
  return `${formattedDay}/${formattedMonth}`;
}
