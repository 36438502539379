import { Chip } from "@mui/material";
import { PlacementType } from "consts";
import { generateDataTestId } from "modules/utils";
import { useState } from "react";
import { Link } from "react-router-dom";
import colors from "styles/colors";
import t from "translations";
import { Row } from "./Layout";
import { Button, Subtitle } from "./base/Typography";
import CollectionDebugModal from "./modals/CollectionDebugModal";

interface CollectionTitleProps {
  id: number
  title?: string;
  total?: number;
  limit?: number;
  titleGap?: string;
  debug?: { [slotId: number]: { reason: string, placementType: PlacementType } };
}

export default function CollectionTitle({ id, title, total = 0, limit = 0, titleGap = "md", debug }: CollectionTitleProps) {
  const [showDebug, setShowDebug] = useState(false);

  return <Row sf="msb" padding={`${titleGap} 1.5rem`}>
    <div style={{ display: "flex" }}>
      {debug &&
        <CollectionDebugModal
          collectionId={id}
          collectionDebug={debug}
          showDebug={showDebug}
          setShowDebug={setShowDebug}
        />}
      <Subtitle variant="h5">{title}</Subtitle>
      {debug && <Chip
        onClick={() => setShowDebug(true)}
        label="!"
        size="medium"
        sx={{
          fontWeight: "bold",
          textTransform: "capitalize",
          background: colors.pink,
          color: colors.textWhite,
          height: 'auto',
          marginLeft: '0.5rem',
          '& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'normal',
          },
        }}
      />}
    </div>
    {total > limit && (
      <Link
        to={`/collection/${id}`}
        data-testid={generateDataTestId({
          prefix: "see-all-button",
          properties: [id.toString()],
        })}>
        <Button size="s" underline sx={{ color: colors.textLight }}>{t("see_all")}</Button>
      </Link>
    )}
  </Row>

}