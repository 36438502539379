import { Button } from "@mui/material";
import { GlobalStateContext } from "GlobalState";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import type { AnimationItem } from 'lottie-web';
import * as utils from "modules/utils";
import React, { Dispatch, useContext, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import css from "./BottomNavBar.module.scss";

type NavBarLinks = {
  label?: string
  path: string
  search: string
  className: string
  size: number
  lottie?: string
  lottieInstance?: AnimationItem | undefined
  setLottieInstance?: Dispatch<AnimationItem>
  dataTestId: string,
  src: string
}

export const BottomNavBar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [isFirstRender, setIsFirstRender] = useState(true)
  const { showBottomNavBar } = useContext(GlobalStateContext)
  const [discoverLottie, setDiscoverLottie] = useState<AnimationItem>()
  const [searchLottie, setSearchLottie] = useState<AnimationItem>()
  const [playLottie, setPlayLottie] = useState<AnimationItem>()

  const navbarLinks: NavBarLinks[] = useMemo(
    () => [
      {
        label: "Discover",
        path: "/discover",
        search: location.search,
        className: "discoverBtn",
        size: 24,
        dataTestId: "discover",
        src : '/img/ic_discover.svg'
      },
      {
        label: "Shops",
        path: "/app/9999/store",
        search: location.search,
        className: "shopsBtn",
        size: 24,
        dataTestId: "shops",
        src : '/img/ic_shops.svg'
      },
    ],
    [location]
  )

  return (
    <AnimatePresence>
      {showBottomNavBar && (
        <motion.div
          className={css.bottomNavBar}
          padding="lr-lg"
          sf="mc"
          initial={{ opacity: 0, marginBottom: "-4.5rem" }}
          animate={{ opacity: 1, marginBottom: 0 }}
          exit={{ opacity: 0, marginBottom: "-4.5rem" }}
          transition={{ duration: utils.isTest() ? 0 : 0.5 }}
          data-testid={utils.generateDataTestId({ prefix: "bottom-nav-bar" })}
        >
          {navbarLinks.map(({ path, search, size, label, className, src }) => (
            <div
              key={path}
              col={1}
              className={classNames(css.tab, css[className])}
            >
              <Button
                className={classNames(css.button, css[className], {
                  [css.selected]: location.pathname.includes(path),
                })}
                sf="col mc"
                onClick={() => navigate(path + search)}
              >
                <img
                  style={
                    {
                      // background: colors.transparent,
                    }
                  }
                  src={src}
                  className={classNames(css.img, css[className], {
                    [css.selected]: location.pathname.includes(path),
                  })}
                  width={size}
                  height={size}
                  alt={"icon"}
                />

                {label && <div>{label}</div>}
              </Button>
            </div>
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
