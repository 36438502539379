import { DELIVERY_STATES } from "consts"
import { log } from "./logs"
import { isIOSWebview } from "./utils"

const GAME_INSTALL_STATES = [
  DELIVERY_STATES.queued,
  DELIVERY_STATES.downloading,
  DELIVERY_STATES.installing,
  DELIVERY_STATES.sentToNative,
]

interface GameInstallData {
  deliveryProcessVersionCode: number
  deliveryProcessState: DELIVERY_STATES
  installedVersionCode: number
}

interface GetDeliveryProcessStateInput extends GameInstallData {
  packageName: string
  versionCode: number
}

interface GetAppInstallBtnInput extends GetDeliveryProcessStateInput {
  type: "small" | "large"
  btnHeight: number
  progress: number
  hasAppDelivery: boolean
  originalPrice: number | null
  price: number | null
  discountPercent: number | null
  handleUpdateClick: () => void
  handleCancelDownloadClick: () => void
  handleLaunchClick: () => void
  handleDefaultClick: () => void
}

interface GetAppInstallBtnOutput {
  label: string
  btnIcon?: string
  onClickHandler?: Function | undefined
  progressVarient?: "indeterminate" | "determinate" | undefined
  btnStyle: any
  progress: number
  originalPrice?: number | null
}

function checkDeliveryIsGameInstalled({
  deliveryProcessVersionCode,
  deliveryProcessState,
}: Omit<GameInstallData, "installedVersionCode">) {
  return (
    !!deliveryProcessVersionCode &&
    deliveryProcessState === DELIVERY_STATES.installed
  )
}

function getDefaultBtnLabel(
  discountPercent: number,
  price: number | null
): string {
  if (discountPercent === 1) {
    return "FREE"
  }
  return `${price} $`
}

export function isGameInstalledOnDevice({
  deliveryProcessVersionCode,
  deliveryProcessState,
  installedVersionCode,
}: GameInstallData) {
  return (
    !!installedVersionCode ||
    checkDeliveryIsGameInstalled({
      deliveryProcessState,
      deliveryProcessVersionCode,
    })
  )
}

export function getCurrentInstalledVersionOnDevice({
  deliveryProcessVersionCode,
  deliveryProcessState,
  installedVersionCode,
}: GameInstallData) {
  return checkDeliveryIsGameInstalled({
    deliveryProcessState,
    deliveryProcessVersionCode,
  })
    ? deliveryProcessVersionCode
    : installedVersionCode
}

export function isGameDuringInstall(deliveryProcessState: DELIVERY_STATES) {
  return GAME_INSTALL_STATES.includes(deliveryProcessState)
}

export function getDeliveryProcessState({
  deliveryProcessState,
  versionCode,
  packageName,
  deliveryProcessVersionCode,
  installedVersionCode,
}: GetDeliveryProcessStateInput) {
  const isGameInstalled = isGameInstalledOnDevice({
    deliveryProcessState,
    deliveryProcessVersionCode,
    installedVersionCode,
  })
  const currentInstalledVersion = getCurrentInstalledVersionOnDevice({
    deliveryProcessState,
    deliveryProcessVersionCode,
    installedVersionCode,
  })
  const isGameVersionHigherThanInstalledVersion =
    versionCode > currentInstalledVersion
  const gameDuringInstall = isGameDuringInstall(deliveryProcessState)

  if (
    isGameInstalled &&
    !gameDuringInstall &&
    isGameVersionHigherThanInstalledVersion
  ) {
    log(
      `${packageName}: installed version code ${currentInstalledVersion} is lower than what we have ${versionCode}, providing an update option.`
    )
    return DELIVERY_STATES.update
  }
  return deliveryProcessState
}

export function getAppInstallBtnProps({
  deliveryProcessState,
  versionCode,
  deliveryProcessVersionCode,
  packageName,
  installedVersionCode,
  type,
  btnHeight,
  progress,
  originalPrice,
  discountPercent,
  price,
  handleDefaultClick,
  handleCancelDownloadClick,
  handleUpdateClick,
  handleLaunchClick,
  hasAppDelivery,
}: GetAppInstallBtnInput): GetAppInstallBtnOutput {
  const buttonDeliveryProcessState = getDeliveryProcessState({
    deliveryProcessState,
    versionCode,
    deliveryProcessVersionCode,
    packageName,
    installedVersionCode,
  })

  const getLabel = (): string => {
    if (type === "large") {
      if (discountPercent) {
        return getDefaultBtnLabel(discountPercent, price)
      }
      if (hasAppDelivery) {
        return "Install"
      }
      return `Get it on ${isIOSWebview() ? "App Store" : "Google Play"}`
    }
    return "Get"
  }

  const btnStyle: any = {
    height: btnHeight,
    borderRadius: btnHeight / 2,
    minWidth: btnHeight,
  }

  switch (buttonDeliveryProcessState) {
    case DELIVERY_STATES.sentToNative:
    case DELIVERY_STATES.installing:
    case DELIVERY_STATES.queued:
      return {
        progressVarient: "indeterminate",
        progress: 0,
        btnStyle: { ...btnStyle, background: "transparent" },
        label: "",
      }
    case DELIVERY_STATES.downloading:
      return {
        btnIcon: "close",
        progressVarient: "determinate",
        onClickHandler: handleCancelDownloadClick,
        btnStyle: { ...btnStyle, background: "transparent" },
        progress,
        label: "",
      }
    case DELIVERY_STATES.installed:
    case DELIVERY_STATES.launched:
      return {
        label: type === "small" ? "Play" : "Play Now",
        btnIcon: "arrow-right",
        onClickHandler: handleLaunchClick,
        btnStyle,
        progress,
      }
    case DELIVERY_STATES.update:
      return {
        label: "Update",
        btnIcon: "install",
        onClickHandler: handleUpdateClick,
        btnStyle,
        progress,
      }
    case DELIVERY_STATES.failed:
    case DELIVERY_STATES.error:
    case DELIVERY_STATES.cancelled:
    default:
      return {
        label: getLabel(),
        btnIcon: "install",
        progress,
        btnStyle,
        originalPrice,
        onClickHandler: handleDefaultClick,
      }
  }
}
