import { Collapse } from "@mui/material";
import classNames from "classnames";
import { useState } from "react";
import { AppInfo } from "types";
import CTABtn from "../CTABtn";
import { Column } from "../Layout";
import Skeleton from "../Skeleton";
import { Body, Caption, Subtitle } from "../base/Typography";
import css from "./GameInfo.module.scss";

interface GameInfoProps {
  app?: AppInfo
  title?: string
}

export interface AppInfoField {
  icon: string
  label: string
  value: any
}

const InfoSkeleton = () => <div margin="tb-lg">
  <Skeleton width="100%" sx={{ marginBottom: '6px' }} />
  <Skeleton width="90%" sx={{ marginBottom: '6px' }} />
  <Skeleton width="80%" sx={{ marginBottom: '6px' }} />
  <Skeleton width="60%" sx={{ marginBottom: '6px' }} />
</div>

export default function GameInfo({ app, title }: GameInfoProps) {

  const [expand, setExpand] = useState(false);

  return <Column gap="24px" padding="0 1.5rem">
    <div>
      <Collapse in={expand} collapsedSize="254px">
        <div className={classNames(css.gameInfoContent, {
          [css.contentCollapsed]: !expand,
        })}>
          <Subtitle className={classNames(css.contentTitle, css.title)}>About {title || app?.locale.title}</Subtitle>
          {app ?
            <Body className={css.contentBody}><div
              dangerouslySetInnerHTML={{ __html: (app as AppInfo)?.locale.descriptionHtml }}
            ></div></Body>
            : <InfoSkeleton />}
          <Subtitle className={classNames(css.whatsNew, css.title)}>What's new</Subtitle>
          {app ?
            <Body className={css.contentBody}>
              <p>
                {app?.locale.title} v{app?.version} Update
              </p>
              <div
                dangerouslySetInnerHTML={{ __html: (app as AppInfo)?.locale.recentChanges }}
              ></div>
            </Body> :
            <InfoSkeleton />
          }
        </div>
      </Collapse>

      <CTABtn onClick={() => setExpand(!expand)} className={css.expandButton}>
        <Caption>{expand ? "Read Less" : "Read More"}</Caption>
      </CTABtn>
    </div >
  </Column >
}