import { Button } from "@mui/material"
import classNames from "classnames"
import { ReactNode } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import BackBtn from "./BackBtn"
import Icon from "./Icon"
import { Column, Row } from "./Layout"
import css from "./PageHeader.module.scss"
import { Subtitle, Title } from "./base/Typography"

interface PageHeaderProps {
  title?: string
  titleSize?: "medium" | "small" | "large"
  backBtn?: boolean
  settingsMenu?: boolean
  children?: ReactNode
  background?: "default" | "large" | "fullGradient"
}

interface PageHeaderProps {
  title?: string
  titleSize?: "medium" | "small" | "large"
  backBtn?: boolean
  settingsMenu?: boolean
  children?: React.ReactNode
  background?: "default" | "large" | "fullGradient"
}

export default function PageHeader({
  title,
  titleSize = "large",
  backBtn,
  settingsMenu,
  children = null,
  background = "default",
}: PageHeaderProps) {
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Column
      className={classNames(css.pageHeader, css[background])}
      dataTestId="page-header"
    >
      <Row align="msb" padding="lg 1.5rem xs" gap="sm">
        {backBtn && <BackBtn className={css.backBtn} />}

        <div col={1} style={{ minWidth: 0 }}>
          {titleSize === "large" && (
            <Title size="l" data-testid="page-header-title">
              {title}
            </Title>
          )}
          {titleSize !== "large" && (
            <Subtitle
              size={titleSize === "medium" ? "l" : "m"}
              className={classNames("ellipsis", css.titleSmall)}
              data-testid="page-header-title-small"
              skeletonWidth="70%"
            >
              {title}
            </Subtitle>
          )}
        </div>

        {location.pathname !== "/search" && (
          <Button className={css.searchBtn} data-test="search" onClick={() => navigate('/search')}>
            <Icon name="search" />
          </Button>)}

        {settingsMenu && (
          <Icon
            name="settings"
            onClick={() => setSearchParams({ showSettingsMenu: "true" })}
            dataTestId="settings-menu-button-open"
          />
        )}
      </Row>

      {children && <div padding="bottom-xs">{children}</div>}
    </Column>
  )
}
