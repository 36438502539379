import classNames from "classnames"
import AppInfoHeader from "components/appInfo/AppInfoHeader"
import AppInstallBtn from "components/AppInstallBtn"
import { Subtitle } from "components/base/Typography"
import CloseButton, { CLOSE_BUTTON_WIDTH } from "components/CloseButton"
import { Column, Row } from "components/Layout"
import { DELIVERY_STATES } from "consts"
import { GlobalStateContext } from "GlobalState"
import useReportGamePageEvents from "hooks/useReportGamePageEvents"
import * as gamesAPI from "modules/gamesAPI"
import { generateDataTestId } from "modules/utils"
import { useContext } from "react"
import Modal from "react-modal"
import { useLocation, useNavigate } from "react-router-dom"
import { useAsync } from "react-use"
import colors from "styles/colors"
import t from "translations"
import css from './NonPartnerInstallModal.module.scss'

export default function NonPartnerInstallModal() {
  const { apps } = useContext(GlobalStateContext)
  const navigate = useNavigate()
  const { state } = useLocation()
  const { modalOpen, appId } = state || {}

  const closeModal = () => navigate(-1)

  const {
    loading,
    error,
    value: app,
  } = useAsync(() => {
    if (modalOpen) {
      return gamesAPI.getAppInfo(appId)
    }
    return Promise.resolve(undefined)
  }, [modalOpen, appId])

  useReportGamePageEvents({ app })

  if (error) throw error
  const modalDataTestId = generateDataTestId({ prefix: 'modal', dataTestId: 'Non Partner Install' })

  const deliveryProcessState = app?.packageName ? apps[app.packageName]?.deliveryProcessState : undefined

  const redirect = ![DELIVERY_STATES.launched, DELIVERY_STATES.installed].includes(deliveryProcessState)
  const noAppFound = !loading && !app

  return <Modal
    isOpen={modalOpen}
    shouldCloseOnOverlayClick
    onRequestClose={closeModal}
    closeTimeoutMS={200}
    className={{ base: css.content, afterOpen: css.contentAfterOpen, beforeClose: css.contentBeforeClose }}
    overlayClassName={{ base: css.overlay, afterOpen: css.overlayAfterOpen, beforeClose: css.overlayBeforeClose }}
  >
    <Column className={css.wrapper} data-testid={modalDataTestId} style={noAppFound ? { minHeight: 150 } : undefined}>
      {noAppFound && <>
        <Row align="mr">
          <CloseButton onClose={closeModal} dataTestId={modalDataTestId} />
        </Row>
        <Row align="mc" className={css.wrapper}>No App Found</Row>
      </>}
      {!noAppFound && <>
        <Row align="tsb">
          <Column
            padding="xs xs 0 xs"
            style={{ width: `calc(100% - (${CLOSE_BUTTON_WIDTH}))` }}
            className={classNames(css.appInfoHeaderWrapper, css.wrapper)}
          >
            <AppInfoHeader
              packageName={app?.packageName}
              name={app?.locale.title}
              developer={app?.developer}
              primaryCategory={app?.primaryCategory}
              appOtherCategories={app?.otherCategories}
              tags={app?.tags}
              hideChips
            />
          </Column>
          <CloseButton onClose={closeModal} dataTestId={modalDataTestId} />
        </Row>
        <Column align="mc" padding="0 xs xs xs" margin="1.25rem 0 0 0">
          <AppInstallBtn
            appId={app?.id}
            packageName={app?.packageName}
            versionCode={app?.appDelivery?.versionCode}
            type="large"
            app={app}
            originalPrice={app?.locale?.originalPrice}
            discountPercent={app?.locale?.discountPercent}
            price={app?.locale?.price}
          />
          {redirect && <Subtitle size="s" className="ellipsis" sx={{ color: colors.textMedium, marginTop: '0.875rem' }}>{t('redirect')}</Subtitle>}
        </Column>
      </>
      }
    </Column>
  </Modal>
}