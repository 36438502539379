import * as utils from "modules/utils"
import Skeleton from "./Skeleton"

export default function Icon({
  name,
  size = 22,
  color = "#ffffff",
  bg,
  bgColor,
  style,
  onClick,
  dataTestId
}: {
  name: string
  size?: number
  color?: string
  bg?: "circle" | "square"
  bgColor?: string
  style?: any
  onClick?: () => void
  dataTestId?: string
}) {
  const bgFill = bgColor || utils.setColorOpacity(color, 0.2)

  if (!name) {
    return <Skeleton width={size} height={size} variant={bg === "circle" ? "circular" : "rectangular"} />
  }

  return (
    <svg
      width={size}
      height={size}
      version="1.1"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {bg === "circle" && <circle cx="11" cy="11" r="11" fill={bgFill} />}
      {bg === "square" && <rect width="22" height="22" x="6" fill={bgFill} />}
      <use
        name="icon"
        xlinkHref={`#icons_${name.toLowerCase()}`}
        fill={color}
        x={bg ? 4 : 0}
        y={bg ? 4 : 0}
        width={bg ? 14 : 22}
        height={bg ? 14 : 22}
      />
    </svg>
  )
}
