import Icon from "components/Icon"
import { Fragment } from "react"
import colors from "styles/colors"
import { t } from "translations"
import * as utils from "../modules/utils"
import { Column, Row } from "./Layout"
import Skeleton from "./Skeleton"
import { Subtitle } from "./base/Typography"

interface AppStatsProps {
  score?: number
  reviews?: number
  size?: number
  downloads?: string
  className?: string
}

const AppStatsSeparator = (
  <div
    className="sep"
    style={{
      display: "block",
      background: colors.cardsBackground,
      width: 2,
      height: 30,
      margin: "0 20px",
    }}
  >
    &nbsp;
  </div>
)

export default function AppStats({
  score,
  reviews,
  size,
  downloads,
  className,
}: AppStatsProps) {
  const isSkeleton = !score && !reviews && !size && !downloads
  const appStats = [
    {
      largeTitle: score && `${score.toFixed(1)}`,
      icon: <Icon name="star-full" size={16} />,
      smallTitle:
        reviews && `${utils.abbreviateNumber(reviews)} ${t("reviews")}`,
    },
    {
      hide: !size && !isSkeleton,
      component: size ? (
        <Icon name="downloads" size={20} />
      ) : (
        <Skeleton width={22} />
      ),
      smallTitle: size && utils.humanFileSize(size),
    },
    {
      largeTitle:
        downloads && `${utils.abbreviateNumber(parseInt(downloads))}+`,
      smallTitle: downloads && t("downloads"),
    },
  ]

  return (
    <Row className={className} align="mc">
      {appStats.map(({ largeTitle, smallTitle, component, hide, icon }, index) => {
        const isLast = index === appStats.length - 1
        if (hide) return null

        return (
          <Fragment key={index}>
            <Column align="mc" gap={2}>
              {largeTitle && (
                <Subtitle size="l" skeletonWidth={46} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '4px' }}>
                  {largeTitle} {icon}
                </Subtitle>
              )}
              {component}
              <Subtitle
                size="s"
                skeletonWidth={65}
                sx={{ color: colors.textMedium }}
              >
                {smallTitle}
              </Subtitle>
            </Column>
            {!isLast && AppStatsSeparator}
          </Fragment>
        )
      })}
    </Row>
  )
}
