import classNames from "classnames";
import { CardType, Layout, PlacementType } from "consts";
import useReportScrolledIntoView from "hooks/useReportScrolledIntoView";
import { reportEvent } from "modules/reporting";
import { CSSProperties, useEffect, useRef, useState } from "react";
import {
  IApp,
  IArticle,
  IAuraEvent,
  ICollection,
  IEmbeddedVideo,
  IPromoCard,
  ITab,
  IVideo,
  RelatedApp,
} from "types";
import AppCard from "./AppCard";
import AuraEventCard from "./AuraEventCard";
import css from "./Collection.module.scss";
import CollectionTitle from "./CollectionTitle";
import { Column, Row } from "./Layout";
import PodiumChart from "./PodiumChart";

import PromoCard from "./PromoCard";
import ArticleCard from "./articles/ArticleCard";
import { ArticleHeaderType } from "./articles/ArticleHeader";

interface CollectionProps {
  id: number;
  total?: number;
  limit?: number;
  title?: string;
  titleGap?: string;
  layout?: Layout;
  cardType?: CardType;
  hideReadMore?: boolean;
  placements: (IApp | IArticle | IPromoCard | RelatedApp)[];
  style?: CSSProperties;
  showTitle?: boolean;
  hidePodium?: boolean;
  collection?: ICollection;
  tab?: ITab;
  loading?: boolean;
  debug?: {
    [slotId: number]: { reason: string; placementType: PlacementType };
  };
}
const APPS_IN_PODIUM = 3;

const Collection = ({
  id,
  total,
  limit,
  title = "",
  titleGap,
  layout = Layout.VERTICAL,
  cardType = CardType.DEFAULT,
  hideReadMore = false,
  placements = [],
  style,
  showTitle = false,
  hidePodium = false,
  collection, // for reporting
  tab, // for reporting
  loading, // for reporting
  debug,
}: CollectionProps) => {
  const collectionRef = useRef(null);

  useReportScrolledIntoView(
    collectionRef,
    "discover",
    "collection shown",
    {
      tab,
      collection,
      loading,
    },
    (data: any) => !data.loading && data.collection && data.collection?.id,
  );

  // Only for horizontal layout
  const collectionScrollRef = useRef<HTMLDivElement>(null);
  const [scrollMileStones, setScrollMileStones] = useState<
    Record<number, boolean>
  >({});
  useEffect(() => {
    if (layout !== "horizontal") return;

    const el = collectionScrollRef?.current;
    if (!el) return;

    const updatePosition = () => {
      const scrollPcent =
        el.scrollLeft / (el.scrollWidth - el.getBoundingClientRect().width);
      const normalized = Math.floor(Math.round(scrollPcent * 100) / 25);

      if (normalized > 0 && !scrollMileStones[normalized]) {
        setScrollMileStones({ ...scrollMileStones, [normalized]: true });
        if (!loading && collection?.id) {
          reportEvent("discover", "collection scrolled", {
            scroll_depth: `${normalized * 25}%`,
            tab,
            collection,
          });
        }
      }
    };

    el.addEventListener("scroll", updatePosition);

    // 👇️ remove the event listener when component unmounts
    return () => {
      el.removeEventListener("scroll", updatePosition);
    };
  }, [collectionScrollRef, scrollMileStones]);

  const isChartLayout = layout === Layout.CHART;

  if (singleEntityInCollection()) {
    return displaySingleEntity();
  }

  const appCards = placements.map((placement, index) => {
    switch (placement.type) {
      case PlacementType.PROMO_CARD:
        return buildPromoCard(placement as IPromoCard);
      case PlacementType.ARTICLE:
        return buildArticleCard(placement as IArticle, ArticleHeaderType.CARD);
      case PlacementType.AURA_APP_EVENT:
        return buildAuraEventCard(placement as IAuraEvent);
      case PlacementType.VIDEO: {
        const { app, embeddedVideo } = mapAppFromVideo(placement as IVideo);
        return buildApp(app as IApp, index, embeddedVideo);
      }
      default:
        return buildApp(placement as IApp, index);
    }
  });

  function mapAppFromVideo(video: IVideo): {
    app: IApp;
    embeddedVideo: IEmbeddedVideo;
  } {
    return {
      app: {
        id: video.app.id,
        isPartnerApp: video.app.isPartnerApp,
        packageName: video.app.packageName,
        ratings: video.app.ratings,
        score: video.app.score,
        title: video.app.title,
        video: video.app.video,
        videoImage: video.app.videoImage,
        videoPreview: video.app.videoPreview,
        icon: video.app.icon,
        headerImage: video.app.headerImage,
        type: PlacementType.APP,
      },
      embeddedVideo: {
        id: video.id,
        title: video.title,
        length: video.length,
        url: video.url,
        source: video.source,
        metadata: video.metadata,
        dpId: video.dpId,
      },
    };
  }

  function singleEntityInCollection() {
    return (
      placements.length === 1 &&
      [PlacementType.ARTICLE, PlacementType.PROMO_CARD].includes(
        placements[0].type,
      )
    );
  }

  function displaySingleEntity() {
    switch (placements[0].type) {
      case PlacementType.PROMO_CARD:
        return buildPromoCard(placements[0] as IPromoCard);
      case PlacementType.ARTICLE:
        return (
          <>
            <CollectionTitle title={title} limit={limit} total={0} id={id} />
            <div style={{ padding: "0 1.333rem" }}>
              <ArticleCard {...placements[0]} />
            </div>
          </>
        );
    }
  }

  function buildPromoCard(promoCard: IPromoCard) {
    return (
      <PromoCard
        imageUrl={promoCard.imageUrl}
        title={promoCard.title}
        // reporting
        tab={tab || null}
        promoCard={promoCard as IPromoCard}
      />
    );
  }

  function buildArticleCard(
    article: IArticle,
    type = ArticleHeaderType.COLLECTION,
  ) {
    return <ArticleCard {...article} type={type} />;
  }

  function buildApp(app: IApp, index: number, embeddedVideo?: IEmbeddedVideo) {
    const iconSize = cardType === CardType.LARGE ? 36 : 52;

    return (
      <AppCard
        key={index}
        id={app.id}
        // app metadata
        packageName={app.packageName}
        title={app.title}
        score={app.score}
        versionCode={app.versionCode}
        isPartnerApp={app.isPartnerApp}
        iconSize={iconSize}
        embeddedVideo={embeddedVideo}
        originalPrice={app.originalPrice}
        price={app.price}
        discountPercent={app.discountPercent}
        // Look and feel
        cardType={cardType}
        hideReadMore={hideReadMore}
        app={app}
        tab={tab}
        collection={collection}
      />
    );
  }

  function buildAuraEventCard(auraEvent: IAuraEvent) {
    return <AuraEventCard auraEvent={auraEvent} isCard={true} />;
  }

  function buildChartLayout() {
    const apps = placements as IApp[];
    const top3 = apps.slice(0, APPS_IN_PODIUM);
    const appCardsRest = hidePodium ? appCards : appCards.slice(APPS_IN_PODIUM);

    return (
      <>
        {!hidePodium && <PodiumChart apps={top3} collection={collection} />}
        <Column
          ref={collectionRef}
          className={classNames(css.collection, css.charts)}
          padding="0 1.5rem"
          style={style}
        >
          <div className={css.chartList}>
            {appCardsRest.map((card, i) => {
              const chartRank = hidePodium ? i + 1 : APPS_IN_PODIUM + i + 1;
              return (
                <Row key={i} align="mc" padding="0 0 0 md">
                  <div className={css.chartRank}>{chartRank}</div>
                  <div col={1} style={{ minWidth: 0 }}>
                    {card}
                  </div>
                </Row>
              );
            })}
          </div>
        </Column>
      </>
    );
  }

  function buildHorizontalLayout() {
    return (
      <div ref={collectionRef} className={css.collection} style={style}>
        {showTitle && (
          <CollectionTitle
            title={title}
            limit={limit}
            total={total}
            id={id}
            debug={debug}
          />
        )}
        <div
          ref={collectionScrollRef}
          className={classNames(css.appCards, css.horizontal)}
        >
          {appCards}
        </div>
      </div>
    );
  }

  function buildVerticalLayout() {
    return (
      <div ref={collectionRef} className={css.collection} style={style}>
        {showTitle && (
          <CollectionTitle
            title={title}
            titleGap={titleGap}
            limit={limit}
            total={total}
            id={id}
            debug={debug}
          />
        )}
        <Column
          className={classNames(css.appCards, css.vertical)}
          padding="0 1.5rem"
          gap="sm"
        >
          {appCards}
        </Column>
      </div>
    );
  }

  if (isChartLayout) {
    return buildChartLayout();
  } else if (layout === Layout.HORIZONTAL) {
    return buildHorizontalLayout();
  } else {
    return buildVerticalLayout();
  }
};

export default Collection;
