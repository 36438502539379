import { Skeleton as MuiSkeleton, SkeletonProps as MuiSkeletonProps } from "@mui/material";
import { generateDataTestId } from "modules/utils";
import { FC } from "react";
import { secondary } from "styles/colors";

export interface SkeletonProps extends MuiSkeletonProps {
  dataTestId?: string
  bgColor?: string
}

const Skeleton: FC<SkeletonProps> = ({ variant, width, height, children, sx, dataTestId, bgColor, ...props }) => {
  const isTextVariant = !variant || variant === 'text'
  return <MuiSkeleton
    variant={variant}
    width={width}
    height={height}
    sx={{ ...sx, bgcolor: bgColor || secondary, ...(isTextVariant ? { transform: 'scale(1, 0.8)' } : {}) }}
    data-testid={generateDataTestId({ prefix: "skeleton", dataTestId })}
    {...props}
  >
    {children}
  </MuiSkeleton >
}

export default Skeleton