import classNames from "classnames";
import Icon from "components/Icon";
import { Column } from "components/Layout";
import { CardType } from "consts";
import useReportAppImpressionEvent from 'hooks/useReportAppImpressionEvent';
import useReportScrolledIntoView from "hooks/useReportScrolledIntoView";
import { useRef, useState } from "react";
import { IApp, IAuraEvent } from "types";
import { reportEvent } from "../modules/reporting";
import { formatEventDate } from "../modules/utils";
import AppCard from "./AppCard";
import css from "./AuraEventCard.module.scss";
import { Caption, Subtitle } from "./base/Typography";
import EventInfoModal from "./modals/EventInfoModal";


interface AuraEventCardProps {
  auraEvent: IAuraEvent
  isCard?: boolean
}

const AuraEventCard = ({ auraEvent, isCard = true }: AuraEventCardProps) => {

  const [isOpenEventModal, setIsOpenEventModal] = useState(false);
  const eventCardRef = useRef<HTMLDivElement>(null)

  useReportScrolledIntoView(eventCardRef, "discover", "item impression", {
    eventCard: auraEvent
  })

  useReportAppImpressionEvent({ data: { eventCard: auraEvent }, app: auraEvent.app as IApp, elRef: eventCardRef })

  const handleClick = () => {
    reportEvent("discover", "item clicked", {
      eventCard: auraEvent
    })
    setIsOpenEventModal(true)
  }

  const { id, isPartnerApp, packageName, title, versionCode, score } = auraEvent.app

  return (
    <>
      <div
        className={css.eventCardContainer}
        ref={eventCardRef}
      >
        <Column
          className={classNames(css.eventCard, isCard && css.onlyTopBorderRadius)}
          align="t"
          onClick={handleClick}
        >
          <img src={auraEvent.fullImageUrl} className={css.coverPhoto} style={{ width: isCard ? "100%" : "86vw" }}>
          </img>
          <div className={classNames(css.overlay)}></div>
          <Column align="sb" className={css.eventBody}>
            <div className={css.endTime}>
              <Icon name="clock" size={14} />
              <Caption>
                {`Ends on ${formatEventDate(auraEvent.endTime)}`}</Caption>
            </div>
            <div className={css.eventInfo}>
              <div className={css.eventBadge}>
                <Icon name="star-small" size={14} />
                <Caption>Event</Caption>
              </div>
              <div className={css.eventText}>
                <Subtitle className={css.eventTitle} bold={true} lineHeight="22px">{auraEvent.title}</Subtitle>
                <Subtitle className={css.desc} size="s">{auraEvent.description}</Subtitle>
              </div>
            </div>
          </Column>
        </Column>
        {
          isCard && <AppCard
            id={id}
            isPartnerApp={isPartnerApp}
            packageName={packageName}
            title={title}
            versionCode={versionCode}
            score={score}
            iconSize={36}
            app={auraEvent.app}
            hideReadMore={true}
            cardType={CardType.DEFAULT}
            event={auraEvent} />
        }
      </div >
      <EventInfoModal
        auraEvent={auraEvent}
        endTime={formatEventDate(auraEvent.endTime)}
        isOpen={isOpenEventModal}
        closeModal={() => setIsOpenEventModal(false)} />
    </>
  )
}

export default AuraEventCard;