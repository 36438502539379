import Collection from "components/Collection"
import { CardType, Layout } from "consts"
import { RelatedApp } from "types"

interface ArticleCollectionProps {
  content?: string
  relatedApps: RelatedApp[]
  layout?: Layout
  title?: string,
  titleGap?: string
}

export default function ArticleCollection({ content, relatedApps, layout = Layout.HORIZONTAL, title, titleGap }: ArticleCollectionProps) {
  return <Collection
    id={1}
    cardType={CardType.DEFAULT}
    hidePodium
    showTitle={!!title}
    placements={content ? relatedApps.filter(({ packageName }) => content.includes(packageName)) : relatedApps}
    title={title}
    titleGap={titleGap}
    layout={layout}
  />
}