import useReportAppImpressionEvent from 'hooks/useReportAppImpressionEvent'
import { useRef } from 'react'
import useNavigateToAppInfo from "../hooks/useNavigateToAppInfo"
import useReportScrolledIntoView from "../hooks/useReportScrolledIntoView"
import { reportEvent } from "../modules/reporting"
import { IApp, IPromoCard, ITab } from '../types'
import css from "./PromoCard.module.scss"

interface PromoCardProps {
  promoCard: IPromoCard
  imageUrl: string
  title: string
  tab: ITab | null
}

export default function PromoCard({ promoCard, imageUrl, title, tab = null }: PromoCardProps) {
  const promoCardRef = useRef(null)
  const navigateToAppInfo = useNavigateToAppInfo()

  useReportScrolledIntoView(promoCardRef, "discover", "promo card shown", {
    promoCard
  })

  useReportAppImpressionEvent({ data: { promoCard }, app: promoCard.app as IApp, elRef: promoCardRef })


  function handleClick() {
    reportEvent("discover", "promo card clicked", {
      promoCard
    })
    if (promoCard.app) {
      const { id, isPartnerApp, title } = promoCard.app
      navigateToAppInfo({ appId: id, isPartnerApp, appTitle: title })
    }
  }

  return (
    <div ref={promoCardRef} className={css.promoCard} onClick={handleClick}>
      <img src={imageUrl} alt={title} width={"100%"} />
    </div>
  )
}