import classNames from "classnames";
import AppIcon from "components/AppIcon";
import BackBtn from "components/BackBtn";
import { Column, Row } from "components/Layout";
import Rating from "components/Rating";
import AppInfoInstallBtn from "components/appInfo/AppInfoInstallBtn";
import ArticleContent from "components/articles/ArticleContent";
import ArticleHeader from "components/articles/ArticleHeader";
import { Button, Subtitle } from "components/base/Typography";
import useNavigateToAppInfo from "hooks/useNavigateToAppInfo";
import useReportAppImpressionEvent from "hooks/useReportAppImpressionEvent";
import useReportPageEvents, {
  PAGE_EVENT_DATA_TYPE,
} from "hooks/useReportPageEvents";
import useScrollTopSnap from "hooks/useScrollTopSnap";
import * as gamesAPI from "modules/gamesAPI";
import { assetURL } from "modules/utils";
import { useCallback, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAsync } from "react-use";
import texts from "styles/texts";
import { GetArticleResponse } from "types";
import css from "./AppInfoPage.module.scss";

function getArticleAppInfo(packageName: string | undefined) {
  if (!packageName) {
    return;
  }
  return gamesAPI.getAppInfo(packageName);
}

export default function ArticlePage() {
  const { search } = useLocation();
  const { articleId } = useParams();
  const headerRef = useRef(null);
  const [isHeaderRefInitialized, setIsHeaderRefInitialized] = useState(false);
  const headerStuck = useScrollTopSnap(headerRef, isHeaderRefInitialized);
  const navigateToAppInfo = useNavigateToAppInfo();
  const isPreviewMode = !articleId;

  const setRef = useCallback((node: any) => {
    if (node) {
      setIsHeaderRefInitialized(true);
    }

    headerRef.current = node;
  }, []);

  // Load app info from API
  const { loading, error, value } = useAsync(async () => {
    if (isPreviewMode && search) {
      const { article, relatedArticles, relatedApps } = JSON.parse(
        decodeURIComponent(search as string).substring(1),
      ) as unknown as GetArticleResponse;
      return {
        article,
        relatedArticles,
        relatedApps,
        app: await getArticleAppInfo(article.appPackageName),
      };
    }

    const { article, relatedArticles, relatedApps } = await gamesAPI.getArticle(
      Number(articleId),
    );
    const app = await getArticleAppInfo(article.appPackageName);

    return { article, app, relatedApps, relatedArticles };
  });

  useReportPageEvents(
    value && { article: value?.article },
    PAGE_EVENT_DATA_TYPE.ARTICLE,
  );
  useReportAppImpressionEvent({
    data: { article: value?.article },
    app: value?.app,
  });

  if (loading || (isPreviewMode && !search)) return null;
  if (error) throw error;
  if (!value?.article) return <Row align="mc">Article Not Found</Row>;

  const { article, app, relatedArticles, relatedApps } = value;

  let coverPhoto = article.coverPhoto;
  if (!coverPhoto && app?.locale?.headerImage) {
    coverPhoto = assetURL(app.packageName, "headerImage");
  }

  return (
    <Column className={css.appInfo} style={{ minHeight: "100%" }}>
      {/* @ts-ignore */}
      <BackBtn className={css.backBtn} />

      <ArticleHeader
        title={article.title}
        chipLabel={article.label}
        chipIcon={article.labelIcon}
        backgroundImage={coverPhoto}
      />

      <Column
        ref={setRef}
        col={1}
        gap={20}
        padding={app ? "lg 0 0 0" : "lg 0"}
        className={classNames(css.header, {
          [css.top]: headerStuck,
        })}
      >
        {app && (
          <Column padding="0 1.5rem">
            <Row align="ml" gap={10}>
              <AppIcon
                packageName={app.packageName}
                size={54}
                borderRadius={6}
              />
              <Column>
                <Subtitle>{app.locale.title}</Subtitle>
                <Rating value={app.score} />
                <Button
                  size="s"
                  underline
                  sx={{ color: texts.textMedium }}
                  onClick={() =>
                    navigateToAppInfo({
                      isPartnerApp: app.isPartnerApp,
                      appId: app.id,
                      appTitle: app?.locale.title,
                    })
                  }
                >
                  More Info
                </Button>
              </Column>
            </Row>
          </Column>
        )}
        <ArticleContent
          article={article}
          app={app}
          relatedApps={relatedApps}
          relatedArticles={relatedArticles}
        />
        {app && <AppInfoInstallBtn app={app} />}
      </Column>
    </Column>
  );
}
