import { Box } from "@mui/material";
import { GlobalStateContext } from "GlobalState";
import classNames from "classnames";
import useNavigateToAppInfo from "hooks/useNavigateToAppInfo";
import { getAppInstallBtnProps } from "modules/appInstall";
import * as nativeAPI from "modules/nativeAPI";
import { reportConversion } from "modules/reporting";
import * as utils from "modules/utils";
import { generateDataTestId } from "modules/utils";
import { useContext } from "react";
import css from "./AppInstallBtn.module.scss";
import CTABtn from "./CTABtn";
import ProgressBar from "./ProgressBar";
import Skeleton from "./Skeleton";

export default function AppInstallBtn({
  appId,
  packageName = "",
  versionCode = -1,
  isPartnerApp,
  type = "large",
  originalPrice = null,
  discountPercent = null,
  price = null,

  app = null, // for reporting
  collection = null, // for reporting
  tab = null, // for reporting
  background,
}: {
  appId?: number;
  packageName?: string;
  versionCode?: number;
  isPartnerApp?: boolean;
  type: "small" | "large";

  app: any;
  collection?: any;
  tab?: any;
  background?: string;
  discountPercent?: number | null;
  price?: number | null;
  originalPrice?: number | null;
}) {
  const {
    apps,
    getCampaignForApp,
    closeModal,
    setCampaignForApp,
    clearCampaignForApp,
  } = useContext(GlobalStateContext);
  const {
    installedVersionCode,
    deliveryProcessVersionCode,
    deliveryProcessState,
    progress: appProgress,
  } = apps[packageName] || {};

  const navigateToAppInfo = useNavigateToAppInfo();

  const containerWidth = type === "large" ? "100%" : 60;
  const progressThickness = type === "large" ? 3.5 : 2.5;
  const btnHeight: number = type === "large" ? 40 : 28;

  function reportClickEvent(funnelStep: string) {
    const campaign = getCampaignForApp(app.id);
    reportConversion(packageName, funnelStep, {
      app,
      campaign,
      collection,
      tab,
    });
  }

  const {
    btnStyle,
    label,
    onClickHandler,
    progressVarient,
    progress,
    btnIcon,
  } = getAppInstallBtnProps({
    deliveryProcessState,
    versionCode,
    deliveryProcessVersionCode,
    packageName,
    installedVersionCode,
    progress: appProgress,
    btnHeight,
    type,
    hasAppDelivery: !!app?.appDelivery,
    originalPrice,
    discountPercent,
    price,
    handleUpdateClick: () => {
      reportClickEvent("update button clicked");
      if (isPartnerApp) {
        appId &&
          nativeAPI.packageDeliver(
            appId,
            packageName,
            true,
            getCampaignForApp(appId),
          );
      } else {
        nativeAPI.openUrl(
          `https://play.google.com/store/apps/details?id=${packageName}`,
        );
      }
    },
    handleCancelDownloadClick: () => {
      reportClickEvent("install cancel clicked");
      nativeAPI.packageCancelDelivery(packageName);
    },
    handleLaunchClick: () => {
      reportClickEvent("play button clicked");
      nativeAPI.packageLaunch(packageName);
    },
    handleDefaultClick:
      type === "large"
        ? () => {
            reportClickEvent("install button clicked");

            if (isPartnerApp) {
              appId &&
                nativeAPI.packageDeliver(
                  appId,
                  packageName,
                  false,
                  getCampaignForApp(appId),
                );
            } else {
              closeModal();
              if (utils.isIOSWebview()) {
                nativeAPI.openUrl(`itms-apps://itunes.apple.com`);
              } else {
                nativeAPI.openUrl(
                  `https://play.google.com/store/apps/details?id=${packageName}`,
                );
              }
            }
          }
        : () => {
            if (!app.campaign) {
              clearCampaignForApp(app.id);
            } else {
              setCampaignForApp(app.id, app.campaign);
            }

            navigateToAppInfo({
              isPartnerApp,
              appId,
              appTitle: app?.title,
              campaign: app?.campaign,
            });
          },
  });

  return (
    <Box
      className={classNames(css.appInstallBtn)}
      sx={{ width: containerWidth, height: btnHeight }}
    >
      {progressVarient && (
        <Box
          className={classNames(css.progressWrapper)}
          sx={{
            left: `calc(50% - ${btnHeight / 2}px)`,
            width: btnHeight,
            height: btnHeight,
          }}
        >
          <ProgressBar
            variant={progressVarient}
            value={progress}
            size={btnHeight}
            thickness={progressThickness}
          />
        </Box>
      )}
      {appId ? (
        <CTABtn
          className={classNames(css.ctaBtn)}
          style={{
            ...btnStyle,
            ...(background && { background }),
            opacity: label || btnIcon ? 1 : 0,
            width: progressVarient ? btnHeight : "100%",
          }}
          onClick={onClickHandler}
          iconRight={type === "large" && !discountPercent ? btnIcon : undefined}
          iconSize={type === "large" ? 22 : 16}
          type={type}
          dataTestId={generateDataTestId({
            prefix: "app-install",
            properties: [label, packageName],
          })}
        >
          {label}{" "}
          {!!discountPercent && type === "large" && (
            <div className={classNames(css.originalPrice)}>
              {originalPrice} $
            </div>
          )}
        </CTABtn>
      ) : (
        <Skeleton
          variant="rounded"
          width={type === "large" ? "100%" : 60}
          height={type === "large" ? 40 : 28}
          bgColor={background}
          sx={{ borderRadius: "60px" }}
          animation={type === "large" ? false : "pulse"}
        />
      )}
    </Box>
  );
}
