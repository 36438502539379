import styled from "@emotion/styled"
import { Collapse } from "@mui/material"
import Icon from "components/Icon"
import { Column, Row } from "components/Layout"
import { Caption, Subtitle } from "components/base/Typography"
import { parseArticleLines } from "modules/utils"
import { useState } from "react"
import colors from "styles/colors"

type GameFeatureProps = { label: string, icon: string }

const Circle = styled(Row)`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  padding: 10px;
  background: ${colors.secondary};
`

function GameFeature({ label, icon }: GameFeatureProps) {
  return <Column gap={4} align="mc" style={{ width: 65, height: 70 }}>
    <Circle align="mc">
      <Icon name={icon} size={20} color={colors.textWhite} />
    </Circle>
    <Caption className="ellipsis" sx={{ maxWidth: 65 }}>{label}</Caption>
  </Column>
}

function getGameFeatures(content: string): GameFeatureProps[] {
  return parseArticleLines(content).map(line => {
    const [label, icon] = line.split('[')
    return { label: label.trim(), icon: icon.replace(']', '')?.trim() }
  })
}

export default function ArticleGameFeatures({ content }: { content: string }) {
  const [expand, setExpand] = useState(false)

  const gameFeatures = getGameFeatures(content)

  return <Column
    gap={expand ? 12 : 0}
    style={{
      background: colors.cardsBackground,
      padding: 10,
      borderRadius: 10,
    }}>
    <Row align="msb" onClick={() => setExpand(!expand)}>
      <Row gap={5} align="m">
        <Icon name="trophy" size={16} color={colors.textWhite} />
        <Subtitle>Game Features</Subtitle>
      </Row>
      <Icon
        name="chevron-right"
        size={22}
        color={colors.textWhite}
        style={expand ? { transform: 'rotate(90deg)' } : undefined}
      />
    </Row>
    <Collapse in={expand}>
      <Row gap={10} padding="0 sm" margin="0 0 4px 0" align="msb">
        {gameFeatures.map((gameFeature, i) => <GameFeature key={i} {...gameFeature} />)}
      </Row>
    </Collapse>
  </Column>
}