
import { Body, Subtitle, Title } from 'components/base/Typography'
import ReactMarkdown, { Components, Options } from 'react-markdown'
import colors from 'styles/colors'

interface ArticleMarkdownProps extends Omit<Options, 'children'> {
  content: string
  components?: Components
}

export default function ArticleMarkdown({ content, components = {}, ...props }: ArticleMarkdownProps) {
  return <ReactMarkdown
    {...props}
    components={{
      h1({ children }) {
        return <Title size="l" padding="0 1.5rem">{children}</Title>
      },
      h2({ children }) {
        return <Title padding="0 1.5rem">{children}</Title>
      },
      h3({ children }) {
        return <Title size="s" bold={true} padding="0 1.5rem">{children}</Title>
      },
      h4({ children }) {
        return <Subtitle size="l" padding="0 1.5rem">{children}</Subtitle>
      },
      h5({ children }) {
        return <Subtitle padding="0 1.5rem">{children}</Subtitle>
      },
      h6({ children }) {
        return <Subtitle size="s" padding="0 1.5rem">{children}</Subtitle>
      },
      pre: 'div',
      p({ children }) {
        return <Body size="l" sx={{ color: colors.textLight }} lineHeight="26px" padding="0 1.5rem">{children}</Body>
      },
      ...components
    }}
    children={content}
  />
}