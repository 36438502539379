import ReactSelect, { components, OptionProps, ValueContainerProps } from 'react-select'
import { Dispatch } from 'react'
import colors from 'styles/colors'
import texts from 'styles/texts'
import Icon from './Icon'

interface SelectOption {
  label: string
  value: string
  icon?: string
}

interface SelectProps {
  options: SelectOption[]
  onChange: Dispatch<string>
  selectedValue: string
}

const Option = (props: OptionProps) => {
  const icon = (props.data as SelectOption).icon
  return <div style={{ display: 'flex', gap: '12px', padding: '0.5rem 0.75rem', font: texts.subtitleM, color: colors.textWhite }}>
    {icon && <Icon name={icon} size={22} />}
    <components.Option {...props} />
  </div>
}

const ValueContainer = (props: ValueContainerProps) => {
  const icon = (props.getValue() as SelectOption[])?.[0]?.icon
  return <div style={{ ...(icon && { display: 'flex', gap: '12px', }), font: texts.subtitleM, color: colors.textWhite }}>
    {icon && <Icon name={icon} size={22} />}
    <components.ValueContainer {...props} />
  </div>
}

export default function Select({ options, onChange, selectedValue }: SelectProps) {
  return <ReactSelect
    options={options}
    onChange={(selectedValue) => onChange((selectedValue as SelectOption).value)}
    components={{ IndicatorSeparator: () => null, Option, ValueContainer }}
    unstyled
    styles={{
      control: (baseStyles) => ({
        ...baseStyles,
        border: 'none',
        height: 49,
        borderRadius: 10,
        background: colors.cardsBackground,
        padding: '8px 8px 8px 16px'
      }),
      placeholder: (baseStyles) => ({
        ...baseStyles,
        font: texts.subtitleM,
        color: colors.textLight
      }),
      dropdownIndicator: (baseStyles, state) => ({
        ...baseStyles,
        color: colors.textWhite,
        ...(state.selectProps.menuIsOpen && { transform: 'rotate(180deg)' })
      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        background: colors.secondary,
        borderRadius: 10,
        padding: '0.5rem 0',
        marginTop: 2
      }),
    }}
    placeholder="Select your request"
  />
}