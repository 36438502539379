import { Chip } from "@mui/material";
import classNames from "classnames";
import Icon from "components/Icon";
import { Column } from "components/Layout";
import { Subtitle, Title } from "components/base/Typography";
import { forwardRef } from "react";
import colors from "styles/colors";
import texts from "styles/texts";
import css from "./ArticleHeader.module.scss";

export enum ArticleHeaderType {
  DEFAULT = "default",
  CARD = "card",
  COLLECTION = "collection"
}

interface ArticleHeaderProps {
  backgroundImage?: string
  chipLabel: string
  chipIcon?: string
  title: string
  type?: ArticleHeaderType
  onClick?: () => void
}

export default forwardRef<HTMLDivElement, ArticleHeaderProps>(function (
  { backgroundImage, chipLabel, chipIcon, title, type = ArticleHeaderType.DEFAULT, onClick }: ArticleHeaderProps,
  ref) {
  const isDefault = type === ArticleHeaderType.DEFAULT
  const isCollection = type === ArticleHeaderType.COLLECTION
  const isCard = type === ArticleHeaderType.CARD

  return <Column
    align="tr"
    className={classNames(css.coverPhoto, css[type])}
    margin={isCollection ? 'md 1.5rem' : undefined}
    style={{
      backgroundImage: `url('${backgroundImage || "/img/app_banner_fallback.svg"}')`,
      ...(isCard && { display: 'inline-flex' }),
      height: '212px'
    }}
    onClick={onClick}
    ref={ref}
  >
    <Column
      gap={isDefault ? 10 : 6}
      align="t"
      padding={`${isDefault ? 36 : 20}px 20px`}
      className={css.header}
    >
      {chipLabel && <Chip
        label={chipLabel}
        icon={<Icon name={chipIcon || "star-big"} size={12} color={colors.textWhite} />}
        sx={{
          font: texts.caption,
          textTransform: "capitalize",
          background: colors.primaryGradient,
          color: colors.textWhite,
          padding: '4px 6px 4px 4px',
          boxShadow: "0px 1px 4px 0px rgba(34, 27, 64, 0.25)",
          height: 22,
          '& .MuiChip-label': {
            padding: `0 0 0 2px`,
          },
          borderRadius: '4px'
        }}
      />}
      {(isDefault || isCollection) ?
        <Title size="s" bold={true}>{title}</Title> :
        <Subtitle size="l" variant="h4" bold>{title}</Subtitle>
      }
    </Column>
  </Column >
})
