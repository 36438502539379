import { getCurrentPage, reportEvent, setCurrentPage } from "modules/reporting"
import { useEffect, useState } from "react"
import { useLocation } from "react-use"
import { IArticle, IAuraEvent } from "types"

export type PageEventData = IArticle | IAuraEvent
export enum PAGE_EVENT_DATA_TYPE {
  ARTICLE = "article",
  EVENT = "event",
}

export default function useReportPageEvents(
  data?: Partial<Record<PAGE_EVENT_DATA_TYPE, PageEventData>>,
  type?: PAGE_EVENT_DATA_TYPE
) {
  const { pathname = "/" } = useLocation()
  const [shouldWaitForData, setShouldWaitForData] = useState(false)

  useEffect(() => {
    const currentPage = getCurrentPage()
    const hasPathChanged = currentPage?.path !== pathname

    if (hasPathChanged) {
      if (pathname !== "/") {
        const title = pathname
          ?.replace(/^\//, "")
          .replace(/_|-/gi, " ")
          .replace(/\//gi, " - ")

        setCurrentPage(pathname, title)
        type ? setShouldWaitForData(true) : reportEvent("app", "page view")
      }
    }

    return () => {
      if (!currentPage || type) return

      const timeSpent = new Date().getTime() - currentPage.enter.getTime()
      reportEvent("app", "page leave", { event_duration: timeSpent })
    }
  }, [])

  useEffect(() => {
    const currentPage = getCurrentPage()
    if (data) {
      if (shouldWaitForData) {
        reportEvent("app", "page view", data)
        setShouldWaitForData(false)
      } else {
        return () => {
          if (!currentPage) return

          const timeSpent = new Date().getTime() - currentPage.enter.getTime()
          reportEvent("app", "page leave", {
            event_duration: timeSpent,
            ...data,
          })
        }
      }
    }
  }, [shouldWaitForData, data?.[type as PAGE_EVENT_DATA_TYPE]])
}
