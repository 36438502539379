import { GlobalStateContext } from "GlobalState"
import classNames from "classnames"
import AppStats from "components/AppStats"
import BackBtn from "components/BackBtn"
import { Column, Row } from "components/Layout"
import ScreenshotGallery from "components/ScreenshotGallery"
import AppAndDeveloperInfo from "components/appInfo/AppAndDeveloperInfo"
import AppInfoEvents from "components/appInfo/AppInfoEvents"
import AppInfoHeader from "components/appInfo/AppInfoHeader"
import AppInfoInstallBtn from "components/appInfo/AppInfoInstallBtn"
import GameInfo from "components/appInfo/GameInfo"
import RelatedContent from "components/appInfo/RelatedContent"
import useReportGamePageEvents from "hooks/useReportGamePageEvents"
import useReportPageEvents from "hooks/useReportPageEvents"
import useScrollTopSnap from "hooks/useScrollTopSnap"
import * as gamesAPI from "modules/gamesAPI"
import { assetURL } from "modules/utils"
import { ReactNode, useCallback, useContext, useRef, useState } from "react"
import { useLocation, useParams } from "react-router-dom"
import { useAsync } from "react-use"
import { AppInfo } from "types"
import { Subtitle } from "../components/base/Typography"
import css from "./AppInfoPage.module.scss"

export const AppInfoInstallButtonWrapper = ({ children }: { children: ReactNode }) => (
  <Row align="mt" className={css.installBtn} padding="lg 1.5rem">
    {children}
  </Row>
)

export function AppInfoPage() {
  const { appId } = useParams()
  const title = useLocation()?.state?.title
  const { getCampaignForApp } = useContext(GlobalStateContext)
  useReportPageEvents()

  const headerRef = useRef(null)
  const [isHeaderRefInitialized, setIsHeaderRefInitialized] = useState(false)
  const headerStuck = useScrollTopSnap(headerRef, isHeaderRefInitialized)

  const setRef = useCallback((node: any) => {
    if (node) {
      setIsHeaderRefInitialized(true)
    }

    headerRef.current = node
  }, [])

  // Load app info from API
  const {
    loading,
    error,
    value: app,
  } = useAsync(() => gamesAPI.getAppInfo(appId as string))

  const campaign = app?.id ? getCampaignForApp(app?.id) : undefined
  useReportGamePageEvents({ app, campaign })

  if (error) throw error
  if (!app && !loading) return <Row align="mc">No App Found</Row>


  // Extract Assets
  const screenshots = app?.locale.screenshots.map((s: string, i: number) => {
    return assetURL(app?.packageName, `screenshots/${i + 1}`)
  })

  const headerImage = app?.locale.headerImage
    ? assetURL(app?.packageName, "headerImage")
    : screenshots?.[0]

  const hasRelatedContent = Boolean(
    app?.relatedArticles?.length || app?.relatedApps?.length
  )

  const hasScreenshots = Boolean(screenshots && screenshots.length > 0)

  const isEvents = Boolean(app?.locale.auraEvents && app?.locale.auraEvents.length > 0)

  return (
    <div className={css.appInfo}>
      <BackBtn className={css.backBtn} />
      <Row
        className={css.coverPhoto}
        style={{ backgroundImage: `url('${headerImage}')` }}
      />
      <div
        ref={setRef}
        className={classNames(css.header, {
          [css.top]: headerStuck,
        })}
      >
        <Column padding="1.5rem 1.5rem" gap="1.5rem">
          <AppInfoHeader
            packageName={app?.packageName}
            name={app?.locale.title}
            developer={app?.developer}
            primaryCategory={app?.primaryCategory}
            appOtherCategories={app?.otherCategories}
            tags={app?.tags}
            offersIAP={app?.offersIap}
          />

          <AppStats
            className={css.appStats}
            score={app?.score}
            reviews={app?.ratings}
            size={app?.appDelivery?.size}
            downloads={app?.maxInstalls}
          />
        </Column>
      </div>

      <Column className={css.bodyContent} gap={24}>
        {
          app && isEvents &&
          <AppInfoEvents app={app} />
        }
        {hasScreenshots && <div>
          {isEvents && <Subtitle mb={1.5} px={'1.5rem'}>Screenshots</Subtitle>}
          <ScreenshotGallery images={screenshots} app={app} padding="0 0 0 1.5rem" />
        </div>}

        <GameInfo app={app} title={title} />
        {hasRelatedContent && <RelatedContent app={app as AppInfo} />}
        <AppAndDeveloperInfo app={app} />
      </Column>
      <AppInfoInstallBtn app={app} />
    </div>
  )
}
