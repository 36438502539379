import { generateDataTestId } from "modules/utils";
import Icon from "./Icon";
import { Row } from "./Layout";
import css from "./Rating.module.scss";
import Skeleton from "./Skeleton";

interface RatingProps {
  value: number;
  dataTestId?: string;
  small?: boolean;
  color?: string;
}

const parts = [
  "M5.00001 0C4.78588 0.000524071 4.57197 0.123781 4.49179 0.369771L3.67817 2.87186C3.64313 2.97927 3.57498 3.07285 3.48348 3.13919C3.39199 3.20554 3.28183 3.24126 3.16879 3.24123H0.536706C0.0182165 3.24123 -0.198178 3.90502 0.221756 4.21016L2.35142 5.75616C2.4429 5.82263 2.51098 5.91633 2.54591 6.02384C2.58084 6.13135 2.58082 6.24715 2.54585 6.35465L1.73277 8.85673C1.57208 9.34976 2.13717 9.76035 2.55656 9.45522L4.68623 7.90922C4.77744 7.84295 4.88725 7.80714 5.00001 7.8069V0Z",
  "M5.00002 0C5.21414 0.000526207 5.42805 0.123783 5.50822 0.369771L6.32184 2.87186C6.35689 2.97927 6.42503 3.07285 6.51653 3.13919C6.60803 3.20554 6.71818 3.24126 6.83123 3.24123H9.46331C9.9818 3.24123 10.1982 3.90502 9.77826 4.21016L7.6486 5.75616C7.55712 5.82263 7.48903 5.91633 7.45411 6.02384C7.41918 6.13135 7.4192 6.24715 7.45416 6.35465L8.26725 8.85673C8.42794 9.34976 7.86285 9.76035 7.44345 9.45522L5.31379 7.90922C5.22257 7.84295 5.11277 7.80715 5.00002 7.8069V0Z",
  "M17 0C16.7859 0.000524071 16.572 0.123781 16.4918 0.369771L15.6782 2.87186C15.6431 2.97927 15.575 3.07285 15.4835 3.13919C15.392 3.20554 15.2818 3.24126 15.1688 3.24123H12.5367C12.0182 3.24123 11.8018 3.90502 12.2218 4.21016L14.3514 5.75616C14.4429 5.82263 14.511 5.91633 14.5459 6.02384C14.5808 6.13135 14.5808 6.24715 14.5459 6.35465L13.7328 8.85673C13.5721 9.34976 14.1372 9.76035 14.5566 9.45522L16.6862 7.90922C16.7774 7.84295 16.8873 7.80714 17 7.8069V0Z",
  "M17 0C17.2141 0.000526207 17.4281 0.123783 17.5082 0.369771L18.3218 2.87186C18.3569 2.97927 18.425 3.07285 18.5165 3.13919C18.608 3.20554 18.7182 3.24126 18.8312 3.24123H21.4633C21.9818 3.24123 22.1982 3.90502 21.7783 4.21016L19.6486 5.75616C19.5571 5.82263 19.489 5.91633 19.4541 6.02384C19.4192 6.13135 19.4192 6.24715 19.4542 6.35465L20.2672 8.85673C20.4279 9.34976 19.8628 9.76035 19.4435 9.45522L17.3138 7.90922C17.2226 7.84295 17.1128 7.80715 17 7.8069V0Z",
  "M29 0C28.7859 0.000524071 28.572 0.123781 28.4918 0.369771L27.6782 2.87186C27.6431 2.97927 27.575 3.07285 27.4835 3.13919C27.392 3.20554 27.2818 3.24126 27.1688 3.24123H24.5367C24.0182 3.24123 23.8018 3.90502 24.2218 4.21016L26.3514 5.75616C26.4429 5.82263 26.511 5.91633 26.5459 6.02384C26.5808 6.13135 26.5808 6.24715 26.5459 6.35465L25.7328 8.85673C25.5721 9.34976 26.1372 9.76035 26.5566 9.45522L28.6862 7.90922C28.7774 7.84295 28.8873 7.80714 29 7.8069V0Z",
  "M29 0C29.2141 0.000526207 29.4281 0.123783 29.5082 0.369771L30.3218 2.87186C30.3569 2.97927 30.425 3.07285 30.5165 3.13919C30.608 3.20554 30.7182 3.24126 30.8312 3.24123H33.4633C33.9818 3.24123 34.1982 3.90502 33.7783 4.21016L31.6486 5.75616C31.5571 5.82263 31.489 5.91633 31.4541 6.02384C31.4192 6.13135 31.4192 6.24715 31.4542 6.35465L32.2672 8.85673C32.4279 9.34976 31.8628 9.76035 31.4435 9.45522L29.3138 7.90922C29.2226 7.84295 29.1128 7.80715 29 7.8069V0Z",
  "M41 0C40.7859 0.000524071 40.572 0.123781 40.4918 0.369771L39.6782 2.87186C39.6431 2.97927 39.575 3.07285 39.4835 3.13919C39.392 3.20554 39.2818 3.24126 39.1688 3.24123H36.5367C36.0182 3.24123 35.8018 3.90502 36.2218 4.21016L38.3514 5.75616C38.4429 5.82263 38.511 5.91633 38.5459 6.02384C38.5808 6.13135 38.5808 6.24715 38.5459 6.35465L37.7328 8.85673C37.5721 9.34976 38.1372 9.76035 38.5566 9.45522L40.6862 7.90922C40.7774 7.84295 40.8873 7.80714 41 7.8069V0Z",
  "M41 0C41.2141 0.000526207 41.4281 0.123783 41.5082 0.369771L42.3218 2.87186C42.3569 2.97927 42.425 3.07285 42.5165 3.13919C42.608 3.20554 42.7182 3.24126 42.8312 3.24123H45.4633C45.9818 3.24123 46.1982 3.90502 45.7783 4.21016L43.6486 5.75616C43.5571 5.82263 43.489 5.91633 43.4541 6.02384C43.4192 6.13135 43.4192 6.24715 43.4542 6.35465L44.2672 8.85673C44.4279 9.34976 43.8628 9.76035 43.4435 9.45522L41.3138 7.90922C41.2226 7.84295 41.1128 7.80715 41 7.8069V0Z",
  "M53 0C52.7859 0.000524071 52.572 0.123781 52.4918 0.369771L51.6782 2.87186C51.6431 2.97927 51.575 3.07285 51.4835 3.13919C51.392 3.20554 51.2818 3.24126 51.1688 3.24123H48.5367C48.0182 3.24123 47.8018 3.90502 48.2218 4.21016L50.3514 5.75616C50.4429 5.82263 50.511 5.91633 50.5459 6.02384C50.5808 6.13135 50.5808 6.24715 50.5459 6.35465L49.7328 8.85673C49.5721 9.34976 50.1372 9.76035 50.5566 9.45522L52.6862 7.90922C52.7774 7.84295 52.8873 7.80714 53 7.8069V0Z",
  "M53 0C53.2141 0.000526207 53.428 0.123783 53.5082 0.369771L54.3218 2.87186C54.3569 2.97927 54.425 3.07285 54.5165 3.13919C54.608 3.20554 54.7182 3.24126 54.8312 3.24123H57.4633C57.9818 3.24123 58.1982 3.90502 57.7782 4.21016L55.6486 5.75616C55.5571 5.82263 55.489 5.91633 55.4541 6.02384C55.4192 6.13135 55.4192 6.24715 55.4541 6.35465L56.2672 8.85673C56.4279 9.34976 55.8628 9.76035 55.4434 9.45522L53.3138 7.90922C53.2226 7.84295 53.1128 7.80715 53 7.8069V0Z",
];

const Rating = ({ value, dataTestId, small = false, color }: RatingProps) => {
  const paths = [];
  for (let i = 0; i < 10; i++) {
    paths.push(<path key={i} d={parts[i]} opacity={value > i / 2 ? 1 : 0.5} />);
  }

  const parsedValue = value ? value.toFixed(1) : 0;

  return (
    <Row
      className={css.rating}
      align="ml"
      gap="xs"
      dataTestId={generateDataTestId({ prefix: "rating", dataTestId })}
    >
      {value >= 0 ? (
        <>
          {!small && (
            <svg
              width="58"
              height="10"
              viewBox="0 0 58 10"
              xmlns="http://www.w3.org/2000/svg"
              fill="#FCD23C"
            >
              {paths}
            </svg>
          )}
          <span
            className={small ? css.topChartRating : undefined}
            style={{ color }}
          >
            {parsedValue}
          </span>
          {small && <Icon name="star-full" size={10} color={color} />}
        </>
      ) : (
        <Skeleton width={small ? 25 : "65%"} height={16} />
      )}
    </Row>
  );
};

export default Rating;
