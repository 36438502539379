import { generateDataTestId } from "modules/utils"
import { useEffect, useRef, useState } from "react"
import Skeleton from "./Skeleton"

interface VideoProps {
  src: string
  poster: string
  autoPlayThrushold?: number
  dataTestId?: string
}

export default function Video({
  src,
  autoPlayThrushold = 0.75,
  poster,
  dataTestId,
}: VideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [videoSrc, setVideoSrc] = useState<string>()
  const [posterSrc, setPosterSrc] = useState(poster)
  const [videoLoaded, setVideoLoaded] = useState(false)

  useEffect(() => {
    if (!videoRef?.current) return

    const videoEl: HTMLVideoElement = videoRef?.current

    const observer = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          if (videoSrc === undefined) {
            setVideoSrc(src)
            setPosterSrc(poster)
          } else if (videoEl.src && videoLoaded) {
            videoEl.muted = true
            videoEl.play()
          }
        } else {
          if (videoEl.src) videoEl.pause()
        }
      },
      { threshold: autoPlayThrushold }
    )

    observer.observe(videoEl)

    // 👇️ remove the event listener when component unmounts
    return () => {
      observer.unobserve(videoEl)
    }
  }, [videoRef, videoSrc, videoLoaded, posterSrc, src, poster])

  if (!src || !poster) {
    return (
      <Skeleton
        variant="rounded"
        height={250}
        width={512}
        dataTestId={generateDataTestId({
          prefix: "video-skeleton",
          dataTestId,
        })}
      />
    )
  }

  return (
    <video
      ref={videoRef}
      loop
      src={videoSrc}
      poster={posterSrc}
      onLoadedData={() => setVideoLoaded(true)}
      muted
      data-testid={generateDataTestId({ prefix: "video", dataTestId })}
    />
  )
}
