import _ from "lodash";
import { error, log } from "modules/logs";
import {
  AppInfo,
  GetArticleResponse,
  ITab,
  PlayableFeed,
  SearchResponse,
  TypeaheadResponse,
} from "types";
import * as Communication from "./communication/communication";
import {
  convertToIOSApps,
  getIOSDelivery as getIOSAppDelivery,
  getIOSAppInfo,
} from "./iosDemo";
import * as utils from "./utils";

let _apiHost: string;
function apiHost() {
  if (!_apiHost) {
    //used because Native is using localhost as hardcoded url
    //we can change it to "games-api.gems.io"
    if (utils.isLocal()) {
      _apiHost = "http://localhost:3020";
    } else if (utils.isDev()) {
      _apiHost = "http://games-api.gems.io";
    } else if (utils.isStaging() || utils.isTest()) {
      _apiHost = "https://games-api-gamestore-dev.isappcloud.com";
    } else if (utils.is2js()) {
      _apiHost = "https://games-api.games.j2s.co.il";
    } else if (utils.isProd()) {
      _apiHost = "https://games-api.isappcloud.com";
    } else if (process.env.REACT_APP_GAMES_API_HOST) {
      _apiHost = process.env.REACT_APP_GAMES_API_HOST;
    } else {
      throw new Error(`Cannot resolve API host for ${document.location.href}`);
    }
  }

  // Override for debugging
  if (localStorage.__gamesAPIUrl) {
    _apiHost = localStorage.__gamesAPIUrl;
  }

  return _apiHost;
}

export class APIError extends Error {
  httpStatusCode = 500;
  errorCode = 500;
  url = "";

  constructor(
    message: string,
    httpStatusCode: number,
    errorCode: number,
    url: string,
  ) {
    super(message);
    if (httpStatusCode) this.httpStatusCode = httpStatusCode;
    if (errorCode) this.errorCode = errorCode;
    if (url) this.url = url;
  }
}

const apiCache: {
  [key: string]: {
    createdAt: number;
    data: any;
  };
} = {};

async function get(
  path: string,
  params: any = {},
  cachePolicy?: { maxAge: number },
) {
  const queryParams = new URLSearchParams({
    ...params,
    cd: JSON.stringify(Communication.getClientDescriptor()),
    sessionId: _sessionId,
    debug: localStorage.__debugMode === "true",
  })
    .toString()
    .trim();

  return api({ path, queryParams, cachePolicy, method: "GET" });
}

async function post(path: string, body: any = {}) {
  return api({
    path,
    body,
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });
}

async function api({
  path,
  queryParams,
  body,
  headers,
  cachePolicy,
  method = "GET",
}: {
  path: string;
  queryParams?: any;
  body?: any;
  headers?: any;
  cachePolicy?: { maxAge: number };
  method: "GET" | "POST";
}) {
  const url = `${apiHost()}${path}${method === "GET" ? `?${queryParams}` : ""}`;

  if (apiCache[url] && cachePolicy) {
    if (apiCache[url].createdAt + cachePolicy.maxAge > Date.now()) {
      return apiCache[url].data;
    }
    delete apiCache[url].data;
  }

  let response;

  try {
    const fetchParams: any = {
      method,
    };
    if (body) {
      fetchParams.body = JSON.stringify(body);
    }
    if (queryParams) {
      fetchParams.queryParams = queryParams;
    }
    if (headers) {
      fetchParams.headers = headers;
    }

    response = await fetch(url, fetchParams);
  } catch (err) {
    error("There was an error", err);
    throw err;
  }

  if (!response?.ok) {
    error(`HTTP Response Code: ${response?.status}`);
    const errorCode = response?.status || 500;
    throw new APIError(
      `HTTP Response Code: ${response?.status}`,
      errorCode,
      errorCode,
      url,
    );
  }

  let json;
  try {
    json = await response.json();
  } catch (err) {
    if (err instanceof SyntaxError) {
      // Unexpected token < in JSON
      error("There was a SyntaxError", err);
    } else {
      error("JSON parse error:", err);
    }
    throw err;
  }

  if (cachePolicy && cachePolicy.maxAge) {
    log("api :: caching", url, json);
    apiCache[url] = {
      createdAt: Date.now(),
      data: json,
    };
  }

  return json;
}

const _sessionId = utils.uuid();
export function sessionId() {
  return _sessionId;
}

export function userId() {
  return (
    _.get(Communication.getClientDescriptor(), "uid") ||
    "000000-0000-0000-0000-000000000000"
  );
}

// first half from the user id, second half from the session id
// Primarly used to identify in logs if someone sends a screenshot or support ticket
export function versionCode() {
  return userId().substr(0, 18) + sessionId().substr(18, 36);
}

export async function getDiscoverTabs(): Promise<ITab[]> {
  return get(`/tabs/discover`, null, { maxAge: 3 * 60 * 60 * 1000 }); // 3 hour
}

export async function getTabFeed(tabId: number): Promise<any> {
  const maxAge = utils.isIOSWebview() ? 1 * 60 * 60 * 1000 : 0; // 1 hour
  const tabFeed = await get(`/tabs/${tabId}/feed`, null, { maxAge }); // 1 hour = 1 * 60 * 60 * 1000

  if (utils.isIOSWebview()) {
    tabFeed.collections = tabFeed.collections.map((collection: any) => {
      collection.placements = convertToIOSApps(collection.placements);
      return collection;
    });
  }

  return tabFeed;
}

export async function getRelatedFeed(appId: number): Promise<any> {
  return get(`/apps/${appId}/related`, null, {
    maxAge: 3 * 60 * 60 * 1000,
  }); // 3 hour
}

export async function whereIsMyApp(
  data: {
    packageName: string;
    collectionId: number;
  }[],
): Promise<any> {
  return post(`/whereIsMyApp`, data);
}

export async function getAppFeed(collectionId: number): Promise<any> {
  const appFeed = await get(`/collection/${collectionId}/feed`, null, {
    maxAge: 0,
  }); // 3 hour = 3 * 60 * 60 * 1000

  if (utils.isIOSWebview()) {
    appFeed.apps = convertToIOSApps(appFeed.apps);
  }

  return appFeed;
}

export async function getAppInfo(appId: number | string): Promise<AppInfo> {
  if (`${appId}` === "9999" || appId === "com.dcohenb.barbie") {
    return {
      version: "1.0.0",
      id: 9999,
      packageName: "com.dcohenb.barbie",
      minInstalls: 10000000,
      maxInstalls: "12446142",
      score: 5,
      ratings: 394703,
      free: true,
      available: true,
      offersIap: true,
      adSupported: false,
      released: new Date("2022-10-15T00:00:00"),
      updated: new Date("2023-08-25T07:46:19"),
      primaryCategory: "GAME_STRATEGY",
      otherCategories: [],
      tags: [],
      developer: {
        name: "Daniel",
        email: "dcohenb@gmail.com",
        address: "123 Main St",
        playstoreId: "dcohenb",
        website: "https://dcohenb.github.io",
      },
      isPartnerApp: true,
      locale: {
        appId: 9999,
        locale: "en-us",
        recentChanges: "nothing",
        privacyPolicy: "privacy_policy.html",
        title: "Sweet Sugar",
        description:
          "Buy packs, coins and more at special prices. Purchases instantly added to your game!",
        descriptionHtml: "empty",
        summary: "empty",
        contentRatingDescription: "noop",
        contentRating: "Teen",
        iapRange: "$0.99 - $99.99 per item",
        priceText: "Free",
        price: 0,
        currency: "USD",
        icon: "",
        headerImage: "",
        video: "",
        videoImage: "",
        videoPreview: "",
        screenshots: [],
        discountPercent: null,
        originalPrice: null,
        auraEvents: [],
      },
    };
  }
  const appInfo = await get(`/apps/${appId}`, null, { maxAge: 5 * 60 * 1000 }); // 3 hour = 3 * 60 * 60 * 1000

  // Mock App Delivery for iOS Demo
  if (utils.isIOSWebview()) {
    return getIOSAppInfo(appInfo);
  }

  return appInfo;
}

export async function search(query: string): Promise<SearchResponse> {
  query = query.trim().toLowerCase();
  const results = await get(`/search`, { query }, { maxAge: 15 * 60 * 1000 }); // 15 minutes

  if (utils.isIOSWebview()) {
    results.exactResults = convertToIOSApps(results.exactResults);
    results.results = convertToIOSApps(results.results);
  }

  return results;
}

export async function searchTypeahead(
  query: string,
): Promise<TypeaheadResponse> {
  query = query.trim().toLowerCase();
  return get(`/search/typeahead`, { query }, { maxAge: 15 * 60 * 1000 }); // 15 minutes
}

export async function getPlayablesFeed(): Promise<PlayableFeed[]> {
  const playables = await get(`/playables/feed`);

  return playables.map((playable: any) => {
    if (!utils.isIOSWebview()) {
      playable.video = playable.video.replace(".mp4", "-webm");
    }
    return playable;
  });
}

export async function delivery(appId: number): Promise<any> {
  // Mock App Delivery for iOS Demo
  if (utils.isIOSWebview()) return getIOSAppDelivery(appId);

  return get(`/apps/${appId}/delivery`);
}

export async function getArticle(
  articleId: number,
): Promise<GetArticleResponse> {
  return get(`/articles/${articleId}`);
}
