export const black0 = `rgba(0, 0, 0, 0.0)`
export const white0 = `rgba(255, 255, 255, 0.0)`
export const black10 = `rgba(0, 0, 0, 0.1)`
export const white10 = `rgba(255, 255, 255, 0.1)`
export const black20 = `rgba(0, 0, 0, 0.2)`
export const white20 = `rgba(255, 255, 255, 0.2)`
export const black30 = `rgba(0, 0, 0, 0.3)`
export const white30 = `rgba(255, 255, 255, 0.3)`
export const black40 = `rgba(0, 0, 0, 0.4)`
export const white40 = `rgba(255, 255, 255, 0.4)`
export const black50 = `rgba(0, 0, 0, 0.5)`
export const white50 = `rgba(255, 255, 255, 0.5)`
export const black60 = `rgba(0, 0, 0, 0.6)`
export const white60 = `rgba(255, 255, 255, 0.6)`
export const black70 = `rgba(0, 0, 0, 0.7)`
export const white70 = `rgba(255, 255, 255, 0.7)`
export const black80 = `rgba(0, 0, 0, 0.8)`
export const white80 = `rgba(255, 255, 255, 0.8)`
export const black90 = `rgba(0, 0, 0, 0.9)`
export const white90 = `rgba(255, 255, 255, 0.9)`
export const black100 = `rgba(0, 0, 0, 1.0)`
export const white100 = `rgba(255, 255, 255, 1.0)`
export const primary = `#644df0`
export const secondary = `#3a2f6d`
export const cardsBackground = `#2d2552`
export const backgroundDark = `#17132c`
export const backgroundLight = `#261f47`
export const textWhite = `#ffffff`
export const textLight = `#d5ceff`
export const textMedium = `#9b96bb`
export const textDark = `#8580a0`
export const pink = `#dc6488`
export const blue = `#3a84ef`
export const darkBlue = `#4c66f3`
export const purple = `#6054e6`
export const yellow = `#fcd23c`
export const bottomBarText = `#746ea0`
export const primaryGradient = `linear-gradient(180deg, #6877ff 0%,#5e4df0 100%)`
export const shadowCards = `#d9d9d9`
export const shadowSmallItems = `#d9d9d9`
export const specialGradient = `linear-gradient(180deg, #b499ff 0%,#7c99ff 48.4375%)`
export const backgroundRadialGradient = `radial-gradient(#292052 0%,#17132c 100%)`
export const backgroundMedium = `#1f193c`
export const bottomBarGradient = `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%,#17132c 44.27083432674408%,#17132c 100%)`
export const bottomBarLightGradient = `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%,#2d2552 44.27083432674408%,#2d2552 100%)`
export const overlayGradientGradient = `linear-gradient(180deg, rgba(23, 19, 44, 0.00) 0%, rgba(23, 19, 44, 0.90) 100%)`
export const chipShadow = `#d9d9d9`
export const positiveGreen = `#12a771`
export const negativeRed = `#fc6476`
export const shade = `#000000`
export const topBarGradient = `linear-gradient(180deg, #17132c 0%,#1f193c 82.8125%,rgba(0, 0, 0, 0) 100%)`
export const transparent = `#00000000`


const colors: Record<string, string> = {
  black0,
  white0,
  black10,
  white10,
  black20,
  white20,
  black30,
  white30,
  black40,
  white40,
  black50,
  white50,
  black60,
  white60,
  black70,
  white70,
  black80,
  white80,
  black90,
  white90,
  black100,
  white100,
  primary,
  secondary,
  cardsBackground,
  backgroundDark,
  backgroundLight,
  textWhite,
  textLight,
  textMedium,
  textDark,
  pink,
  blue,
  darkBlue,
  purple,
  yellow,
  bottomBarText,
  primaryGradient,
  shadowCards,
  shadowSmallItems,
  specialGradient,
  backgroundRadialGradient,
  backgroundMedium,
  bottomBarGradient,
  bottomBarLightGradient,
  overlayGradientGradient,
  chipShadow,
  positiveGreen,
  negativeRed,
  shade,
  topBarGradient,
  transparent,
}
export default colors