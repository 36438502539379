import _ from "lodash"
import * as utils from "modules/utils"
import strings from "./strings.json"

const lang = "en"

export const t = (key: string) => {
  if (utils.isDev() || utils.isLocal()) {
    // Show an error in development
    return _.get(strings[lang], key) || `t.${key}`
  } else {
    // Fallback to english in production
    return _.get(strings[lang], key) || _.get(strings.en, key)
  }
}

export default t
