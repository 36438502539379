import { Column } from "components/Layout"
import Loader from "components/Loader"
import ArticleBottomLine from "components/articles/ArticleBottomLine"
import ArticleGameFeatures from "components/articles/ArticleGameFeatures"
import ArticleQuickSummary from "components/articles/ArticleQuickSummary"
import { Subtitle } from "components/base/Typography"
import { ArticleContentType, Layout, } from "consts"
import * as gamesAPI from "modules/gamesAPI"
import { assetURL } from "modules/utils"
import { ReactNode, memo } from "react"
import { useAsync } from "react-use"
import { AppInfo, IArticle, RelatedApp, RelatedArticle } from "types"
import ArticleAppBlock from "./ArticleAppBlock"
import ArticleAppCard from "./ArticleAppCard"
import ArticleCollection from "./ArticleCollection"
import ArticleMarkdown from "./ArticleMarkdown"
import ArticleRelatedArticles from "./ArticleRelatedArticles"
import ArticleScreenshots from "./ArticleScreenshots"
import ArticleVideo from "./ArticleVideo"

function getAppFromContent(content?: string) {
  if (!content) return ""
  const matches = [...content.matchAll(/(app)\[([a-zA-Z0-9-_.]*)\]/g)].map(a => a[2]);
  return matches[0];
}

const componentsWithoutPadding = [ArticleContentType.SCREENSHOTS, ArticleContentType.APP_SCREENSHOTS, ArticleContentType.RELATED_GAMES, ArticleContentType.APP_CHART]

function ArticleContentWrapper({ children, hidePadding }: { children: ReactNode, hidePadding?: boolean }) {
  return hidePadding ? children : <Column padding="0 1.5rem">{children}</Column>
}

function AppComponent({ content, currentApp, render }: { content: string, currentApp: any, render: any }) {
  const rows = content.trim().split('\n') as string[]
  const packageName = getAppFromContent(rows.shift()?.trim().toLowerCase())
  const restOfContent = rows.join('\n').trim()

  if (!packageName) return <Subtitle size="s" sx={{ background: 'red' }}>App Not Defined. use "current" or app[package_name]</Subtitle>

  const { loading, error, value: loadedApp } = useAsync(async () => {
    if (packageName === "current") {
      return currentApp
    } else {
      return await gamesAPI.getAppInfo(packageName)
    }
  })

  if (loading) return <Loader dataTestId="page" />
  if (error) throw error
  if (!loadedApp) return <div>App Not Found</div>

  return render(restOfContent, loadedApp)
}

interface ArticleContentProps {
  article: IArticle
  app?: AppInfo
  relatedApps: RelatedApp[]
  relatedArticles: RelatedArticle[]
}

function ArticleContent({ article, app, relatedApps, relatedArticles }: ArticleContentProps) {
  const { content } = article
  return <ArticleMarkdown
    content={content}
    components={{
      code({ node, inline, className, children, ...props }) {
        const [, component] = /language-(\w+)/.exec(className || '') || []
        const content = children.toString().trim()
        const hidePadding = componentsWithoutPadding.includes(component as ArticleContentType)

        let contentComponent;
        if (component === ArticleContentType.QUICK_SUMMARY) {
          contentComponent = <ArticleQuickSummary content={content} relatedApps={relatedApps} article={article} />
        } else if (component === ArticleContentType.GAME_FEATURES) {
          contentComponent = <ArticleGameFeatures content={content} />
        } else if (component === ArticleContentType.BOTTOM_LINE) {
          contentComponent = <ArticleBottomLine content={content} />
        } else if (component === ArticleContentType.VIDEO) {
          contentComponent = <ArticleVideo content={content} />
        } else if (component === ArticleContentType.SCREENSHOTS) {
          contentComponent = <ArticleScreenshots content={content} />
        } else if (component === ArticleContentType.APP_CHART) {
          contentComponent = <ArticleCollection content={content} relatedApps={relatedApps} layout={Layout.CHART} />
        } else if (component === ArticleContentType.RELATED_GAMES) {
          contentComponent = <ArticleCollection content={content} relatedApps={relatedApps} title="Related Games" />
        } else if (component === ArticleContentType.RELATED_ARTICLES) {
          contentComponent = <ArticleRelatedArticles content={content} relatedArticles={relatedArticles} />
        } else if (component === ArticleContentType.APP_CARD) {
          contentComponent = <AppComponent content={content} currentApp={app} render={(content: string, app: any) => <ArticleAppCard content={content} app={app} article={article} />} />
        } else if (component === ArticleContentType.APP_SCREENSHOTS) {
          contentComponent = <AppComponent content={content} currentApp={app} render={(content: string, app: any) => {
            const screenshots = app.locale.screenshots.map((_: string, i: number) => {
              return assetURL(app.packageName, `screenshots/${i + 1}`)
            })
            return <ArticleScreenshots content={screenshots.join('\n')} app={app} />
          }} />
        } else if (component === ArticleContentType.APP_BLOCK) {
          contentComponent = <AppComponent content={content} currentApp={app} render={(content: string, app: any) => <ArticleAppBlock content={content} app={app} article={article} />} />
        } else if (component === ArticleContentType.APP_VIDEO) {
          contentComponent = <AppComponent content={content} currentApp={app} render={(content: string, app: any) => <ArticleVideo content={app.locale.video} />} />
        }
        else {
          contentComponent = <code {...props} className={className}>
            {children}
          </code>
        }

        return <ArticleContentWrapper hidePadding={hidePadding}>
          {contentComponent}
        </ArticleContentWrapper>


      }
    }}
  />
}

export default memo(ArticleContent)