import styled from '@emotion/styled';
import { tab } from '@testing-library/user-event/dist/tab';
import classNames from 'classnames';
import { PodiumType } from 'consts';
import useNavigateToAppInfo from 'hooks/useNavigateToAppInfo';
import { isEmpty, times } from 'lodash';
import { generateDataTestId } from 'modules/utils';
import { FC } from 'react';
import { IApp, ICollection } from 'types';
import AppIcon from './AppIcon';
import AppInstallBtn from './AppInstallBtn';
import { Subtitle } from './base/Typography';
import { Column, Row } from './Layout';
import css from "./PodiumChart.module.scss";
import Rating from "./Rating";

const podiumAppsSkeleton = times(3, () => ({} as IApp))

interface PodiumChartProps {
  apps: IApp[]
  collection?: ICollection
}

const podiumTypeToText = {
  [PodiumType.first]: '1st',
  [PodiumType.second]: '2nd',
  [PodiumType.third]: '3rd'
}

const podiumTypeToBackground = {
  [PodiumType.first]: '#6954F0',
  [PodiumType.second]: '#5971F5',
  [PodiumType.third]: '#477FD8'
}

interface Shape {
  type: PodiumType
}
interface Podium extends Shape {
  app: IApp
  collection?: ICollection
}

const AppTitle = styled(Subtitle)`
  text-align: center;
  width: 100% !important;
  line-height: 16px !important;
  margin-top: 0.7rem !important;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

function Trapezoid({ type }: Shape) {
  return <div className={classNames(css.trapezoidOuterMask, css[type])}>
    {type !== PodiumType.first &&
      <div className={classNames(css.trapezoidInnerMask, css[type])}>
        <div className={classNames(css.trapezoidContent, css[type])} />
      </div>
    }
  </div>
}

function Square({ type, app, collection }: Podium) {
  const hasApp = !isEmpty(app)
  return <div className={classNames(css.square, css[type])}>
    <div className={css.squareContent}>
      {hasApp && <Subtitle bold size="l">{podiumTypeToText[type]}</Subtitle>}
    </div>
    <Row align='mc' margin='0 0 md 0'>
      <AppInstallBtn
        appId={app.id}
        packageName={app.packageName}
        versionCode={app.versionCode}
        isPartnerApp={app.isPartnerApp}
        type={"small"}
        app={app}
        collection={collection}
        tab={tab}
        background={podiumTypeToBackground[type]}
        originalPrice={app?.originalPrice}
        discountPercent={app?.discountPercent}
        price={app?.price}
      />
    </Row>
  </div>
}

function Podium({ type, app, collection: collection }: Podium) {
  const navigateToAppInfo = useNavigateToAppInfo()

  return <Row style={{ height: '100%', width: 'calc(100% / 3)' }} data-testid={generateDataTestId({
    prefix: "podium",
    properties: [type, ...(app ? [] : ['skeleton'])]
  })}>
    <Column align="mr" style={{ width: '100%' }}>
      <Column margin="0 0 lg 0" padding="0 xs" align="mr" style={{ width: '100%' }}>
        <Column align='mc' onClick={() => navigateToAppInfo({
          isPartnerApp: app.isPartnerApp,
          appId: app.id,
          appTitle: app.title,
          campaign: app.campaign,
        })}>
          <AppIcon
            packageName={app.packageName}
            size={50}
            borderRadius={12}
            dataTestId={generateDataTestId({
              prefix: "app-card",
              properties: ["icon", app.packageName],
            })}
          />
          <AppTitle size="s">{app.title}</AppTitle>
          <Rating
            value={app.score}
            small
            dataTestId={generateDataTestId({
              prefix: "podium",
              properties: [app.packageName],
            })}
          />
        </Column>
      </Column>
      <Column align='mr' style={{ width: '100%' }}>
        <Trapezoid type={type} />
        <Square type={type} app={app} collection={collection} />
      </Column>
    </Column>
  </Row>
}

const PodiumChart: FC<PodiumChartProps> = ({ apps, collection }) => {
  const podiumApps = apps.length ? apps : podiumAppsSkeleton
  return <Row
    align="bc"
    padding="0 2.75rem"
    style={{
      backgroundImage: `url("/img/rank_bars.svg")`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 100%',
      height: 300,
      position: 'relative',
      width: '100%',
    }}
    data-testid={generateDataTestId({ prefix: "podium-chart" })}
  >
    <Podium type={PodiumType.second} app={podiumApps[0]} collection={collection} />
    <Podium type={PodiumType.first} app={podiumApps[1]} collection={collection} />
    <Podium type={PodiumType.third} app={podiumApps[2]} collection={collection} />
  </Row >
}

export default PodiumChart