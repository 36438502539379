import ArticleCollection from "components/articles/ArticleCollection";
import ArticleRelatedArticles from "components/articles/ArticleRelatedArticles";
import { assetURL } from "modules/utils";
import { AppInfo } from "types";

interface RelatedContentProps {
  app: AppInfo
}

export default function RelatedContent({ app }: RelatedContentProps) {

  const relatedApps = app.relatedApps?.map(({ packageName, ...rest }) => ({
    ...rest,
    icon: assetURL(packageName, "icon"),
    packageName
  }))

  return <>
    {!!app?.relatedArticles?.length && <ArticleRelatedArticles relatedArticles={app?.relatedArticles} titleSize="m" title="Articles" />}
    {!!relatedApps?.length && <ArticleCollection relatedApps={relatedApps} title="Related Games" />}
  </>
}