import styled from "@emotion/styled"
import { GlobalStateContext } from "GlobalState"
import { Row } from "components/Layout"
import PageHeader from "components/PageHeader"
import Tab from "components/Tab"
import PageLoader from "containers/PageLoader"
import { AnimatePresence, motion } from "framer-motion"
import useReportPageEvents from "hooks/useReportPageEvents"
import { times } from "lodash"
import * as gamesAPI from "modules/gamesAPI"
import { generateDataTestId } from "modules/utils"
import { createRef, useContext, useEffect, useState } from "react"
import { useAsync } from "react-use"
import { ITab } from "types"

const LoadWrapper = styled.div`
  padding-bottom: 2rem;
  flex: 1;
`

const skeletonTabs = times(4, i => ({})) as ITab[]

export function DiscoverPage() {
  const { tabId, setTabId } = useContext(GlobalStateContext)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [shouldAnimate, setShouldAnimate] = useState(false)

  useReportPageEvents()

  const {
    value: tabs,
    loading,
    error,
  } = useAsync(async () => {
    const tabs = await gamesAPI.getDiscoverTabs()

    if (!tabId) {
      setTabId(tabs[0].id)
    }

    return tabs.map(tab => ({
      ...tab,
      lottieRef: createRef(),
    }))
  }, [tabId])

  useEffect(() => {
    if (!tabId || !tabs) return

    setTimeout(() => {
      tabs.forEach(({ id, lottieRef }) => {
        // @ts-ignore
        const lottie = lottieRef?.current?.state?.instance
        if (!lottie) return

        if (id === tabId) {
          if (isFirstRender) {
            lottie.goToAndStop(lottie.totalFrames - 1, true)
            setIsFirstRender(false)
          } else {
            lottie.goToAndPlay(20, true)
          }
        } else {
          lottie.goToAndStop(0, true)
        }
      })
    }, 1)
  }, [tabId, tabs])

  if (error) throw error

  const motionAnimationProps = shouldAnimate
    ? {
      initial: { opacity: 0, transform: "translateX(1rem)" },
      animate: { opacity: 1, transform: "translateX(0rem)" },
      exit: { opacity: 0, transform: "translateX(-1rem)" },
    }
    : {}

  const tabsToRender = tabs || skeletonTabs

  return (
    <div className="bottom-overlay">
      <PageHeader title="GameSaver" settingsMenu background="large">
        <Row
          align="msb"
          padding="sm xxl xl"
          dataTestId={generateDataTestId({ prefix: "tabs-wrapper" })}
        >
          {tabsToRender.map((tab, i) => (
            <Tab
              key={i}
              {...tab}
              selected={tabId === tab.id}
              onClick={() => setTabId(tab.id)}
            />
          ))}
        </Row>
      </PageHeader>

      <AnimatePresence mode="wait">
        <motion.div
          key={tabId}
          {...motionAnimationProps}
          transition={{ duration: 0.25 }}
          style={{
            flex: 1,
            minHeight: 0,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <LoadWrapper>
            <PageLoader
              tabId={tabId}
              collectionLayouts={tabs?.find(({ id }) => id === tabId)?.collectionLayouts}
              setShouldAnimate={setShouldAnimate}
            />
          </LoadWrapper>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}
