import CTABtn from "components/CTABtn";
import Icon from "components/Icon";
import { Column, Row } from "components/Layout";
import Select from "components/Select";
import { Body, Subtitle } from "components/base/Typography";
import PrivacyManagementModal from "components/modals/PrivacyManagementModal";
import * as EmailValidator from 'email-validator';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import colors from "styles/colors";
import * as Communication from "../../modules/communication/communication";
import classes from "./PrivacyManagementPage.module.scss";

const selectOptions = [
  { label: 'Access my personal data', value: 'get_data', icon: 'info' },
  { label: 'Delete my personal data', value: 'delete', icon: 'trash' }
]

export default function PrivacyMangementPage() {
  const [requestSelected, setRequestSelected] = useState<string>("")
  const [email, setEmail] = useState("")
  const [shouldShowEmailError, setShouldShowEmailError] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate()

  const shouldDisableSubmit = () => {
    return !requestSelected || !email
  }

  async function submitPersonalDataDeletion() {
    if (!EmailValidator.validate(email)) {
      return setShouldShowEmailError(true)
    }

    setShouldShowEmailError(false)

    const cd = Communication.getClientDescriptor()
    cd.ab = cd.ac // why - https://unity.slack.com/archives/C0632EJJ4D7/p1699885312899029?thread_ts=1699432998.623059&cid=C0632EJJ4D7
    const { auid, gaid } = cd

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        request_type: requestSelected,
        identifiers: {
          uuid: auid,
          gaid,
          cd
        }
      })
    };

    const response = await fetch('https://gdpr.isappcloud.com', requestOptions)
    setShowModal(true)
  }

  return <>
    <PrivacyManagementModal modalOpen={showModal} email={email} type={requestSelected} />
    <Column style={{ height: "100%" }}>
      <Row align="ml" padding="1rem 1.25rem" gap="1rem">
        <Icon name="arrow-left" onClick={() => navigate(-1)} />
        <Subtitle size="l">Privacy management</Subtitle>
      </Row>
      <Column align="sb" padding="24px" style={{ height: "100%" }}>
        <Column gap={36} >
          <Column gap={6}>
            <Subtitle sx={{ color: colors.textLight }} variant="h5">
              Get a copy of your personal data or delete your GamePortal data at anytime.
            </Subtitle>
          </Column >

          <Column gap={24}>
            <Select
              options={selectOptions}
              onChange={setRequestSelected}
              selectedValue={requestSelected}
            />
            <Column gap={8}>
              <input
                type="text"
                autoFocus
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Add your email"
                className={classes.formInput}
                data-testid="search-input-email"
              />
              {shouldShowEmailError && <Subtitle size="s" style={{ color: colors.negativeRed }}>
                Please enter a valid email address
              </Subtitle>
              }
            </Column>
          </Column>

          <Body size="s" sx={{ color: colors.textLight }} lineHeight="22px">
            By submitting your request, you hereby declare that you are the legal owner of this device, and the email address stated above. Your request is subject to our review, and will be processed only if we find it eligible for processing under the terms of the General Data Protection Regulation.
          </Body>
        </Column>
        <CTABtn
          type="large"
          onClick={submitPersonalDataDeletion}
          isDisabled={shouldDisableSubmit()}
        >
          Submit request
        </CTABtn>
      </Column>
    </Column>
  </>
}