import CardPrice from "components/CardPrice"
import _ from "lodash"
import { useRef } from "react"
import { Link } from "react-router-dom"
import colors from "styles/colors"
import texts from "styles/texts"
import { AppTag } from "types"
import AppIcon from "../AppIcon"
import { Column, Row } from "../Layout"
import Text, { Subtitle } from "../base/Typography"
import AppInfoChips from "./AppInfoChips"
import css from "./AppInfoHeader.module.scss"

interface AppInfoHeaderProps {
  packageName?: string
  name?: string
  developer?: {
    name?: string
    website?: string
  }
  offersIAP?: boolean
  primaryCategory?: string
  appOtherCategories?: string[]
  tags?: AppTag[]
  hideChips?: boolean
  discountPercent?: number | null
  price?: number | null
  originalPrice?: number | null
}

export default function AppInfoHeader({
  packageName = "",
  name = "",
  developer = {},
  offersIAP = false,
  primaryCategory = "",
  appOtherCategories = [],
  tags = [],
  hideChips = false,
  discountPercent = null,
  price = null,
  originalPrice = null,
}: AppInfoHeaderProps) {
  const headerInfoContainerRef = useRef<HTMLDivElement>(null)
  const isSkeleton = !name

  const chips = _.chain([
    primaryCategory.replace("GAME_", ""),
    ...appOtherCategories.map(c => c.replace("GAME_", "")),
    ...tags.map(t => t.label),
  ])
    .map(label => label.toLowerCase())
    .uniq()
    .value()

  return (
    <Row
      className={css.appInfoHeader}
      align={`${hideChips ? "t" : "m"}c`}
      gap={hideChips ? 14 : "md"}
    >
      <AppIcon packageName={packageName} size={(hideChips && !discountPercent) ? 50 : 66} />
      <Column
        align={!hideChips ? "sb" : undefined}
        col={1}
        gap={hideChips ? "4px" : undefined}
        style={{
          minWidth: 0,
          ...(!hideChips && { height: 66 }),
        }}
        ref={headerInfoContainerRef}
      >
        <Subtitle size="l" className="ellipsis">
          {name}
        </Subtitle>
        {!hideChips && (
          <AppInfoChips
            chips={!isSkeleton ? chips : undefined}
            maxWidth={headerInfoContainerRef.current?.offsetWidth}
          />
        )}
        {discountPercent && <Row><CardPrice
          originalPrice={originalPrice}
          price={price}
          discountPercent={discountPercent} />
        </Row>}
        <Row
          align="ml"
          gap="xs"
          style={{
            font: texts.caption,
            fontSize: hideChips ? "0.875rem" : "0.75em",
          }}
        >
          {(developer.name || isSkeleton) && (
            <Link
              to={`${developer.website}`}
              style={{
                textDecoration: "none",
                ...(isSkeleton && { width: "100%" }),
              }}
            >
              <Subtitle variant="h6" size="s"
                className="ellipsis"
                sx={{ color: colors.textLight, font: "inherit" }}
                skeletonWidth="30%"
              >
                {!isSkeleton && `By ${developer.name}`}
              </Subtitle>
            </Link>
          )}
          {offersIAP && (
            <Text
              sx={{
                color: colors.textDark,
                font: "inherit",
                whiteSpace: "nowrap",
              }}
            >
              In-app purchases
            </Text>
          )}
        </Row>
      </Column>
    </Row>
  )
}
