import { GlobalStateContext } from "GlobalState"
import classNames from "classnames"
import AppIcon from "components/AppIcon"
import AppInstallBtn from "components/AppInstallBtn"
import Img from "components/Img"
import { Column, Row } from "components/Layout"
import Rating from "components/Rating"
import Video from "components/Video"
import AppCardDebug from "components/debugging/AppCardDebug"
import { CardType } from "consts"
import useNavigateToAppInfo from "hooks/useNavigateToAppInfo"
import useScrollIntoView from "hooks/useScrollIntoView"
import { log } from "modules/logs"
import { reportConversion } from "modules/reporting"
import { assetURL } from "modules/utils"
import { useContext, useEffect, useRef, useState } from "react"
import colors from "styles/colors"
import { t } from "translations"
import { IApp, IArticle, IAuraEvent, ICampaign, ICollection, IEmbeddedVideo, ITab } from "types"
import * as utils from "../modules/utils"
import css from "./AppCard.module.scss"
import CardPrice from "./CardPrice"
import EmbeddedVideo from "./EmbededVideo"
import { Caption, Subtitle } from "./base/Typography"

interface AppCardProps {
  id: number
  packageName: string
  title: string
  versionCode?: number
  score: number
  isPartnerApp?: boolean
  cardType?: CardType
  iconSize?: number
  app?: IApp
  collection?: ICollection
  tab?: ITab
  article?: IArticle
  event?: IAuraEvent,
  embeddedVideo?: IEmbeddedVideo,
  originalPrice?: number | null,
  price?: number | null,
  discountPercent?: number | null,
  hideReadMore?: boolean,
}

export default function AppCard({
  id,
  packageName,
  title,
  versionCode,
  score,
  isPartnerApp = false,
  originalPrice = null,
  price = null,
  discountPercent = 0,
  // Look and feel
  cardType = CardType.DEFAULT,
  iconSize = 48,
  embeddedVideo,
  hideReadMore = false,

  // for reporting
  app,
  collection,
  tab,
  article,
  event,
}: AppCardProps) {
  const { wasTrackingImpressionFired, markImpressionFired } =
    useContext(GlobalStateContext)
  const appCardRef = useRef<HTMLDivElement>(null)
  const inView = useScrollIntoView(appCardRef)
  const [reportedInView, setReportedInView] = useState(false)
  const isDataLoaded = !!id
  const navigateToAppInfo = useNavigateToAppInfo()

  useEffect(() => {
    if (!isDataLoaded || !inView || reportedInView) return

    setReportedInView(true)

    reportConversion(packageName, "impression", {
      app,
      campaign: app?.campaign,
      tab,
      collection,
      article,
      eventCard: event,
      embeddedVideo
    })

    if (app?.campaign) {
      fireImpressionTracking(app.id, app.campaign)
    }
  }, [isDataLoaded, inView, reportedInView])

  async function fireImpressionTracking(appId: number, campaign: ICampaign) {
    if (wasTrackingImpressionFired(appId.toString())) {
      return
    }

    log("firing impression tracking", campaign.impressionUrl)
    try {
      const result = await fetch(campaign.impressionUrl, { mode: "no-cors" })
      const resultText = await result.text()
      log("impression tracking fired", resultText)
      markImpressionFired(appId.toString())
    } catch (error) {
      log("error firing impression tracking", error)
    }
  }

  function navigateToAppInfoPage() {
    if (!isDataLoaded) return
    navigateToAppInfo({
      isPartnerApp,
      appId: id,
      appTitle: title,
      campaign: app?.campaign,
    })
  }

  function renderCaption() {
    if (!discountPercent
      && !hideReadMore
      && cardType !== CardType.LARGE)
      return <Caption
        sx={{ color: colors.textMedium }}
        data-testid={utils.generateDataTestId({
          prefix: "app-card",
          properties: ["read more", packageName],
        })}
        underline
        skeletonWidth="25%"
      >
        {isDataLoaded && t("read_more")}
      </Caption>
  }

  return (
    <div ref={appCardRef} className={classNames(css.appCard, (embeddedVideo || app?.hasVideo) && css.videoCard)}>
      {/* Embedded video */}
      {embeddedVideo !== undefined && (
        <div
          onClick={navigateToAppInfoPage}
          className={classNames(
            css.video,
            !isDataLoaded && css.skeleton,
            !packageName && css.skeleton,
          )}
          sf="mc"
          data-testid={utils.generateDataTestId({
            prefix: "app-card",
            properties: ["link", packageName],
          })}
        >
          <EmbeddedVideo
            embeddedVideo={embeddedVideo}
            app={app}
            dataTestId={utils.generateDataTestId({
              prefix: "app-card",
              dataTestId: packageName,
            })}
          />
        </div>
      )}
      {/* App video */}
      {cardType === CardType.LARGE && embeddedVideo === undefined && !!app?.hasVideo && (
        <div
          onClick={navigateToAppInfoPage}
          className={classNames(
            css.video,
            !isDataLoaded && css.skeleton,
            !packageName && css.skeleton
          )}
          sf="mc"
          data-testid={utils.generateDataTestId({
            prefix: "app-card",
            properties: ["link", packageName],
          })}
        >
          <Video
            src={assetURL(packageName, "videoPreview")}
            poster={assetURL(packageName, "videoImage")}
            dataTestId={utils.generateDataTestId({
              prefix: "app-card",
              dataTestId: packageName,
            })}
          />
        </div>
      )}
      {/* Banner fallback */}
      {cardType === CardType.LARGE && (embeddedVideo === undefined) && (!!app?.hasVideo === false) && (
        <div
          onClick={navigateToAppInfoPage}
          className={classNames(css.banner, !isDataLoaded && css.skeleton)}
          margin="bottom-md"
        >
          <Img
            src={assetURL(packageName, "headerImage")}
            fallbackImg={packageName ? "/img/app_banner_fallback.svg" : undefined}
            alt={title}
            dataTestId={utils.generateDataTestId({
              prefix: "app-banner",
              dataTestId: packageName,
            })}
            threshold={0}
          />
        </div>
      )}
      <Row className={css.infoGroup} align="ml">
        <div
          className={css.info}
          onClick={navigateToAppInfoPage}
          sf="ml"
          col="1"
        >
          {(
            <AppIcon
              packageName={packageName}
              size={iconSize}
              dataTestId={utils.generateDataTestId({
                prefix: "app-card",
                properties: ["icon", packageName],
              })}
            />
          )}
          <Column className={css.titleGroup} col={1} gap="xxs">
            <Subtitle
              className={classNames(css.appName, "ellipsis")}
              size="s"
              data-testid={utils.generateDataTestId({
                prefix: "app-card",
                properties: ["app name", packageName],
              })}
              skeletonWidth="100%"
            >
              {title}
            </Subtitle>
            <Rating
              value={score}
              dataTestId={utils.generateDataTestId({
                prefix: "app-card",
                properties: [packageName],
              })}
            />

            {(
              !!discountPercent &&
              <CardPrice
                originalPrice={originalPrice}
                price={price}
                discountPercent={discountPercent} />
            )}

            {(
              renderCaption()
            )}
          </Column>
        </div>

        <AppInstallBtn
          appId={id}
          packageName={packageName}
          versionCode={versionCode}
          isPartnerApp={isPartnerApp}
          type={"small"}
          app={app}
          collection={collection}
          tab={tab}
          originalPrice={app?.originalPrice}
          discountPercent={app?.discountPercent}
          price={app?.price}
        />

        {localStorage.__debugMode === "true" && <AppCardDebug app={app} />}
      </Row>
    </div>
  )
}
