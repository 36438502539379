import { GlobalStateContext } from "GlobalState"
import CTABtn from "components/CTABtn"
import { Column, Row } from "components/Layout"
import { useContext } from "react"
import Modal from "react-modal"

interface UnknownErrorModalProps {
  error: string
}

export default function UnknownErrorModal({ error }: UnknownErrorModalProps) {
  const { closeModal } = useContext(GlobalStateContext)

  return <Modal
    isOpen={true}
    shouldCloseOnOverlayClick={false}
    style={{ content: { textAlign: "center" } }}
  >
    <Row align="mc" style={{ height: '100%' }} padding="20px 10px">
      <Column align="mc" style={{ textAlign: 'center', maxWidth: 260 }} gap="1rem">
        <h3>Unknown Error Occured</h3>
        {error}
        <Row>
          <CTABtn type="large" onClick={closeModal}>Close</CTABtn>
        </Row>
      </Column>
    </Row>
  </Modal>
}