import * as utils from "./utils"

const fonts = [
  {
    name: "Open Sans",
    weight: 400,
    url: "/fonts/open-sans-latin-400-normal.woff2",
  },
  {
    name: "Open Sans",
    weight: 400,
    url: "/fonts/open-sans-latin-400-normal.woff",
  },
  {
    name: "Open Sans",
    weight: 600,
    url: "/fonts/open-sans-latin-600-normal.woff2",
  },
  {
    name: "Open Sans",
    weight: 600,
    url: "/fonts/open-sans-latin-600-normal.woff",
  },
  {
    name: "Open Sans",
    weight: 700,
    url: "/fonts/open-sans-latin-700-normal.woff2",
  },
  {
    name: "Open Sans",
    weight: 700,
    url: "/fonts/open-sans-latin-700-normal.woff",
  },
]

const images = [
  "/img/logo.svg",
  "/img/app_banner_fallback.svg",
  "/img/app_icon_fallback.svg",
  // "/img/error_connection.png",
  // "/img/error_game.png",
  "/img/error_general.png",
  // "/img/error_no_connection.png",
  // "/img/error_playable.png",
  "/img/error_storage.png",
  // "/img/error_unknown.png",
  "/img/install_permissions.png",
  "/img/no_search_results.png",
  "/img/play_icon.png",
  // "/img/promoted_badge.png",
  "/img/settings_bg.png",
  "/img/rank_bars.svg",
  "/img/bottom_line.png",
]

const lottie = [
  "/lottie/controller_icon.json",
  "/lottie/discovery_icon.json",
  "/lottie/hearts_icon.json",
  "/lottie/search_icon.json",
  "/lottie/stars_icon.json",
  "/lottie/sword_icon.json",
  "/lottie/trophy_icon.json",
]

async function preloadFonts() {
  return Promise.all(
    fonts
      .filter(({ url }) => (utils.isTest() ? !url.endsWith("woff2") : true))
      .map(async ({ name, weight, url }) => {
        const font = new FontFace(name, `url(${url})`, {
          weight: weight.toString(),
        })
        await font.load()
        document.fonts.add(font)
      })
  )
}

async function preloadLottieAnimations() {
  console.log("hi from preloadLottieAnimations");
  return Promise.all(
    lottie.map(async url => {
      const lottieData = await fetch(url).then(res => res.json())
      utils.setLottieCache(url, lottieData)
    })
  )
}

async function preloadImages() {

  return Promise.all(
    images.map(async url => {
      return new Promise<void>(resolve => {
        const img = new Image()
        img.onload = () => resolve()
        img.onerror = () => {
          console.log("error loading image, url: ", url);
          resolve();
        }
        img.src = url
      })
    })
  )
}

async function preloadIcons() {
  const svg = await fetch("/icons.svg").then(res => res.text())
  document.head.insertAdjacentHTML("beforeend", svg)
}

export default async function preloadAssets() {
  console.log("hi from preloadAssets");
  return Promise.all([
    preloadFonts(),
    preloadLottieAnimations(),
    preloadImages(),
    preloadIcons(),
  ])
}
