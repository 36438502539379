import { Paper, Tab, Tabs } from "@mui/material"
import AppIcon from "components/AppIcon";
import Img from "components/Img";
import { Column, Row } from "components/Layout";
import * as gamesAPI from "modules/gamesAPI";
import {
  Badge,
  createOrUpdatePlayer,
  getIapItems, Group,
  PackageContent,
  Player,
  StoreItem
} from "modules/paymentsApi";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useAsync, useEffectOnce, useList } from "react-use";
import CTABtn from "../components/CTABtn"
import * as _ from "lodash";
import css from "./AppIAPStorePage.module.scss";
import offerModalCss from "../components/modals/OfferModal.module.scss";
import Text, { Subtitle, Title } from "../components/base/Typography"
import React, { SyntheticEvent, useEffect, useState } from "react";
import Modal from "react-modal"
import colors from "../styles/colors"
import BackBtn from "../components/BackBtn"
import { closeWindow, openStore } from "../modules/nativeAPI";
import classNames from "classnames"
import * as Communication from "../modules/communication/communication";

const headerImage = "https://cloudinary-res-games.isappcloud.com/image/upload/v1/aura-games/game-saver/sweetcandy/sweet_candy_cover.png";
const vipHeaderImage = "https://cloudinary-res-games.isappcloud.com/image/upload/v1/aura-games/game-saver/sweetcandy/sweet_sugar_cover_vip.png";
const appIcon = "https://cloudinary-res-games.isappcloud.com/image/upload/v1/aura-games/game-saver/sweetcandy/sweet_candy_appicon.png";
const vipStoreDescription = "Premium value for premium customers!\nPurchases instantly added to your game.";
export default function AppIAPStorePage() {
  const [debugClickSequence, { push, reset }] = useList([]);
  const [showDebugPanel, setShowDebugPanel] = useState(false)
  useEffect(() => {
      // Double tap to open in dev
      setShowDebugPanel(debugClickSequence.length >= 3)
  }, [debugClickSequence])

  const navigate = useNavigate();
  const location = useLocation();
  const isOnGame = location.pathname.includes('on_game')

  const { appId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const isVipStore = queryParams.get("vipStore") === "true";
  useEffectOnce(() => {
    sessionStorage.removeItem("purchaseSucceeded");
    reset()
  });
  // Load app info from API
  const { loading, error, value } = useAsync(async () => {
    
    const app = await gamesAPI.getAppInfo(appId as string);

    const items: StoreItem[] = await getIapItems(9999, isVipStore);

    let player: Player | undefined = undefined;

    function getPlayerId(): string | undefined {
      const dynamicUserIdFromQuery = queryParams.get("playerId") || undefined;
      if (dynamicUserIdFromQuery) {
        localStorage.setItem(`game${appId}playerId`, dynamicUserIdFromQuery);
        return dynamicUserIdFromQuery;
      }
      const storedDynamicUserId = localStorage.getItem(`game${appId}playerId`);
      if (storedDynamicUserId) {
        return storedDynamicUserId;
      }
    }

    const playerId = getPlayerId()
    if (playerId) {
      player = await createOrUpdatePlayer(
        app.id,
        playerId,
        Communication.getClientDescriptor().gaid,
      );
    }
    const iapItemIdToOpen =
      Number(queryParams.get("iapItemIdToOpen")) || undefined;
    if (iapItemIdToOpen) {
      const iapItem = items.find(
        (item: StoreItem) => item.id === iapItemIdToOpen,
      );
      if (iapItem) {
        const props: IapItemPrePurchaseModalProps = {
          appId: appId as string,
          item: iapItem,
          isFullScreen: isOnGame,
          player: player,
        };
        navigate(location.pathname + location.search, {
          state: { iapOfferModalOpen: true, props },
        });
      }
    }

    return { app, items, player };
  });

  const app = value?.app;
  const items = value?.items;
  const player = value?.player;

  function onCardClicked(item: StoreItem){
    const props : IapItemPrePurchaseModalProps = {
      appId: appId as string,
      item: item,
      isFullScreen : isOnGame,
      player: player
    }
    return navigate(location.pathname + location.search, { state: { iapOfferModalOpen: true, props } })
  }

  const [currentTab, setTab] = useState(0)
  const handleTabChange = (_e: SyntheticEvent, val: number) => setTab(val)
  const tabs = [
    {
      title: "All Deals",
      filter: (item: StoreItem) => {
        return item.group !== "LIMITED";
      },
    },
    {
      title: "Coins",
      filter: (item: StoreItem) => {
        return item.group === "COINS_PACK";
      },
    },
    {
      title: "Packs",
      filter: (item: StoreItem) => {
        return item.group === "PACK";
      },
    },
    {
      title: "Chests",
      filter: (item: StoreItem) => {
        return item.group === "CHEST";
      },
    },
  ]

  function onBackButtonClicked() {
    closeWindow()
  }

  return (
    <div className={css.appIapPage}>
      <Row
        className={css.coverPhoto}
        style={{
          backgroundImage: `url('${
            isVipStore ? vipHeaderImage : headerImage
          }')`,
        }}
      ></Row>

      {isOnGame ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "156px",
            width: "100%",
            background:
              "var(--gradients-cover-overlay, linear-gradient(180deg, rgba(0, 0, 0, 0.75) 30.38%, rgba(0, 0, 0, 0.56) 49.43%, rgba(0, 0, 0, 0.30) 64.5%, rgba(0, 0, 0, 0.00) 78.43%))",
          }}
        ></div>
      ) : (
        <></>
      )}
      {!isOnGame ? (
        <></>
      ) : (
        <>
          <Row
            gap={12}
            style={{
              position: "fixed",
              top: "28px",
              left: "28px",
              background: colors.transparent,
            }}
          >
            <img
              style={{
                background: colors.transparent,
                height: "40px",
                width: "40px",
              }}
              src={"/img/barbie_app_icon.png"}
            ></img>
            <Column>
              <Subtitle size={"l"} bold>
                GameSaver
              </Subtitle>
              <Text style={{ fontSize: "14px" }}>
                Get higher-value offers here!
              </Text>
            </Column>
          </Row>

          <img
            style={{
              position: "fixed",
              top: "28px",
              right: "28px",
              background: colors.transparent,
            }}
            src={"/img/ic_exit.svg"}
            onClick={onBackButtonClicked}
          ></img>
        </>
      )}

      <Column col={1} gap={0} className={css.appIapContentRoot}>
        <Paper
          elevation={5}
          className={css.appIapIcon}
          onClick={() => {
            push("logo" as never);
          }}
        >
          <AppIcon externalUrl={appIcon} size={64} />
        </Paper>
        <Column align="mc" gap={0} style={{ marginTop: "12px" }}>
          <Row align={"mc"}>
            <Title size={"s"}>
              {app?.locale.title ? `${app?.locale.title} Shop` : undefined}
            </Title>
            {isVipStore ? (
              <img
                src={"/img/vip_shop_img.png"}
                style={{
                  height: "18px",
                  width: "35px",
                  marginLeft: "8px",
                  background: colors.transparent,
                }}
              />
            ) : (
              <></>
            )}
          </Row>

          {!isOnGame ? (
            <Subtitle
              size={"m"}
              className={css.appIapSubtitle}
              style={{ color: colors.textLight, marginTop: "6px" }}
            >
              {isVipStore ? vipStoreDescription : app?.locale.description}
            </Subtitle>
          ) : (
            <Row align={"c"} style={{ marginTop: "8px" }}>
              <Subtitle
                size={"m"}
                className={css.appIapSubtitle}
                style={{ color: colors.textLight }}
                onClick={() => {
                  const path = location.pathname
                    .replace("/on_game", "")
                    .substring(1);
                  openStore(path);
                }}
              >
                {loading ? undefined : "Open in app"}
              </Subtitle>
              <img
                src={"/img/ic_open_in_new.svg"}
                style={{
                  marginLeft: "4px",
                  background: colors.transparent,
                }}
              />
            </Row>
          )}
        </Column>

        {error ? (
          <div>Error: {error.message}</div>
        ) : (
          <>
            <Column
              align="h"
              col={1}
              gap={16}
              style={{ width: "100%", marginTop: "24px" }}
            >
              <Subtitle size={"m"}>
                {loading ? undefined : "Limited-time offers"}
              </Subtitle>
              {items
                ? items
                    ?.filter((item: StoreItem) => {
                      return item.group === "LIMITED";
                    })
                    .map((item: StoreItem) => {
                      return (
                        <IApItemCardLarge
                          item={item}
                          onCardClicked={onCardClicked}
                          appId={appId!}
                          key={`iap-limited-${item.id}`}
                          player={player}
                        ></IApItemCardLarge>
                      );
                    })
                : [
                    <IApItemCardLarge
                      item={undefined}
                      onCardClicked={onCardClicked}
                      appId={appId!}
                      key={`iap-limited-0}`}
                      player={player}
                    ></IApItemCardLarge>,
                    <IApItemCardLarge
                      item={undefined}
                      onCardClicked={onCardClicked}
                      appId={appId!}
                      key={`iap-limited-1`}
                      player={player}
                    ></IApItemCardLarge>,
                  ]}

              <Tabs
                value={currentTab}
                onChange={handleTabChange}
                className={css.appIapTabs}
                style={{ marginTop: "10px", marginBottom: "10px" }}
                variant={"fullWidth"}
              >
                {tabs.map((collection: any, i: number) => (
                  <Tab key={i} label={collection.title} value={i} />
                ))}
              </Tabs>

              {_.chunk(items?.filter(tabs[currentTab].filter), 2).map(
                (row: StoreItem[], index) => (
                  <Row
                    key={`iap-row-${index}`}
                    align="strecthl"
                    gap={16}
                    col={1}
                    style={{
                      width: row.length == 2 ? "100%" : "calc(50% - 7px)",
                    }}
                  >
                    {row.map((item: StoreItem) =>
                      IApItemCardSmall(item, onCardClicked, appId!, player),
                    )}
                  </Row>
                ),
              )}
            </Column>
          </>
        )}
        {showDebugPanel && <div>Player Data: {JSON.stringify(player)}</div>}
      </Column>
    </div>
  );
}

function IApItemCardSmall(
  item: StoreItem,
  onCardClicked: (item: StoreItem) => void,
  appId: string, 
  player?: Player,
) {
  const badgeImg = toBadgeImage(item.badgeText);
  return (
    <Paper className={css.appIapItemSmall} key={item.id}>
      <Column align="mc" style={{ height: "100%" }}>
        <div
          style={{ position: "relative", width: "100%" }}
          onClick={() => onCardClicked(item)}
        >
          {badgeImg && (
            <img
              src={badgeImg}
              style={{
                background: colors.transparent,
                position: "absolute",
                justifyContent: "center",
                width: "80px",
                height: "80px",
                objectFit: "cover",
              }}
            ></img>
          )}
          <Img
            src={item.imageSmall}
            fallbackImg="/img/app_icon_fallback.svg"
            alt="app_icon"
            className={css.appIapItemSmallImage}
            threshold={0}
          />
        </div>

        <Column align="msb" col={1} style={{ padding: "14px" }}>
          <Subtitle
            bold={true}
            style={{ textAlign: "center" }}
            onClick={() => onCardClicked(item)}
          >
            {item.name}
          </Subtitle>
          <Subtitle
            onClick={() => onCardClicked(item)}
            size={"s"}
            style={{
              color: colors.textLight,
              textAlign: "center",
              alignItems: "center",
              paddingBottom: "14px",
            }}
          >
            {item.description}
          </Subtitle>

          <BuyButton appId={appId} item={item} player={player}></BuyButton>
        </Column>
      </Column>
    </Paper>
  );
}

function toBadgeImage(badgeText: Badge) {
  switch (badgeText) {
    case "10_percent_value":
      return "/img/badge_10_percent_value.png"
    case "15_percent_value":
      return "/img/badge_15_percent_value.png"
    case "20_percent_value":
      return "/img/badge_20_percent_value.png"
    case "daily_deal":
      return "/img/badge_daily_deal.png"
    case "weekly_deal":
      return "/img/badge_weekly_deal.png"
    case "great_value":
      return "/img/badge_great_value.png"
    default:
      return undefined
  }
}

function LargeImage({
  item,
  showTag = true,
  onClick,
  mainImgStyle = undefined,
  rootDivStyle = undefined,
}: {
  item?: StoreItem;
  showTag?: boolean;
  onClick?: () => void;
  mainImgStyle?: React.CSSProperties | undefined;
  rootDivStyle?: React.CSSProperties | undefined;
}) {
  const badgeImg = toBadgeImage(item?.badgeText);
  const tagText = item?.tagText;
  return (
    <div className={css.largeBanner} style={rootDivStyle}>
      {badgeImg && (
        <img
          src={badgeImg}
          style={{
            background: colors.transparent,
            position: "absolute",
            justifyContent: "center",
            width: "80px",
            height: "80px",
            objectFit: "cover",
          }}
        ></img>
      )}
      {showTag && tagText && (
        <Row
          gap={2}
          style={{
            position: "absolute",
            right: "14px",
            top: "14px",
            borderRadius: "5px",
            backgroundColor: "black",
            background: "#0000004D",
            padding: "4px 6px 4px 4px",
          }}
        >
          <img
            src={"/img/ic_clock.svg"}
            style={{
              background: colors.transparent,
            }}
          ></img>
          <Subtitle size={"s"}>{tagText}</Subtitle>
        </Row>
      )}
      <Img
        threshold={0}
        src={item?.imageLarge}
        fallbackImg="/img/app_icon_fallback.svg"
        alt="app_icon"
        onClick={onClick}
        style={{
          width: "100%",
          height: "100%",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
          objectFit: "cover",
          transition: "none",
          ...mainImgStyle,
        }}
      />
    </div>
  );
}

export function IApItemCardLarge({
  item,
  onCardClicked,
  buyBtnDisabled,
  showTag = true,
  appId,
  player,
}: {
  item?: StoreItem;
  onCardClicked: (item: StoreItem) => void;
  buyBtnDisabled?: boolean;
  showTag?: boolean;
  appId: string;
  player?: Player;
}) {
  return (
    <Paper className={css.appIapItemLarge} key={item?.id}>
      <Column align="mc">
        <LargeImage
          item={item}
          showTag={showTag}
          onClick={() => item && onCardClicked(item)}
        />
        <Row align="msb" className={css.iapCardLargeItemContent}>
          <Column
            align="sb"
            col={1}
            className="description"
            onClick={() => item && onCardClicked(item)}
          >
            <Subtitle size={"l"} bold={true}>
              {item?.name}
            </Subtitle>
            <Subtitle size={"s"} style={{ color: colors.textLight }}>
              {item?.description}
            </Subtitle>
          </Column>
          <BuyButton
            appId={appId}
            item={item}
            isDisabled={buyBtnDisabled ?? false}
            player={player}
          />
        </Row>
      </Column>
    </Paper>
  );
}
export function OfferDetailsDialog({ item, appId, player }: { item: StoreItem; appId: string, player?: Player }) {
  return (
    <Column
      align="mc"
      style={{
        textAlign: "center",
        backgroundColor: colors.cardsBackground,
        borderRadius: "12px",
        width: "100%", height: "100%"
      }}
    >
      <Column style={{ width: "100%", height: "100%" }}>
        <LargeImage item={item}  />

        <Column
          align="tl"
          col={1}
          className={css.appIapItemModalContent}
          style={{ padding: "24px" }}
        >
          <Title size={"s"} bold={true}>
            {item.name}
          </Title>

          <Subtitle size={"s"} style={{ marginTop: "8px" }}>
            package contains:
          </Subtitle>
          {item.packageContents?.map((content: PackageContent) => {
            return (
              <Row key={content.name} gap={12} style={{ marginTop: "16px" }}>
                <AppIcon externalUrl={content.image} size={40} />
                <Column align={"tl"}>
                  <Subtitle
                    size={"m"}
                  >{`${content.count}x ${content.name}`}</Subtitle>
                  <Subtitle size={"s"} style={{ color: colors.textMedium }}>
                    {content.description}
                  </Subtitle>
                </Column>
              </Row>
            );
          })}
          <BuyButton
            appId={appId}
            item={item}
            style={{ width: "100%", marginTop: "24px" }}
            type={"large"}
            wide
            player={player}
          />
        </Column>
      </Column>
    </Column>
  );
}

export function OfferDetailsFullScreen({
  item,
  appId,
  onBackButtonClicked,
  player,
}: {
  item: StoreItem;
  appId: string;
  onBackButtonClicked?: () => void;
  player?: Player;
}) {
  function onExitButtonClicked() {
    closeWindow();
  }

  return (
    <Column
      align="mc"
      style={{
        textAlign: "center",
        backgroundColor: colors.cardsBackground,
        borderRadius: "12px",
        padding: "16px",
        width: "100%",
        height: "100%",
      }}
    >
      <Column style={{ width: "100%", height: "100%" }} gap={16}>
        <Row align={"sb"} style={{ width: "100%" }}>
          <BackBtn
            style={{ boxShadow: "none" }}
            onClick={onBackButtonClicked}
            backgroundColor={colors.transparent}
          ></BackBtn>
          <img
            style={{
              background: colors.transparent,
            }}
            src={"/img/ic_exit.svg"}
            onClick={onExitButtonClicked}
          ></img>
        </Row>
        <LargeImage
          item={item}
          mainImgStyle={{
            borderRadius: "12px",
          }}
          rootDivStyle={{
            borderRadius: "12px",
          }}
        />

        <Column align="tl" col={1} className={css.appIapItemModalContent}>
          <Title size={"s"} bold={true}>
            {item.name}
          </Title>

          <Subtitle size={"s"} style={{ marginTop: "8px" }}>
            package contains:
          </Subtitle>
          {item.packageContents?.map((content: PackageContent) => {
            return (
              <Row key={content.name} gap={12} style={{ marginTop: "16px" }}>
                <AppIcon externalUrl={content.image} size={40} />
                <Column align={"tl"}>
                  <Subtitle
                    size={"m"}
                  >{`${content.count}x ${content.name}`}</Subtitle>
                  <Subtitle size={"s"} style={{ color: colors.textMedium }}>
                    {content.description}
                  </Subtitle>
                </Column>
              </Row>
            );
          })}
          <BuyButton
            appId={appId}
            item={item}
            style={{ width: "100%", marginTop: "auto" }}
            type={"large"}
            wide
            player={player}
          />
        </Column>
      </Column>
    </Column>
  );
}

interface IapItemPrePurchaseModalProps {
  appId: string;
  item: StoreItem;
  isFullScreen?: boolean;
  player?: Player;
}

export function IapItemOfferDetailsModal() {
  const { state } = useLocation()
  const {
    iapOfferModalOpen,
    props,
  }: { iapOfferModalOpen?: boolean; props?: IapItemPrePurchaseModalProps } =
    state || {};
  const navigate = useNavigate()
  const closeModal = () => navigate(-1)

  const className = {
    base: props?.isFullScreen
      ? classNames(offerModalCss.content, offerModalCss.fullScreen)
      : offerModalCss.content,
    afterOpen: "",
    beforeClose: "",
  };
  return (
    <Modal
      isOpen={iapOfferModalOpen ?? false}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
      className={className}
    >
      {props ? (
        props?.isFullScreen ? (
          <OfferDetailsFullScreen item={props.item} appId={props.appId} player={props.player} />
        ) : (
          <OfferDetailsDialog item={props.item} appId={props.appId} player={props.player} />
        )
      ) : (
        <></>
      )}
    </Modal>
  );
}


function BuyButton({
  appId,
  item,
  style,
  type,
  wide,
  isDisabled = false,
  player,
}: {
  appId: string;
  item?: StoreItem;
  style?: React.CSSProperties;
  type?: "large" | "small";
  wide?: boolean;
  isDisabled?: boolean;
  player?: Player;
}) {
  const isOnGame = useLocation().pathname.includes('on_game')

  const navigate = useNavigate()

  const onPaymentCTAClicked = async () => {
    if (!item) {
      return;
    }

    if (!player) {
      const playerId = prompt("Please enter your player id");
      if (!playerId) {
        console.log('failed to get player');
        return;
      }
      localStorage.setItem(`game${appId}playerId`, playerId);

      player = await createOrUpdatePlayer(
        9999,
        playerId,
        Communication.getClientDescriptor().gaid,
      );
      if (!player) {
        console.log('failed to create player');
        return;
      }
    }

    navigate(
      `/app/9999/store/checkout/${player.id}/${item.id}${
        isOnGame ? "/on_game" : "" 
      }${location.search}`,
    );
  };
  
  return (
    <CTABtn
      style={style}
      type={type}
      wide={wide}
      onClick={onPaymentCTAClicked}
      isDisabled={isDisabled}
    >
      {item ? `$${item.price}` : undefined}
    </CTABtn>
  );
}
