import { debounce } from "@mui/material"
import Collection from "components/Collection"
import Icon from "components/Icon"
import { Column, Row } from "components/Layout"
import PageHeader from "components/PageHeader"
import { CardType } from "consts"
import useReportPageEvents from "hooks/useReportPageEvents"
import _, { times } from "lodash"
import * as gamesAPI from "modules/gamesAPI"
import { reportEvent } from "modules/reporting"
import { generateDataTestId } from "modules/utils"
import { FormEvent, useEffect, useMemo, useRef, useState } from "react"
import colors from "styles/colors"
import texts from "styles/texts"
import { IApp, SearchResponse, TypeaheadResponse } from "types"

function isSearchValueEmpty(searchValue?: string) {
  return !searchValue?.trim()
}

type SearchResults = SearchResponse & { isSkeleton?: boolean }

const searchResultsSkeleton: SearchResults = {
  isSkeleton: true,
  query: "",
  results: times(4, () => ({} as IApp)),
  exactResults: [],
}

export function SearchPage() {
  const [inputValue, setInputValue] = useState("")
  const [typeaheadResults, setTypeaheadResults] = useState<string[] | null>(null)
  const [searchResults, setSearchResults] = useState<SearchResults>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null);
  const [hasScrolled, setHasScrolled] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const scrollElementRef = useRef<HTMLDivElement>(null);
  useReportPageEvents();

  const poularSearches = [
    "Candy Crush Saga",
    "Animal Crossing",
    "Multiplayer",
    "GardenScapes",
    "Roblox",
    "MineCraft",
  ]

  const fetchTypeahead = useMemo(() => {
    return debounce(async (query: string, callback: (typeaheadResponse: TypeaheadResponse) => void) => {
      callback(await gamesAPI.searchTypeahead(query))
    }, 300)
  }, [])

  useEffect(() => {
    // don't search until 3 characters have been entered
    if (inputValue.length < 3 || isSearchValueEmpty(inputValue)) {
      setTypeaheadResults(null)
      setSearchResults(undefined)
      return
    }

    fetchTypeahead(inputValue, ({ results }) => {
      setTypeaheadResults(results)
    })
  }, [fetchTypeahead, inputValue]);

  useEffect(() => {
    if ((searchResults?.results.length || searchResults?.exactResults.length) && !searchResults.isSkeleton) {
      reportSearchEvent("search results shown", inputValue);
    } else if (searchResults &&
      !searchResults?.results.length &&
      !searchResults?.exactResults.length &&
      !searchResults?.isSkeleton &&
      inputValue) {
      reportSearchEvent("search no results shown", inputValue);
    }
  }, [inputValue, searchResults]);

  useEffect(() => {
    function handleScroll() {
      if (!hasScrolled) {
        reportSearchEvent('search results scrolled', inputValue);
        setHasScrolled(true);
      }
    }
    if (inputValue) {
      scrollElementRef.current?.addEventListener('scroll', handleScroll);
    }
    return () => {
      scrollElementRef.current?.removeEventListener('scroll', handleScroll);
    }
  }, [inputValue, hasScrolled]);

  function reportSearchEvent(action: string, event_extra_data: string) {
    reportEvent("search", action, { event_extra_data });
  }

  function clearAll() {
    setInputValue("");
    setInputValue("");
    setTypeaheadResults(null);
    setSearchResults(undefined);
    setHasScrolled(false);
    inputRef.current?.focus();
  }

  function selectTypeaheadResult(result: string) {
    setInputValue(result)
    searchQuery(result)
  }

  function submitSearch(e: FormEvent) {
    e.preventDefault()
    if (isSearchValueEmpty(inputValue)) {
      return
    }
    inputRef.current?.blur()
    searchQuery(inputValue);
    reportSearchEvent("search query sent", inputValue);
  }

  function searchQuery(query: string) {
    setError(null)
    setLoading(true)
    setSearchResults(searchResultsSkeleton)
    setTypeaheadResults(null)

    gamesAPI
      .search(query)
      .then(res => {
        setSearchResults(res)
        setLoading(false)
      })
      .catch(e => {
        setError(e)
        setLoading(false)
      })
  }

  function shouldRenderSearchResults() {
    return (
      searchResults &&
      (searchResults.isSkeleton ||
        searchResults.query === inputValue.trim().toLowerCase() // Render results if search result are made by input value
      )
    )
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(e.target.value);
    setHasScrolled(false);
  }

  let body;

  if (error) {
    body = (
      <Column sf="mc" style={{ height: "100%" }} gap="1rem">
        <img
          src="/img/no_search_results.png"
          alt="No results found"
          width={100}
          height={100}
        />
        <h3>Oops, something went wrong.</h3>
      </Column>
    )
  } else if (shouldRenderSearchResults()) {
    if (
      searchResults?.exactResults?.length ||
      searchResults?.results?.length
    ) {
      body = (
        <Column padding="0 0 2rem">
          {!_.isEmpty(searchResults.exactResults) && (
            <div onClick={() => reportSearchEvent("search exact result clicked", inputValue)}>
              <Collection
                id={1}
                placements={searchResults.exactResults}
                hideReadMore={false}
                cardType={CardType.LARGE}
                style={{ marginBottom: "0.666rem" }}
              />
            </div>
          )}
          {!_.isEmpty(searchResults.results) && (
            <div onClick={() => reportSearchEvent("search result clicked", inputValue)}>
              <Collection id={2} placements={searchResults.results} cardType={CardType.DEFAULT} hideReadMore={false} />
            </div>
          )}
        </Column>
      );

    } else {
      body = (
        <Column sf="mc" style={{ height: "100%" }} gap="1rem">
          <img
            src="/img/no_search_results.png"
            alt="No results found"
            width={100}
            height={100}
          />
          <h3>No results found</h3>
        </Column>
      )
    }
  } else if (inputValue && typeaheadResults?.length) {
    body = (
      <Column padding="0.5rem 2.5rem" gap="1rem">
        {typeaheadResults.map((result, i) => (
          <h5 key={i} onClick={() => {
            selectTypeaheadResult(result);
            reportSearchEvent("popular search clicked", result);
          }}>
            {result}
          </h5>
        ))}
      </Column>
    );
  } else {
    body = (
      <Column padding="md 1.5rem">
        <h4>Popular Searches</h4>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(50%, 1fr))",
            padding: "0.5rem 0",
          }}
        >
          {poularSearches.map(term => (
            <h5
              key={term}
              style={{ padding: "0.5rem 0", color: colors.textMedium }}
              onClick={() => {
                selectTypeaheadResult(term);
                reportSearchEvent("popular search clicked", term);
              }}
              data-testid={generateDataTestId({
                prefix: "popular-search",
                properties: [term],
              })}
            >
              {term}
            </h5>
          ))}
        </div>
      </Column>
    )
  }

  return (
    <div className="search_page" ref={scrollElementRef} style={{ overflowY: 'scroll' }}>
      <PageHeader title="Search" settingsMenu background="large" backBtn>
        <Row padding="1rem 1.5rem 0.5rem 1.5rem" gap="0.5rem">
          <form
            onSubmit={submitSearch}
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              background: colors.cardsBackground,
              padding: "0 1rem",
              height: 44,
              borderRadius: 22,
              gap: "0.5rem",
            }}
          >
            <input
              ref={inputRef}
              type="text"
              autoFocus
              value={inputValue}
              onChange={handleInputChange}
              style={{
                flex: 1,
                background: "none",
                border: "none",
                outline: "none",
                color: colors.textWhite,
                font: texts.subtitleM,
              }}
              data-testid="search-input"
            />

            {inputValue && !loading && (
              <Icon name="close" size={14} onClick={clearAll} />
            )}
          </form>
        </Row>
      </PageHeader>

      <div style={{ flex: 1 }}>{body}</div>
    </div>
  )
}
