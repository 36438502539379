import styled from "@emotion/styled"
import { generateDataTestId } from "modules/utils"
import colors from "styles/colors"
import Icon from "./Icon"
import { Column, spacings } from "./Layout"

const ICON_SIZE = 12
export const CLOSE_BUTTON_WIDTH = `${ICON_SIZE}px + ${spacings.xs} * 2`

interface CloseButtonProps {
  onClose: () => void
  dataTestId?: string
}

const CircleBackground = styled(Column)`
  border-radius: 50%;
  background-color: ${colors.secondary};
  width: calc(${CLOSE_BUTTON_WIDTH});
`

export default function CloseButton({ onClose, dataTestId }: CloseButtonProps) {
  return <CircleBackground
    align="tl"
    padding="xs"
    onClick={onClose}
    dataTestId={generateDataTestId({ prefix: 'close-button', dataTestId })}
  >

    <Icon name="close" size={ICON_SIZE} />
  </CircleBackground>
}
