import AppCard from "components/AppCard"
import { IArticle } from "types"

interface ArticleAppCardProps {
  content: string
  app: any
  article: IArticle // for reporting
}

export default function ArticleAppCard({ app, article }: ArticleAppCardProps) {
  return <AppCard
    id={app.id}
    packageName={app.packageName}
    title={app.locale.title}
    score={app.score}

    // Delivery
    versionCode={app.versionCode}
    isPartnerApp={app.isPartnerApp}

    app={app}
    article={article}
  />
}
