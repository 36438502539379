import Collection from "components/Collection"
import PageHeader from "components/PageHeader"
import { CardType, Layout } from "consts"
import { times } from "lodash"
import * as gamesAPI from "modules/gamesAPI"
import { FC, useEffect } from "react"
import { useAsync } from "react-use"
import PageDefaultLayout from "../components/PageDefaultLayout"
import PageTabsLayout from "../components/PageTabsLayout"

interface PageLoaderProps {
  tabId?: number
  appId?: number
  collectionId?: number
  setShouldAnimate?: (shouldAnimate: boolean) => void
  collectionLayouts?: Layout[]
}

const getAppsSkeleton = (apps: number) => times(apps, () => ({}))

function generateSkeletonCollections(isChartLayout: boolean) {
  return isChartLayout ? [{
    layout: Layout.CHART,
    cardType: CardType.DEFAULT,
    placements: getAppsSkeleton(17)
  }] :
    [{
      layout: Layout.HORIZONTAL,
      cardType: CardType.DEFAULT,
      placements: getAppsSkeleton(4),
    },
    {
      layout: Layout.VERTICAL,
      cardType: CardType.DEFAULT,
      placements: getAppsSkeleton(4)
    },
    {
      layout: Layout.HORIZONTAL,
      cardType: CardType.DEFAULT,
      placements: getAppsSkeleton(4),
    },
    {
      layout: Layout.VERTICAL,
      cardType: CardType.DEFAULT,
      placements: getAppsSkeleton(4),
    }]
}


const PageLoader: FC<PageLoaderProps> = ({
  tabId,
  appId,
  collectionId,
  setShouldAnimate,
  collectionLayouts
}) => {
  const {
    loading,
    error,
    value,
  } = useAsync(() => {
    if (appId) return gamesAPI.getRelatedFeed(appId)
    if (collectionId) return gamesAPI.getAppFeed(collectionId)
    if (tabId) return gamesAPI.getTabFeed(tabId)
    return Promise.resolve()
  }, [tabId, appId, collectionId])

  useEffect(() => {
    value && setShouldAnimate?.(true)
  }, [value])

  if (error) throw error
  const isValueFetched = !value && !loading && !!(tabId || appId || collectionId)
  if (isValueFetched) {
    if (collectionId) {
      throw new Error("Unknown App Feed Requested")
    }
    return <div>No Feed Content</div>
  }

  if (collectionId) {
    return (
      <>
        <PageHeader
          title={value?.title}
          titleSize="small"
          backBtn
          settingsMenu
        />
        <div col={1} padding="top-sm bottom-xxl">
          <Collection key={value?.id} id={value?.id} placements={value?.placements || getAppsSkeleton(17)} debug={value?.debug?.[collectionId]} loading={loading} />
        </div>
      </>
    )
  }

  const isChartLayout = !!collectionLayouts?.includes(Layout.CHART)

  const { tabLayout, collections = generateSkeletonCollections(isChartLayout) } = value || {}

  if (tabLayout === "tabs")
    return <PageTabsLayout tab={value} collections={collections} loading={loading} />
  return <PageDefaultLayout tab={value} collections={collections} debug={value?.debug} loading={loading} />
}

export default PageLoader
