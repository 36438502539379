import * as utils from "./utils";

export type Badge =
  | "10_percent_value"
  | "15_percent_value"
  | "20_percent_value"
  | "daily_deal"
  | "weekly_deal"
  | "great_value"
  | undefined;

export type Group = "LIMITED" | "PACK" | "COINS_PACK" | "CHEST";
export interface StoreItem {
  id: number;
  name: string;
  description: string;
  price: number;
  imageSmall: string;
  imageLarge: string;
  packageContents?: PackageContent[];
  group: Group;
  badgeText: Badge;
  tagText?: string;
}

export interface Purchase {
  paymentSucceededAt: string;
}
export interface Player {
  id: number;
  playerId: string;
  gaid: string;
}

export interface PackageContent {
  image: string;
  count: number;
  name: string;
  description: string;
}

let _apiHost: string;
export function paymentsApiHost() {
  if (!_apiHost) {
    //used because Native is using localhost as hardcoded url
    //we c1an change it to "games-api.gems.io"
    if (utils.isLocal()) {
      _apiHost = "http://localhost:3070";
    } else if (utils.isStaging() || utils.isTest()) {
      _apiHost = "https://games-payments-gamestore-dev.isappcloud.com";
    } else if (utils.isDev()) {
      _apiHost = "http://payments.gems.io";
    } else if (
      /aura-gamestore-oc-games.isappcloud.com/gi.test(
        document.location.hostname,
      )
    ) {
      _apiHost = "https://games-payments-oc-games.isappcloud.com";
    } else if (
      /aura-gamestore-oc-barbie.isappcloud.com/gi.test(
        document.location.hostname,
      )
    ) {
      _apiHost = "https://games-payments-oc-barbie.isappcloud.com";
    } else if (utils.isProd()) {
      _apiHost = "https://games-payments.isappcloud.com";
    } else if (utils.is2js()) {
      _apiHost = "https://payments.games.j2s.co.il";
    } else {
      throw new Error(`Cannot resolve API host for ${document.location.href}`);
    }
  }

  return _apiHost;
}

export async function createPaymentIntent(
  iapItemId: number,
  playerUid: number,
) {
  return await fetch(`${paymentsApiHost()}/api/v1/create-payment-intent`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      playerUid,
      iapItemId,
    }),
  })
    .then(res => res.json())
    .then(function (data: any) {
      return {
        clientSecret: (data.clientSecret as string) || undefined,
        purchaseGuid: (data.purchaseGuid as string) || undefined,
      };
    });
}

export async function getIapItems(
  appId: number,
  isVipStore: boolean,
): Promise<StoreItem[]> {
  return await fetch(
    `${paymentsApiHost()}/api/v1/apps/${appId}/iap-items?isVipStore=${isVipStore}`,
  ).then(res => res.json());
}

export async function getPurchase(purchaseGuid: string) {
  return await fetch(
    `${paymentsApiHost()}/api/v1/purchases/${purchaseGuid}`,
  ).then(res => res.json());
}

export async function getPlayer(playerUid: number): Promise<Player> {
  return await fetch(`${paymentsApiHost()}/api/v1/player/${playerUid}`).then(
    res => (res.ok ? res.json() : undefined),
  );
}

export async function createOrUpdatePlayer(
  appId: number,
  playerId: string,
  gaid: string,
): Promise<Player> {
  return await fetch(`${paymentsApiHost()}/api/v1/player`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      appId,
      playerId,
      gaid,
    }),
  }).then(res => res.json());
}
