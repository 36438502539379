import { GlobalStateContext } from "GlobalState"
import CTABtn from "components/CTABtn"
import { Column, Row } from "components/Layout"
import { useContext } from "react"
import Modal from "react-modal"
import colors from "styles/colors"
import * as utils from "../../modules/utils"

interface InsufficientStorageModalProps {
  size?: number
}

export default function InsufficientStorageModal({ size }: InsufficientStorageModalProps) {
  const { closeModal } = useContext(GlobalStateContext)

  return (
    <Modal
      isOpen={true}
      shouldCloseOnOverlayClick={false}
      style={{ content: { textAlign: "center" } }}
    >
      <Row align="mc" style={{ height: "100%" }} padding="20px 10px">
        <Column
          align="mc"
          style={{ textAlign: "center", maxWidth: 260 }}
          gap="1rem"
        >
          <img
            src="/img/error_storage.png"
            width={180}
            height={180}
            alt="error occured"
          />
          <h3>Not enough storage</h3>
          {size ? (
            <h4 style={{ color: colors.textLight, marginBottom: "5px" }}>
              Please free up your storage and try again (
              {utils.humanFileSize(size)} is required)
            </h4>
          ) : (
            <h4 style={{ color: colors.textLight, marginBottom: "5px" }}>
              Please free up your storage and try again!
            </h4>
          )}
          <Row>
            <CTABtn type="large" onClick={closeModal}>
              Ok, got it!
            </CTABtn>
          </Row>
        </Column>
      </Row>
    </Modal>
  )
}
