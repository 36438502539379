import AuraEventCard from "components/AuraEventCard";
import { Column } from "components/Layout";
import { useEffect, useRef, useState } from "react";
import { AppInfo } from "types";
import { Subtitle } from "../base/Typography";
import css from "./AppInfoEvents.module.scss";
interface AppInfoEventsProps {
  app: AppInfo
}

const AppInfoEvents = ({ app }: AppInfoEventsProps) => {

  const { id, packageName, primaryCategory, score, ratings, isPartnerApp } = app;
  const { auraEvents, title } = app.locale;

  const collectionScrollRef = useRef<HTMLDivElement>(null)
  const [scrollMileStones, setScrollMileStones] = useState<Record<number, boolean>>({})

  useEffect(() => {
    const el = collectionScrollRef?.current
    if (!el) return

    const updatePosition = (e: Event) => {
      const scrollPcent =
        el.scrollLeft / (el.scrollWidth - el.getBoundingClientRect().width)
      const normalized = Math.floor(Math.round(scrollPcent * 100) / 25)

      if (normalized > 0 && !scrollMileStones[normalized]) {
        setScrollMileStones({ ...scrollMileStones, [normalized]: true })
      }
    }

    el.addEventListener("scroll", updatePosition)

    // 👇️ remove the event listener when component unmounts
    return () => {
      el.removeEventListener("scroll", updatePosition)
    }
  }, [collectionScrollRef, scrollMileStones])

  return <Column gap="14px" padding={'0 1.5rem'}>
    <Subtitle size={"m"}>Events and offers</Subtitle>
    <div
      ref={collectionScrollRef}
      className={css.horizontal}
      style={{ display: 'flex' }}
    >
      {auraEvents.map((event, index) =>
        <AuraEventCard
          key={index}
          auraEvent={{
            ...event,
            app: { id, title, packageName, primaryCategory, score, ratings: ratings.toString(), isPartnerApp }
          }}
          isCard={false}
        />
      )}
    </div>

  </Column>
}

export default AppInfoEvents;