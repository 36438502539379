import { GlobalStateContext } from "GlobalState"
import { useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { ICampaign } from "types"

export default function useNavigateToAppInfo() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { setCampaignForApp, clearCampaignForApp } =
    useContext(GlobalStateContext)

  return ({
    isPartnerApp,
    appId,
    appTitle,
    campaign,
  }: {
    isPartnerApp?: boolean
    appId?: number
    appTitle?: string
    campaign?: ICampaign
  }) => {
    if (!appId) return

    if (!isPartnerApp) {
      return navigate(pathname, { state: { modalOpen: true, appId } })
    }

    if (!campaign) {
      clearCampaignForApp(appId)
    } else {
      setCampaignForApp(appId, campaign)
    }

    navigate(`/app/${appId}`, { state: { title: appTitle } })
  }
}
