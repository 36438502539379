import CTABtn from "components/CTABtn"
import { Column, Row } from "components/Layout"
import { useEffect } from "react"
import Modal from "react-modal"
import colors from 'styles/colors'

interface InstallPermissionsModalProps {
  onConfirm: () => void
  onReject: () => void
}

export default function InstallPermissionsModal({ onConfirm, onReject }: InstallPermissionsModalProps) {
  useEffect(() => {
    function handlePopState() {
      window.history.forward()
      onReject()
    }
    window.addEventListener("popstate", handlePopState)

    return () => {
      window.removeEventListener("popstate", handlePopState)
    }
  }, [])

  return <Modal isOpen={true} shouldCloseOnOverlayClick={false} style={{ content: { padding: 0 } }}>
    <Row align="mc" style={{ height: '100%' }} padding="2.5rem 2rem">
      <Column align="mc" style={{ textAlign: 'center', maxWidth: 260 }}>
        <img src="/img/install_permissions.png" width={180} height={180} alt="" />
        <Column margin="xl 0 lg 0" gap="0.5rem">
          <h3>Permission Needed</h3>
          <h4 style={{ color: colors.textLight }}>To install games, turn on “Allow apps from this source” in your settings</h4>
        </Column>
        <Row gap="1rem" style={{ width: '100%' }}>
          <CTABtn type="large" onClick={onReject} style={{ background: colors.secondary, flexGrow: 1 }}>Later</CTABtn>
          <CTABtn type="large" onClick={onConfirm}>Settings</CTABtn>
        </Row>
      </Column>
    </Row>
  </Modal>
}