/* eslint-disable max-len */
import styled from "@emotion/styled";
import Icon from "components/Icon";
import { Column, Row } from "components/Layout";
import { Title } from "components/base/Typography";
import useReportPageEvents from "hooks/useReportPageEvents";
import { useNavigate } from "react-router-dom";
import colors from "styles/colors";

const TermsOfUseWrapper = styled.div`
  flex: 1;
  width: 100%;
  background: ${colors.white100};
  padding: 1rem;
  font-size: 14px;
  color: ${colors.black80};
  overflow-y: scroll;
`;

export function TermsOfServicePage() {
  const navigate = useNavigate();
  useReportPageEvents();

  return (
    <Column style={{ height: "100%" }}>
      <Row align="ml" padding="3rem 1rem 1.5rem 1rem" gap="1rem">
        <Icon name="arrow-left" onClick={() => navigate(-1)} />
        <Title variant="h3" size="s">
          Terms & Conditions
        </Title>
      </Row>

      {/* prettier-ignore */}
      <TermsOfUseWrapper>
        <b>GAMEPORTAL - TERMS OF USE</b>
        <p>Last updated: June 11, 2023</p>
        <p>This service is operated by ironSource Ltd. from 121 Menachem Begin Road St, Tel Aviv, Israel, company number 514643626 (“<b>ironSource</b>”, “<b>us</b>” or “<b>our</b>” or “<b>we</b>” or “<b>Company</b>”). These Terms of Use (“Terms”) is the legal agreement between you (either an individual or an entity) and ironSource for your use of our recommendation services.</p>
        <p>BY USING OUR SERVICE, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS AND THAT YOU AGREE TO BE BOUND BY ITS TERMS AND CONDITIONS.</p>
        <p><b>IF YOU DO NOT AGREE TO THESE TERMS, DO NOT USE THE SERVICE IN ANY MANNER WHATSOEVER.</b></p>
        <p>This service enables you to access and download applications. As part of the service, we might send you smart notifications updating you on installation processes for applications you choose to install (“Service).</p>
        <ol>
          <li><b>GRANT OF LICENSE.</b> We hereby grant you a personal, non-exclusive, non-transferable, non-sub-licenseable, limited license to use the Service on your personal device and personally use the Service for any of the activities enabled via the Service solely in accordance with and subject to the terms set forth herein, the privacy policy, available below, and in any accompanying user documentation. The Service may not be used for any other purposes.</li>
          <li><b>RESTRICTIONS ON USE.</b> You may not: (i) copy, modify, translate, reverse engineer, decompile, disassemble, or create derivative works based on the Service; (ii) except as permitted herein, share or permit other individuals/entities to use the Service, rent, lease or transfer the Service or rights to use it; (iii) delete or modify any attributions, legal notices or other proprietary designations or labels on the Service, or on any third-party Service/material contained therein; or (iv) use the Service by itself or in conjunction with any other products to infringe upon any third party's rights, including without limitation third party's intellectual property rights, to invade users' privacy in any way, or to track, store, transmit or record personal information about any other user of the Service. Any such forbidden uses shall immediately and automatically terminate your license to use the Service, without derogating from any other remedies available to the Company at law or in equity.</li>
          <li><b>TITLE AND OWNERSHIP.</b> You acknowledge and agree that the Service, including any revisions, corrections, modifications, enhancements and/or upgrades thereto, accompanying materials, and any copies you are permitted to make under these Terms are owned by the Company or its licensors, and are protected under copyright laws and treaties. You further acknowledge and agree that all right, title, and interest in and to the Service, including associated intellectual property rights (including, without limitation, any patents (registered or pending), copyrights, trade secrets, designs or trademarks), evidenced by or embodied in and/or attached or connected or related to the Service, are and shall remain owned solely by the Company and/or its licensors. These Terms does not convey to you any interest in or to the Service, but only a limited, revocable right of use in accordance with the terms of these Terms. Nothing in these Terms constitutes a waiver of our intellectual property rights under any law.</li>
          <li><b>THIRD PARTY APPS.</b> The Service includes third party apps (collectively, “<b>Third Party Content</b>”). Any use by you of Third Party Content that is available via the Service is subject to the terms and conditions of any such Third Party Content. You acknowledge that we have no control over and assume no responsibility for Third Party Content. You assume all responsibility and risk in connection with your use of Third Party Content and we hereby disclaim any and all liability to you or any third party in connection thereto. We have no obligation to examine, review or monitor Third Party Content and are not responsible for the accuracy, completeness, appropriateness or legality of Third Party Content.</li>
          <li><b>DMCA TAKEDOWN POLICY.</b> It is our policy to respond to notices of alleged infringement that comply with the Digital Millennium Copyright Act (DMCA). We respects the intellectual property of others. Copyright owners who believe that their intellectual property rights have been infringed through the online services provided by us may contact us through their authorized agent and request that the infringing material be removed or access to it be blocked. Our designated “Agent for Notice” of claims of copyright infringement can be reached as follows:</li>
          <p>Legal Department at <u><a href="mailto: legal@ironsrc.com">legal@ironsrc.com</a></u></p>
          <p>With a copy to support service at <u><a href="mailto: games-support@is.com">games-support@is.com</a></u></p>
          <p>Proper notification of copyright infringement must be sent to the Agent for Notice (as designated above) and must include the following information:</p>
          <ul>
            <li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</li>
            <li>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works are covered by a single notification, a representative list of such works;</li>
            <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit ironSource to locate the material;</li>
            <li>Information reasonably sufficient to permit ironSource to contact the complaining party, such as an address, telephone number, and, if available, an electronic mail address;</li>
            <li>A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</li>
            <li>A statement that the information in the notification is accurate and, under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
          </ul>
          <p>Please place the following label, “GAMEPORTAL - Notice of Infringement,” in the subject line of all such communications. We will promptly remove or disable access to materials that are the subject of a proper DMCA notification and notify the user responsible for the material about said removal.</p>
          <li><b>CHARGES INCURRED BY YOUR MOBILE CARRIER.</b> Please note that your mobile carrier’s data, and other rates and fees may apply to your use of the Services when you download Third Party Content. You are solely responsible for checking with your mobile carrier how much such use will cost you.</li>
          <li><b>OPEN SOURCE.</b> Open source code components are included in the Services (“<b>Open Source Code(s)</b>”) and are licensed to you under the terms of the applicable open source license agreements. Each of the Open Source Codes has its own copyright and applicable license conditions which shall apply respectively notwithstanding any provision in these Terms to the contrary. In the event of inconsistencies between the terms of the Open Source Codes and the terms of these Terms, the terms of the Open Source Codes shall prevail. Restrictions under these Terms do not apply to such Open Source Codes. You can review the Open Source Codes that we use and their attribution in the Service settings.</li>
          <li><b>UPDATES/UPGRADES.</b> The Company has no obligation to provide technical support under these Terms, but it may notify you at its discretion of available Service updates or upgrades. Some updates/upgrades may be optional and some may be mandatory in order to operate the Service, maintain Service compatibility, provide security updates or bug fixes or offer new features, functionality or versions.</li>
          <li><b>DISABLING THE SMART NOTIFICATIONS.</b> You can disable the smart notifications at any time. Go to the application notifications settings page and choose to disable, or, long press on a smart notification when received and then pressing the information icon will redirect you to the application notifications settings screen.</li>
          <li><b>COMPLIANCE WITH LAW & EXPORT CONTROLS.</b> This Service is intended for use only in compliance with applicable laws and you undertake to use it in accordance with all such applicable laws. Without derogating from the foregoing and from any other terms herein, you agree to comply with all applicable export laws and restrictions and regulations and agree that you will not export, or allow the export or re-export of the Service in violation of any such restrictions, laws or regulations. You agree to the foregoing and represent and warrant that you are not located in, under the control of, or a national or resident of any restricted country under US or other applicable law.</li>
          <li><b>GOVERNMENT USERS.</b> Any use, duplication, or disclosure of the Software by the U.S. government is subject to the restrictions as set forth in these Terms and in DFARS 227.7202-1(a) and 227.7202-3(a) (1995), DFARS 252.227-7013(c)(1)(ii) (OCT 1988), FAR 12.212(a) (1995), FAR 52.227-19, or FAR 52.227-14 (ALT III), as applicable.</li>
          <li><b>CHANGES TO SERVICE OR TERMS.</b> We may, at our sole and absolute discretion, change, modify, add to or delete any of the terms and conditions of these Terms, at any time, without prior written notice to you. If the update is material, we will ask for your consent to it. Your continued use of any part of the Service, following any change to these Terms, constitutes your complete and irrevocable acceptance of any such change. If any modification to these Terms is not acceptable to you, your only recourse is to cease using the Service.</li>
          <li><b>WARRANTY DISCLAIMER.</b> THE SERVICE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE COMPANY DISCLAIMS ALL WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT. THE ENTIRE RISK ARISING OUT OF THE USE OR PERFORMANCE OF THE SERVICE REMAINS WITH YOU. THE COMPANY FURTHER DOES NOT REPRESENT OR WARRANT THAT THE SERVICE WILL ALWAYS BE AVAILABLE, ACCESSIBLE, FUNCTIONAL, UNINTERRUPTED, TIMELY, SECURE, ACCURATE, COMPLETE AND ERROR-FREE, NOR DOES THE COMPANY WARRANT ANY CONNECTION TO OR TRANSMISSION FROM THE INTERNET.</li>
          <li><b>EXCLUSION OF CONSEQUENTIAL DAMAGES.</b> UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (TORT, CONTRACT, OR OTHERWISE), SHALL THE COMAPNY OR ITS LICENSORS BE LIABLE TO YOU OR ANY OTHER PERSON FOR ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY TYPE INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF GOODWILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION, LOSS OF BUSINESS PROFITS, LOSS OF DATA OR BUSINESS INFORMATION, OR ANY AND ALL OTHER COMMERCIAL DAMAGES OR LOSSES. Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so this limitation and exclusion may not apply to you.</li>
          <li><b>LIMITATION OF LIABILITY.</b> YOU ACKNOWLEDGE AND AGREE THAT IN NO EVENT SHALL THE COMPANY HAVE ANY LIABILITY WHATSOEVER, WHETHER IN CONTRACT, TORT OR ANY OTHER THEORY OF LIABILITY, AND WHETHER OR NOT THE POSSIBILITY OF SUCH DAMAGES OR LOSSES HAS BEEN NOTIFIED TO THE COMPANY, IN CONNECTION WITH OR ARISING FROM YOUR USE OF THE SERVICE. YOUR ONLY RIGHT OR REMEDY WITH RESPECT TO ANY DISSATISFACTION WITH SUCH SERVICE IS TO IMMEDIATELY CEASE USE OF THE SERVICE.<br />
            THE COMPANY'S TOTAL LIABILITY TO YOU UNDER OR CONNECTION WITH THIS AGREEMENT SHALL NOT EXCEED ONE THOUSAND U.S DOLLARS (US$1,000).</li>
          <li><b>INDEMNITY.</b> You shall indemnify, defend, and hold us and our directors, officers, and employees from and against all claims, suites, costs, damages, losses, liability, and expenses, including reasonable attorneys’ fees and other legal expenses, arising from or incurred as a result of your use of the Service, or your violation of these Terms.</li>
          <li><b>TERM & TERMINATION.</b> These Terms becomes effective as of the date on which you first use the Service. You may terminate your relationship with us at any time by cease using the Service. Furthermore, your failure to comply with the terms of these Terms or any other agreement you have with us shall terminate your license and these Terms. Upon termination of these Terms the license granted to you in these Terms shall automatically expire and you shall discontinue all further use of the Service. For clarification the Company reserves the right to prevent you from further use of the Service in the event you cease to be in compliance with these Terms.</li>
          <li><b>PRIVACY.</b> Your information may be accessed, collected, used and/or shared by us in accordance with the Privacy Policy available in the Service settings. By using the Service, you consent to our use of your information as set forth therein.</li>
          <li><b>MISCELLANEOUS.</b> THE COMPANY'S ACCEPTANCE OF YOUR USE OF THE SERVICE, IS EXPRESSLY MADE CONDITIONAL ON YOUR AGREEMENT WITH THE TERMS SET FORTH HEREIN. If any provision of these Terms is held to be unenforceable, such provision shall be reformed only to the extent necessary to make it enforceable and such decision shall not affect the enforceability of such provision under other circumstances, or of the remaining provisions hereof under all circumstances.</li>
          <li><b>LAW AND JURISDICTION.</b> These Terms will be governed by, construed and enforced in accordance with the laws of New York, U.S, without regard to its conflicts of law principles or provisions. The application of the United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transactions Act is specifically excluded of these Terms. Any disputes arising out of or in connection with these Terms shall be exclusively settled by binding arbitration under the Rules of Arbitration of the International Chamber of Commerce (the “ICC Rules”) by one arbitrator appointed in accordance with the ICC Rules (the “Arbitrator”). The arbitration shall take place in New York, New York, U.S, and shall be conducted in the English Language. The arbitration proceedings shall be conducted on an expedited basis and shall result in an award within no more than 60 days. The arbitration shall be conducted on a confidential basis. The award of the Arbitrator shall be final and binding on the parties. Nothing contained herein shall prevent us from applying to any court of law in order to obtain temporary injunctions and equitable relief, or any equivalent temporary remedy, in order to restrain the breach of any restrictive covenants pursuant to these Terms. The arbitration award shall be enforceable in any court of competent jurisdiction. Any motion to enforce or vacate an arbitration award under this agreement shall be kept confidential to the maximum extent possible.</li>
          <li><b>CONTACT US.</b> If you have any questions, complaints and/or claims, you may contact us at: <u><a href="mailto: games-support@is.com">games-support@is.com</a></u>.</li>
        </ol>
      </TermsOfUseWrapper>
    </Column>
  );
}
