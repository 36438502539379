import { PlacementType } from "consts";
import * as gamesAPI from "modules/gamesAPI";
import { useState } from "react";
import Modal from "react-modal";
import { useAsync } from "react-use";

type CollectionDebugModalProps = {
  collectionId: number;
  collectionDebug: { [slotId: number]: { reason: string, placementType: PlacementType, packageName?: string, backendReason?: string } };
  showDebug: boolean;
  setShowDebug: (show: boolean) => void;
}

type BackendResult = {
  placementType: PlacementType;
  packageName?: string;
  reason: string;
}

export default function CollectionDebugModal({ collectionId, collectionDebug, showDebug, setShowDebug }: CollectionDebugModalProps) {
  const [debugData] = useState(collectionDebug);

  useAsync(async () => {
    const backendRequest = createBackendRequest();

    if (backendRequest.length) {
      const backendResult = await gamesAPI.whereIsMyApp(backendRequest);
      getAdditionalInformationFromBackend(backendResult);
    } else {
      return [];
    }
  });

  const createBackendRequest = () => {
    return Object.keys(collectionDebug).reduce((acc: { packageName: string, slotId: number, collectionId: number }[], slotId) => {
      const debugSlot = collectionDebug[parseInt(slotId)];
      if (debugSlot.placementType === PlacementType.SPECIFIC_APP && debugSlot.packageName) {
        acc.push({ packageName: debugSlot.packageName, slotId: parseInt(slotId), collectionId });
      }
      return acc;
    }, [] as { packageName: string, slotId: number, collectionId: number }[]);
  }

  const getAdditionalInformationFromBackend = (backendResult: BackendResult[]) => {
    Object.values(debugData).forEach((slot) => {
      const backendSlot = backendResult.find((backendSlotResult: any) => slot.packageName === backendSlotResult.packageName);
      if (backendSlot) {
        slot.backendReason = backendSlot.reason;
      }
    });
  }

  return <Modal
    isOpen={showDebug}
    shouldCloseOnOverlayClick={false}
    style={{ content: { textAlign: "left" } }}
  >
    <div style={{ maxHeight: '85vh' }}>
      <button style={{ marginBottom: "20px" }} onClick={() => setShowDebug(false)}>Close</button>
      {debugData
        && Object.keys(collectionDebug).map((slotId) => {
          const { reason, placementType, backendReason } = collectionDebug[parseInt(slotId)];
          return <div key={slotId}>
            <h3>Slot {slotId}</h3>
            <p style={{ fontWeight: "bold" }}>Placement Type</p>
            <p>{placementType}</p>
            <p style={{ fontWeight: "bold" }}>Drop Reason</p>
            <p>{reason}</p>
            {
              backendReason && <div>
                <p style={{ fontWeight: "bold" }}>Additional Backend Information</p>
                <p>{backendReason}</p></div>
            }
          </div>
        })}
    </div>
  </Modal>
}
