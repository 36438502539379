import useReportScrolledIntoView from "hooks/useReportScrolledIntoView";
import { assetURL } from "modules/utils";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { RelatedArticle } from "types";
import ArticleHeader, { ArticleHeaderType } from "./ArticleHeader";

type ArticleCardProps = RelatedArticle & { type?: ArticleHeaderType }

export default function ArticleCard({
  id,
  headerImage,
  title,
  label,
  labelIcon,
  coverPhoto,
  appPackageName,
  type = ArticleHeaderType.CARD,
  dpId,
}: ArticleCardProps) {
  const articleCardRef = useRef(null)
  const navigate = useNavigate()

  useReportScrolledIntoView(
    articleCardRef,
    type === ArticleHeaderType.COLLECTION ? "discover" : "app",
    "article impression",
    { article: { id, headerImage, title, label, labelIcon, coverPhoto, appPackageName, dpId } }
  )

  return <ArticleHeader
    title={title}
    chipLabel={label}
    chipIcon={labelIcon}
    backgroundImage={coverPhoto || headerImage || assetURL(appPackageName, "headerImage")}
    type={type}
    onClick={() => navigate(`/articles/${id}`)}
    ref={articleCardRef}
  />
}