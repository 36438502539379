import { Player } from "@lottiefiles/react-lottie-player";
import classNames from "classnames";
import colors from "styles/colors";
import { TabResponse } from "types";
import * as utils from "../modules/utils";
import { Column } from "./Layout";
import Skeleton from "./Skeleton";
import css from "./Tab.module.scss";
import { Subtitle } from "./base/Typography";

export interface TabProps extends TabResponse {
  lottieRef?: any
  onClick: () => void
  selected?: boolean
}

export default function Tab({ id, name, icon, lottieRef, onClick, selected }: TabProps) {
  return <Column
    onClick={onClick}
    data-testid={utils.generateDataTestId({
      prefix: "tab",
      properties: [name],
    })}
  >
    {id ?
      <Player
        id={utils.generateDataTestId({ prefix: 'tab', properties: ['lottie', name] })}
        ref={lottieRef}
        keepLastFrame
        src={utils.fetchLottie(`/lottie/${icon}_icon.json`)}
        style={{
          width: 66,
          height: 66,
        }}
      />
      : <Skeleton variant="circular" width={42} height={42} sx={{ margin: '12px' }} />
    }
    <div className={css.tabLabel}>
      <Subtitle
        size="s"
        bold={selected}
        sx={{ color: selected ? colors.textWhite : colors.textLight }}
        className={classNames(css.label, !name && css.skeleton)}
        skeletonWidth={50}
      >
        {name}
      </Subtitle>
    </div>
  </Column >
}