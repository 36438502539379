import css from "./CardPrice.module.scss";
import { Row } from "./Layout";

interface CardPriceProps {
  originalPrice: number | null,
  price: number | null,
  discountPercent: number,
}

const CardPrice = ({ originalPrice, price, discountPercent }: CardPriceProps) => {

  const getDiscountText = (): string => discountPercent < 1 ? `-${Math.floor(discountPercent * 100)}%` : "FREE"

  return <Row
    className={css.cardPrice}
    align="ml"
    gap="xs"
  >
    <div className={css.discountPercent}>{getDiscountText()}</div>
    {discountPercent < 1 && <div className={css.price}>{price} $</div>}
    <div className={css.originalPrice}>{originalPrice} $</div>
  </Row>
}

export default CardPrice;