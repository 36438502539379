import { log } from "./logs"

// prettier-ignore
export const iosPackages: { [key: string]: {
  bundleId: string
  ipaURL: string
  appSize: number
  bundleSize: number
  appId?: number
}} = {
  "air.com.goodgamestudios.empirefourkingdoms": { bundleId: "com.goodgamestudios.empirefourkingdoms", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.goodgamestudios.empirefourkingdoms.ipa", appSize: 224000000, bundleSize: 115000000},
  "com.alibaba.aliexpresshd": { bundleId: "com.alibaba.iAliexpress", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.alibaba.iAliexpress.ipa", appSize: 112000000, bundleSize: 53000000},
  "com.amanotes.gs.g06": { bundleId: "com.amanotes.gs.g06", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.amanotes.gs.g06.ipa", appSize: 239000000, bundleSize: 93000000},
  "com.amazon.kindle": { bundleId: "com.amazon.Lassen", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.amazon.Lassen.ipa", appSize: 120000000, bundleSize: 58000000},
  "com.amazon.mShop.android.shopping": { bundleId: "com.amazon.Amazon", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.amazon.Amazon.ipa", appSize: 210000000, bundleSize: 72000000},
  "com.BallGames.Woodturning": { bundleId: "com.BallGames.Woodturning", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.BallGames.Woodturning.ipa", appSize: 305000000, bundleSize: 115000000},
  "com.biglime.cookingmadness": { bundleId: "net.ajoy.kf", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/net.ajoy.kf.ipa", appSize: 355000000, bundleSize: 247000000},
  "com.block.jigsaw.puzzle.gallery": { bundleId: "com.block.puzzle.jigsaw.picture.gallery.ios", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.block.puzzle.jigsaw.picture.gallery.ios.ipa", appSize: 176000000, bundleSize: 116000000},
  "com.block.juggle": { bundleId: "com.blockpuzzle.us.ios", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.blockpuzzle.us.ios.ipa", appSize: 91000000, bundleSize: 40000000},
  "com.chinykian.aslightchanceofsawblades": { bundleId: "com.chinykian.aslightchanceofsawblades", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.chinykian.aslightchanceofsawblades.ipa", appSize: 38000000, bundleSize: 27000000},
  "com.cnn.mobile.android.phone": { bundleId: "com.cnn.iphone", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.cnn.iphone.ipa", appSize: 152000000, bundleSize: 69000000},
  "com.crescivegames.twistedtangle": { bundleId: "com.crescivegames.twistedtangle", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.crescivegames.twistedtangle.ipa", appSize: 298000000, bundleSize: 83000000},
  "com.devhd.feedly": { bundleId: "com.devhd.feedly", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.devhd.feedly.ipa", appSize: 121000000, bundleSize: 76000000},
  "com.disney.disneyplus": { bundleId: "com.disneyplus.mea", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.disneyplus.mea.ipa", appSize: 87000000, bundleSize: 36000000},
  "com.distimo.phoneguardian": { bundleId: "com.phoneguardianapp.ios", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.phoneguardianapp.ios.ipa", appSize: 40000000, bundleSize: 16000000},
  "com.dreamgames.royalmatch": { bundleId: "com.dreamgames.royalmatch", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.dreamgames.royalmatch.ipa", appSize: 232000000, bundleSize: 150000000},
  "com.dropbox.android": { bundleId: "com.getdropbox.Dropbox", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.getdropbox.Dropbox.ipa", appSize: 237000000, bundleSize: 103000000},
  "com.dts.freefireth": { bundleId: "com.dts.freefireth", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.dts.freefireth.ipa", appSize: 1300000000, bundleSize: 525000000},
  "com.dxx.firenow": { bundleId: "com.dxx.firenow", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.dxx.firenow.ipa", appSize: 403000000, bundleSize: 257000000},
  "com.ea.game.starwarscapital_row": { bundleId: "com.ea.starwarscapital.bv", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.ea.starwarscapital.bv.ipa", appSize: 152000000, bundleSize: 51000000},
  "com.ea.games.simsfreeplay_row": { bundleId: "com.ea.sims3deluxe.ipad.bv", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.ea.sims3deluxe.ipad.bv.ipa", appSize: 1900000000, bundleSize: 1308000000},
  "com.ea.games.simsfreeplay_na": { bundleId: "com.ea.sims3deluxe.ipad.bv", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.ea.sims3deluxe.ipad.bv.ipa", appSize: 1900000000, bundleSize: 1308000000},
  "com.ea.gp.fifamobile": { bundleId: "com.ea.ios.fifamobile", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.ea.ios.fifamobile.ipa", appSize: 213000000, bundleSize: 110000000},
  "com.easygames.race": { bundleId: "com.easygames.race", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.easygames.race.ipa", appSize: 269000000, bundleSize: 166000000},
  "com.evernote": {  bundleId: "com.evernote.iPhone.Evernote", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.evernote.iPhone.Evernote.ipa", appSize: 218000000, bundleSize: 98000000},
  "com.funtomic.matchmasters": { bundleId: "com.funtomic.matchmasters", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.funtomic.matchmasters.ipa", appSize: 329000000, bundleSize: 124000000},
  "com.Garawell.BridgeRace": { bundleId: "com.Garawell.BridgeRace", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.Garawell.BridgeRace.ipa", appSize: 260000000, bundleSize: 147000000},
  "com.groupon": {  bundleId: "com.groupon.grouponapp", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.groupon.grouponapp.ipa", appSize: 115000000, bundleSize: 47000000},
  "com.hulu.plus": { bundleId: "net.handya.OR-Hulu", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/net.handya.OR-Hulu.ipa", appSize: 16000000, bundleSize: 7000000},
  "com.hwqgrhhjfd.idlefastfood": { bundleId: "com.hwqgrhhjfd.idlefastfood", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.hwqgrhhjfd.idlefastfood.ipa", appSize: 301000000, bundleSize: 111000000},
  "com.innersloth.spacemafia": { bundleId: "com.innersloth.amongus", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.innersloth.amongus.ipa", appSize: 687000000, bundleSize: 357000000},
  "com.innogames.foeandroid": { bundleId: "com.innogames.iforge", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.innogames.iforge.ipa", appSize: 226000000, bundleSize: 142000000},
  "com.instagram.android": { bundleId: "com.burbn.instagram", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.burbn.instagram.ipa", appSize: 220000000, bundleSize: 118000000},
  "com.ketchapp.rider.evolution.world.race.car.bike.flip": { bundleId: "com.ketchapp.rider.evolution.world.race.car.bike.flip.stunt", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.ketchapp.rider.evolution.world.race.car.bike.flip.stunt.ipa", appSize: 213000000, bundleSize: 110000000},
  "com.kiloo.subwaysurf": { bundleId: "com.kiloo.subwaysurfers", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.kiloo.subwaysurfers.ipa", appSize: 232000000, bundleSize: 131000000},
  "com.king.bubblewitch3": { bundleId: "com.midasplayer.apps.bubblewitchsaga3", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.midasplayer.apps.bubblewitchsaga3.ipa", appSize: 402000000, bundleSize: 101000000},
  "com.king.candycrushjellysaga": { bundleId: "com.midasplayer.apps.candycrushjellysaga1", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.midasplayer.apps.candycrushjellysaga1.ipa", appSize: 250000000, bundleSize: 94000000},
  "com.king.candycrushsaga": { bundleId: "com.midasplayer.apps.candycrushsaga", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.midasplayer.apps.candycrushsaga.ipa", appSize: 312000000, bundleSize: 92000000},
  "com.king.candycrushsodasaga": { bundleId: "com.midasplayer.apps.candycrushsodasaga", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.midasplayer.apps.candycrushsodasaga.ipa", appSize: 307000000, bundleSize: 90000000},
  "com.king.farmheroessaga": { bundleId: "com.midasplayer.apps.farmheroessaga", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.midasplayer.apps.farmheroessaga.ipa", appSize: 221000000, bundleSize: 87000000},
  "com.king.petrescuesaga": { bundleId: "com.midasplayer.apps.petrescuesaga", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.midasplayer.apps.petrescuesaga.ipa", appSize: 200000000, bundleSize: 116000000},
  "com.luckyskeletonstudios.idlebanktycoon": { bundleId: "com.luckyskeletonstudios.idlebanktycoon2", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.luckyskeletonstudios.idlebanktycoon2.ipa", appSize: 247000000, bundleSize: 129000000},
  "com.marmalade.monopoly": { bundleId: "com.scopely.monopolygo", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.scopely.monopolygo.ipa", appSize: 230000000, bundleSize: 134000000},
  "com.miniclip.eightballpool": { bundleId: "com.miniclip.8ballpoolmult", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.miniclip.8ballpoolmult.ipa", appSize: 123000000, bundleSize: 65000000},
  "com.miniit.brain": { bundleId: "com.miniit.brain", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.miniit.brain.ipa", appSize: 172000000, bundleSize: 82000000},
  "com.moonactive.coinmaster": { bundleId: "com.mobile-softing.coinmaster", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.mobile-softing.coinmaster.ipa", appSize: 221000000, bundleSize: 62000000},
  "com.nike.omega": { bundleId: "com.nike.omega", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.nike.omega.ipa", appSize: 172000000, bundleSize: 67000000},
  "com.nvsgames.snap": { bundleId: "com.nvsgames.snap", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.nvsgames.snap.ipa", appSize: 244000000, bundleSize: 143000000},
  "com.outfit7.mytalkingtomfree": { bundleId: "com.outfit7.mytalkingtom", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.outfit7.mytalkingtom.ipa", appSize: 383000000, bundleSize: 126000000},
  "com.paypal.android.p2pmobile": { bundleId: "com.yourcompany.PPClient", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.yourcompany.PPClient.ipa", appSize: 360000000, bundleSize: 143000000},
  "com.peoplefun.wordcross": { bundleId: "com.peoplefun.wordcross", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.peoplefun.wordcross.ipa", appSize: 206000000, bundleSize: 133000000},
  "com.pinterest": {  bundleId: "pinterest", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/pinterest.ipa", appSize: 135000000, bundleSize: 54000000},
  "com.playdemic.golf.android": { bundleId: "com.playdemic.golf", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.playdemic.golf.ipa", appSize: 131000000, bundleSize: 92000000},
  "com.playgendary.tom": { bundleId: "com.happymagenta.fromcore", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.happymagenta.fromcore.ipa", appSize: 201000000, bundleSize: 53000000},
  "com.playrix.fishdomdd.gplay": { bundleId: "com.playrix.fishdom-freeplay", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.playrix.fishdom-freeplay.ipa", appSize: 209000000, bundleSize: 141000000},
  "com.playrix.gardenscapes": { bundleId: "com.playrix.gardenscapes-m3-ios", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.playrix.gardenscapes-m3-ios.ipa", appSize: 237000000, bundleSize: 136000000},
  "com.playrix.township": { bundleId: "com.playrix.township-ios", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.playrix.township-ios.ipa", appSize: 201000000, bundleSize: 125000000},
  "com.robinhood.android": { bundleId: "com.robinhood.release.Gateway", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.robinhood.release.Gateway.ipa", appSize: 75000000, bundleSize: 31000000},
  "com.roblox.client": { bundleId: "com.roblox.robloxmobile", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.roblox.robloxmobile.ipa", appSize: 170000000, bundleSize: 98000000},
  "com.rovio.baba": { bundleId: "com.rovio.baba", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.rovio.baba.ipa", appSize: 291000000, bundleSize: 178000000},
  "com.rubygames.doctorcare": { bundleId: "com.doctor.care", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.doctor.care.ipa", appSize: 196000000, bundleSize: 79000000},
  "com.scopely.wheeloffortune": { bundleId: "com.scopely.wheeloffortune", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.scopely.wheeloffortune.ipa", appSize: 275000000, bundleSize: 78000000},
  "com.scribd.app.reader0": { bundleId: "com.scribd.iscribd", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.scribd.iscribd.ipa", appSize: 130000000, bundleSize: 69000000},
  "com.sgn.pandapop.gp": { bundleId: "com.sgn.pandapop", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.sgn.pandapop.ipa", appSize: 229000000, bundleSize: 145000000},
  "com.snapchat.android": { bundleId: "com.toyopagroup.picaboo", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.toyopagroup.picaboo.ipa", appSize: 265000000, bundleSize: 92000000},
  "com.spaceapegames.beatstar": { bundleId: "com.spaceapegames.beatstar", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.spaceapegames.beatstar.ipa", appSize: 370000000, bundleSize: 194000000},
  "com.spaceapegames.carsuperstar": { bundleId: "com.spaceapegames.carsuperstar", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.spaceapegames.carsuperstar.ipa", appSize: 347000000, bundleSize: 176000000},
  "com.spotify.music": { bundleId: "com.spotify.client", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.spotify.client.ipa", appSize: 130000000, bundleSize: 56000000},
  "com.supercell.clashroyale": { bundleId: "com.supercell.scroll", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.supercell.scroll.ipa", appSize: 444000000, bundleSize: 414000000},
  "com.symantec.mobilesecurity": { bundleId: "com.symantec.mobilesecurity", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.symantec.mobilesecurity.ipa", appSize: 128000000, bundleSize: 46000000},
  "com.ted.android": { bundleId: "com.ted.TED", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.ted.TED.ipa", appSize: 48000000, bundleSize: 22000000},
  "com.topgamesinc.evony": { bundleId: "com.topgamesinc.evony", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.topgamesinc.evony.ipa", appSize: 785000000, bundleSize: 687000000},
  "com.tripadvisor.tripadvisor": { bundleId: "com.tripadvisor.LocalPicks", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.tripadvisor.LocalPicks.ipa", appSize: 138000000, bundleSize: 52000000},
  "com.tripledot.woodoku": { bundleId: "com.tripledot.woodoku", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.tripledot.woodoku.ipa", appSize: 254000000, bundleSize: 103000000},
  "com.waze": {  bundleId: "com.waze.iphone", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.waze.iphone.ipa", appSize: 114000000, bundleSize: 55000000},
  "com.youmusic.magictiles": { bundleId: "com.amanotes.magictiles", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.amanotes.magictiles.ipa", appSize: 419000000, bundleSize: 192000000},
  "com.zynga.wwf2.free": { bundleId: "com.newtoyinc.WordsWithFriendsFree", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.newtoyinc.WordsWithFriendsFree.ipa", appSize: 181000000, bundleSize: 86000000},
  "es.parrotgames.restaurantcity": { bundleId: "worldchef", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/worldchef.ipa", appSize: 136000000, bundleSize: 72000000},
  "io.supercent.burgeridle": { bundleId: "io.supercent.burgeridle", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/io.supercent.burgeridle.ipa", appSize: 204000000, bundleSize: 55000000},
  "me.lyft.android": { bundleId: "com.zimride.instant", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/com.zimride.instant.ipa", appSize: 272000000, bundleSize: 99000000},
  "tv.twitch.android.app": { bundleId: "tv.twitch", ipaURL: "https://aura-gems.isappcloud.com/ios-demo/ipa/tv.twitch.ipa", appSize: 158000000, bundleSize: 56000000},
}

export function getIOSPackage(packageName: string, appId?: number) {
  if (!iosPackages[packageName]) return null

  // Assign appId for future use in delivery
  if (appId) {
    // @ts-ignore
    window.appIdToPackageName ||= {}
    // @ts-ignore
    window.appIdToPackageName[appId] = packageName
  }

  return iosPackages[packageName]
}

export function getIOSAppInfo(appInfo: any) {
  delete appInfo.appDelivery

  const ios = getIOSPackage(appInfo.packageName, appInfo.id)
  if (ios) {
    appInfo.isPartnerApp = true
    appInfo.packageName = ios.bundleId
    appInfo.appDelivery = {
      size: ios.appSize,
      versionCode: 1,
      versionName: "4.55.21",
    }
  }

  return appInfo
}

export function convertToIOSApps(placements: [any]) {
  // we want to convert only apps within the collection
  return placements.map((placement: any) => {
    if (["PROMO_CARD", "ARTICLE", "VIDEO", "EVENT"].includes(placement.type)) {
      return placement
    }

    // @ts-ignore
    const ios = getIOSPackage(placement.packageName, app.id)
    if (ios) {
      // @ts-ignore
      placement.packageName = ios.bundleId
      placement.isPartnerApp = true
      placement.versionCode = 1
    } else {
      placement.isPartnerApp = false
    }

    return placement
  })
}

export function getIOSDelivery(appId: number) {
  log("getIOSPackageByAppId", appId)

  // @ts-ignore
  const packageName = window.appIdToPackageName[appId]
  if (!packageName) throw new Error(`Cannot find iOS app for: ${appId}`)

  const ios = iosPackages[packageName]
  if (!ios) throw new Error(`Cannot find iOS app for: ${packageName}`)

  return {
    title: ios.bundleId,
    catalog_id: appId,
    catalog_app_id: ios.bundleId,
    icon: null,
    banner: null,
    delivery_methods: [
      {
        type: "IPA",
        properties: {
          delivery_url: ios.ipaURL,
          version_code: 1,
          version_name: "3.5.1181",
          size: ios.appSize,
          primary_signature: "",
          secondary_signature: "",
        },
      },
    ],
  }
}
