import { assetURL } from "modules/utils";
import css from "./AppIcon.module.scss";
import Img from "./Img";
import Skeleton from "./Skeleton";
import classNames from "classnames"

interface AppIconProps {
  packageName?: string
  size: number
  borderRadius?: number
  dataTestId?: string
  externalUrl?: string
  className?:string
}

export default function AppIcon({ packageName, size, borderRadius, dataTestId, externalUrl, className }: AppIconProps) {
  const borderRadiusStyle = `${borderRadius || (size * 0.2)}px`;

  if (!packageName && !externalUrl) {
    return <Skeleton
      variant="rounded"
      width={size}
      height={size}
      sx={{ borderRadius: borderRadiusStyle }}
    />
  }

  return (
    <Img
      src={
        externalUrl
          ? externalUrl
          : packageName
            ? assetURL(packageName, "icon")
            : ""
      }
      fallbackImg="/img/app_icon_fallback.svg"
      alt="app_icon"
      width={size}
      height={size}
      className={className ? classNames(css.appIcon, className) : css.appIcon}
      style={{ borderRadius: borderRadiusStyle }}
      dataTestId={dataTestId}
    />
  );
}
