import { log } from "modules/logs"

/**
 * This module provides functions for managing event listeners and dispatching events.
 *
 * @module eventsManager
 */

let eventSubscribers: { [key: string]: Function[] } = {}

/**
 * Dispatches an event with the given name and data.
 *
 * @param {string} eventName - The name of the event to dispatch.
 * @param {any} [data={}] - The data to pass to the event subscribers.
 */
export function dispatch(eventName: string, data: any = {}) {
  log("eventsManager::dispatchEvent", eventName, JSON.stringify(data))
  const subscribers = eventSubscribers[eventName] || []
  subscribers.forEach(callback => callback(data))
}

/**
 * Adds a listener for the given event name.
 *
 * @param {string} eventName - The name of the event to listen for.
 * @param {Function} callback - The function to call when the event is dispatched.
 */
export function addListener(eventName: string, callback: any) {
  log("eventsManager::addEventListener", eventName)
  eventSubscribers[eventName] = eventSubscribers[eventName] || []
  eventSubscribers[eventName].push(callback)
}

/**
 * Removes a listener for the given event name.
 *
 * @param {string} eventName - The name of the event to remove the listener from.
 * @param {Function} callback - The function to remove from the event subscribers.
 */
export function removeListener(eventName: string, callback: any) {
  log("eventsManager::removeEventListener", eventName)
  if (eventSubscribers[eventName]) {
    const eventIndex = eventSubscribers[eventName].indexOf(callback)
    if (eventIndex > -1) {
      eventSubscribers[eventName].splice(eventIndex, 1)
    }
  }
}

/**
 * Removes all listeners for all events.
 */
export function removeAll() {
  eventSubscribers = {}
}
