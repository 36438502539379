import Collapse from '@mui/material/Collapse';
import CTABtn from "components/CTABtn";
import { Column, Row } from "components/Layout";
import { capitalize, startCase } from "lodash";
import { getClientDescriptor } from "modules/communication/communication";
import { cdDebugKeys } from "modules/utils";
import colors from "styles/colors";
import Icon from '../Icon';

import React, { useState } from "react";
import { useLocalStorage } from "react-use";

const panelStyle = { background: 'rgba(70,70,70,0.95)', padding: 15, borderRadius: 15, gap: 15 }
const inputStyle = { height: 25, minWidth: 25, flex: 1, }


const topCdFields = [
  'density',
  'locale',
  'uid'
]


export default function DebugPanel({ onClose }: { onClose: () => void }) {
  const [showCd, setShowCd] = useState(false)
  const [expandCdKeys, setExpandCdKeys] = useState(false);
  const handleExpandClick = () => setExpandCdKeys(!expandCdKeys);

  const [architectures, setArchitectures, clearArchitectures] = useLocalStorage('__cdArchitectures', '');

  const [gamesAPIUrl, setGamesAPIUrl, clearGamesAPIUrl] = useLocalStorage('__gamesAPIUrl', '', { raw: true });
  const [debugMode, setDebugMode, clearDebugMode] = useLocalStorage('__debugMode', '', { raw: true });
  const [allowDownloadingOverMobileData, setAllowDownloadingOverMobileData] = useLocalStorage('__allowDownloadingOverMobileData', '', { raw: true });
  const [disableReporting, setDisableReporting, clearDisableReporting] = useLocalStorage('__disableReporting', '', { raw: true });

  const cdKeysMutators = cdDebugKeys.map(({ name, cdName, type }: any) => {
    const [value, set, clear] = useLocalStorage(`__cd${capitalize(name)}`, '', { raw: true });
    return {
      value,
      set,
      clear,
      cdName,
      name,
      type
    }
  });

  function clearBtn(clearMethod: () => void) {
    return <CTABtn
      style={{ minWidth: 30, padding: 0, background: "rgba(255,255,255,0.3)" }}
      onClick={clearMethod}>X</CTABtn>
  }

  const createCdKeysInputs = (mutators: any, style: Record<string, any> = {}) => mutators.map(({ set, clear, value, cdName, name, type }: any) =>
    <Row align="msb" gap={10} key={name} style={{ ...style, margin: 0 }}>
      <h5>{startCase(name).toLowerCase()} <small>({cdName})</small></h5>
      <input style={inputStyle} type={type} value={value || ''} onChange={e => set(e.target.value)} />
      {clearBtn(clear)}
    </Row>)

  return (
    <Column style={{
      position: "fixed",
      top: 20,
      left: 20,
      width: "calc(100% - 40px)",
      height: "calc(100% - 40px)",
      boxShadow: "0 0 10px 0 rgba(0,0,0,0.5)",
      background: "rgba(30,30,30,0.95)",
      zIndex: 9,
      padding: 15,
      color: "white",
    }}
      gap={20}>
      <Row align="msb">
        <h2>DEBUG PANEL</h2>
        {clearBtn(onClose)}
      </Row>

      <Column style={panelStyle}>
        <Row align="msb">
          <h4>Client Descriptor</h4>
          <CTABtn onClick={() => setShowCd(!showCd)}>{showCd ? "Close preview" : "Preview cd"}</CTABtn>
        </Row>

        <Row align="msb" gap={10}>
          <h5>archs <small>(dcsa)</small></h5>
          <Row align="msb" gap={5}>
            {["arm64-v8a", "armeabi-v7a", "armeabi"].map(arc => {
              return <React.Fragment key={arc}>
                <input style={{ ...inputStyle, flex: 'none' }}
                  id={"arc_" + arc}
                  type="checkbox"
                  checked={architectures && (architectures as any)[arc] || false}
                  onChange={e => setArchitectures({
                    ...architectures as any,
                    [arc]: e.target.checked
                  })} />
                <label htmlFor={"arc_" + arc}>{arc}</label>
              </React.Fragment>
            })}
          </Row>
          {clearBtn(clearArchitectures)}
        </Row>
        {
          createCdKeysInputs(cdKeysMutators.filter(v => topCdFields.includes(v.name)))
        }

        <Row onClick={() => handleExpandClick()}>
          <h3>extra fields</h3>
          <Icon
            name="chevron-right"
            size={22}
            color={colors.textWhite}
            style={expandCdKeys ? { transform: 'rotate(90deg)' } : undefined}
          />
        </Row>
        <Collapse in={expandCdKeys} timeout="auto" unmountOnExit>
          {
            createCdKeysInputs(cdKeysMutators.filter(v => !topCdFields.includes(v.name)), { marginBottom: "15px" })
          }

        </Collapse>

        {showCd && <pre>{JSON.stringify(getClientDescriptor(), null, 2)}</pre>}
      </Column>

      <Column style={panelStyle}>
        <h4>Debug Options</h4>
        <Row align="ml" gap={5}>
          <input style={{ ...inputStyle, flex: 'none' }}
            type="checkbox"
            checked={debugMode === "true"}
            onChange={e => setDebugMode(`${e.target.checked}`)} />
          Debug Mode
        </Row>
        <Row align="ml" gap={5}>
          <input style={{ ...inputStyle, flex: 'none' }}
            type="checkbox"
            checked={allowDownloadingOverMobileData === "true"}
            onChange={e => setAllowDownloadingOverMobileData(`${e.target.checked}`)} />
          Allow Downloading Over Mobile Data
        </Row>

        <Row align="mc" gap={10}>
          <h4>Url Override</h4>
          <input style={inputStyle} type="text" value={gamesAPIUrl || ''} onChange={e => setGamesAPIUrl(e.target.value)} />
          {clearBtn(clearGamesAPIUrl)}
        </Row>

        <Row align="ml" gap={5}>
          <input style={{ ...inputStyle, flex: 'none' }}
            type="checkbox"
            checked={disableReporting === "true"}
            onChange={e => setDisableReporting(`${e.target.checked}`)} />
          Disable Reporting
        </Row>
      </Column>

      <Column style={panelStyle}>
        <CTABtn onClick={() => {
          throw new Error("Test Error")
        }}>Throw Test Error</CTABtn>
        <CTABtn onClick={() => window.location.reload()}>Reload App</CTABtn>
      </Column>
    </Column>
  )
}
