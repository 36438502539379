export const titleL = `600 28px "Open Sans"`
export const subtitleL = `600 16px "Open Sans"`
export const subtitleM = `600 14px "Open Sans"`
export const subtitleS = `600 12px "Open Sans"`
export const bodyS = `400 12px "Open Sans"`
export const captionUnderline = `600 10px "Open Sans"`
export const buttonL = `600 14px "Open Sans"`
export const buttonS = `600 12px "Open Sans"`
export const caption = `600 10px "Open Sans"`
export const subtitleSBold = `700 12px "Open Sans"`
export const subtitleLBold = `700 16px "Open Sans"`
export const buttonSUnderline = `600 12px "Open Sans"`
export const bodyM = `400 14px "Open Sans"`
export const bodyL = `400 16px "Open Sans"`
export const titleM = `700 22px "Open Sans"`
export const titleS = `600 20px "Open Sans"`
export const titleSBold = `700 20px "Open Sans"`
export const subtitleMBold = `700 14px "Open Sans"`
export const wTitle = `700 38px "Poppins"`

const texts: Record<string, string> = {
  titleL,
  subtitleL,
  subtitleM,
  subtitleS,
  bodyS,
  captionUnderline,
  buttonL,
  buttonS,
  caption,
  subtitleSBold,
  subtitleLBold,
  buttonSUnderline,
  bodyM,
  bodyL,
  titleM,
  titleS,
  subtitleMBold,
  wTitle,
}
export default texts
