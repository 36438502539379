import styled from "@emotion/styled";
import { Collapse, List, ListItem } from "@mui/material";
import AppIcon from "components/AppIcon";
import Icon from "components/Icon";
import { Column, Row } from "components/Layout";
import Rating from "components/Rating";
import { Body, Button, Subtitle } from "components/base/Typography";
import useNavigateToAppInfo from "hooks/useNavigateToAppInfo";
import { reportConversion } from "modules/reporting";
import { generateDataTestId } from "modules/utils";
import { useEffect, useState } from "react";
import colors from 'styles/colors';
import t from "translations";
import { IArticle, RelatedApp } from "types";
import classes from './ArticlesQuickSummary.module.scss';

const BodyStyled = styled(Body)`
  line-height: 22px;
  color: ${colors.textLight};
`

type ArticleQuickSummaryContentProps = Omit<ArticleQuickSummaryProps, 'content'> & { line: string, ellipsis?: boolean, visible?: boolean }

interface ArticleQuickSummaryAppProps {
  app: RelatedApp
  article: IArticle
  visible?: boolean
}

interface ArticleQuickSummaryProps {
  content: string
  article: IArticle
  relatedApps: RelatedApp[]
}

function ArticleQuickSummaryApp({ app, article, visible }: ArticleQuickSummaryAppProps) {
  const navigateToAppInfo = useNavigateToAppInfo()
  useEffect(() => {
    if (visible) {
      reportConversion(app.packageName, "impression", {
        app,
        article,
      })
    }
  }, [visible])

  return <Row align="ml" gap={10} onClick={() => navigateToAppInfo({
    isPartnerApp: app.isPartnerApp,
    appId: app.id,
    appTitle: app.title,
  })}>
    <AppIcon packageName={app.packageName} size={30} />
    <Column gap="xxs">
      <Subtitle
        className={"ellipsis"}
        size="s"
        skeletonWidth="100%"
        sx={{ color: colors.textLight }}
      >
        {app.title}
      </Subtitle>
      <Rating
        small
        color={colors.textLight}
        value={app.score}
        dataTestId={generateDataTestId({ prefix: "article", properties: ["quick-summary", "rating"] })}
      />
    </Column>
  </Row>
}

function ArticleQuickSummaryContent({ line, ellipsis, relatedApps, article, visible }: ArticleQuickSummaryContentProps) {
  const isBullet = line.startsWith('-');
  const appPackageName = line.replace("app[", "").replace("]", "");
  const app = relatedApps.find(({ packageName }) => packageName === appPackageName)

  return <ListItem disableGutters={!isBullet} sx={
    {
      ...(isBullet ? { display: 'list-item' } : { marginLeft: -3 }),
      ...(!app && { padding: 0 })
    }
  }>
    {isBullet ?
      <BodyStyled className={ellipsis ? 'ellipsis' : undefined}>
        {line.replace(/^[\s|\-]+/, '')}
      </BodyStyled > :
      app ?
        <ArticleQuickSummaryApp app={app} article={article} visible={visible} /> :
        <BodyStyled className={ellipsis ? 'ellipsis' : undefined}>
          {line}
        </BodyStyled >}
  </ListItem >
}

export default function ArticleQuickSummary({ content, relatedApps, article }: ArticleQuickSummaryProps) {
  const [expand, setExpand] = useState(false)

  const contentFirstLines = content.split('\n').slice(0, 2)
  const contentRest = content.split('\n').slice(2)

  return <Column className={classes.wrapper}>
    <Row gap={8} align="m" padding="20px 20px 0 20px">
      <Icon name="summary" size={16} color={colors.textWhite} />
      <Subtitle>Quick Summary</Subtitle>
    </Row>
    <Row padding="0 20px">
      <List sx={{ listStyleType: 'disc', pl: 3, paddingTop: 1.5, paddingBottom: 0.5, width: '100%', }}>
        {contentFirstLines.map((line, i) => <ArticleQuickSummaryContent key={i} line={line} ellipsis={!expand} relatedApps={relatedApps} article={article} />)}
        <Collapse in={expand} >
          {contentRest.map((line, i) => <ArticleQuickSummaryContent key={i} line={line} relatedApps={relatedApps} article={article} visible={expand} />)}
        </Collapse>
      </List>
    </Row>
    <Column align="sb" padding="0 20px 20px 20px" className={expand ? undefined : classes.readMore}>
      {!expand && <Row style={{ height: 27 }} />}
      <Button size="s" underline onClick={() => setExpand(!expand)}>{expand ? t("read_less") : t("read_more")}</Button>
    </Column>
  </Column >
}