import PageLoader from "containers/PageLoader"
import useReportPageEvents from "hooks/useReportPageEvents"
import { useParams } from "react-router-dom"

export function CollectionPage() {
  const { collectionId } = useParams()
  useReportPageEvents()

  const collectionIdProp = collectionId ? parseInt(collectionId) : undefined

  return <div className="bottom-overlay"><PageLoader collectionId={collectionIdProp} /></div>
}
