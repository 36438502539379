import React from "react"

import { ICollection, ITab } from "types"
import Collection from "./Collection"

interface PageDefaultLayoutProps {
  tab: ITab
  collections: ICollection[],
  loading?: boolean,
  debug?: [{ appCollectionId: [{ slotId: number }] }],
}

const PageDefaultLayout = (data: PageDefaultLayoutProps) => {
  return data.collections.map((collection: ICollection, i: number) => {
    return (
      <React.Fragment key={i}>
        {collection && (
          <Collection
            showTitle
            id={collection.id}
            total={collection.total}
            limit={collection.limit}
            title={collection.title}
            layout={collection.layout}
            cardType={collection.cardType}
            placements={collection.placements}
            debug={data.debug?.[collection.id]}
            // reporting
            tab={data.tab}
            collection={collection}
            loading={data.loading}
          />
        )}

      </React.Fragment>
    )
  })
}

export default PageDefaultLayout
