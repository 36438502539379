
import { CHANNELS, REPONSE_TYPE, ROUTES } from "consts"
import _ from "lodash"
import * as eventsManager from "modules/eventsManager"
import { error, log, warn } from "modules/logs"
import * as utils from "modules/utils"
import { cdDebugKeys } from "modules/utils"
import * as deviceMock from "./deviceMock"

let requestPort: any
let userClientDescriptor: any
let userApiVersion: number

const promises: {
  [key: string]: {
    promise?: Promise<any>
    resolve?: (value: any) => void
    reject?: (reason?: any) => void
    condition?: Function
  }
} = {}

export function getClientDescriptor() {
  const cd = { ...userClientDescriptor } || {}

  // Apply overrides for debugging
  if (localStorage.__cdArchitectures) {
    const architectures = _.reduce(
      JSON.parse(localStorage.__cdArchitectures || "{}"),
      (acc, value, key) => {
        if (value) acc.push(key as never)
        return acc
      },
      []
    )
    if (architectures.length > 0) cd.dcsa = architectures
  }

  cdDebugKeys.forEach(({ name, cdName, type }): any => {
    const localStorageKey = `__cd${_.capitalize(name)}`
    if (localStorage[localStorageKey] && type === "number") {
      cd[cdName] = Number(localStorage[localStorageKey])
    } else if (localStorage[localStorageKey]) {
      cd[cdName] = localStorage[localStorageKey]
    }
  })

  return cd
}

/**
 * gets the userApiVersion retrived in {@link handleCommunitcationInit}
 * @returns {number} - the api version
 */
export function getUserApiVersion(): number {
  return userApiVersion
}

export class NativeClientError extends Error {
  code: number

  constructor(code: number, msg: string) {
    super(msg)
    this.code = code
  }
}

let initPortResolver: Function

export async function init() {
  return new Promise(async resolve => {
    initPortResolver = resolve

    if (utils.isNativeWebview()) {
      window.addEventListener("message", handleCommunicationMessage)
    } else {
      deviceMock.init(handleCommunicationMessage)
    }
  })
}

async function handleCommunicationMessage(event: MessageEvent) {
  const eventData = event.data
  if (!eventData) return

  let communicationChannelName, messageContent
  try {
    const data = JSON.parse(eventData)
    communicationChannelName = data.communicationChannelName
    messageContent = data.messageContent
  } catch (e) {
    warn(`communication :: unknown message recived: ${eventData}`)
    return
  }

  // ignore - Unknown communication channel
  if (communicationChannelName !== CHANNELS.MAIN) {
    warn(
      `communication :: recived message on unknown channel ${communicationChannelName}`
    )
    return
  }

  const { route } = messageContent

  if (route === ROUTES.INIT) {
    const { apiVersion, clientDescriptor } = messageContent
    await handleCommunitcationInit(event, apiVersion, clientDescriptor)
    return initPortResolver()
  }

  const { requestId, data, err } = messageContent

  const [type, id] = requestId.split(":") // "stream:eventName" or "requestID"
  if (type === REPONSE_TYPE.STREAM) {
    // Dispatch events to the rest of the app
    eventsManager.dispatch(id, data)
  }

  // Handle responses for promises
  respondToPendingPromise(requestId, err, data)
}

async function handleCommunitcationInit(
  event: MessageEvent,
  apiVersion: number,
  clientDescriptor: any
) {
  // log(`native > web | init flow.`, { apiVersion, clientDescriptor })

  userApiVersion = apiVersion
  userClientDescriptor = clientDescriptor

  // Store the request port for later use
  if (utils.isIOSWebview()) {
    // @ts-ignore
    requestPort = window.webkit.messageHandlers.main
  } else {
    requestPort = event.ports[0]
  }
}

function respondToPendingPromise(
  requestId: string,
  err: NativeClientError,
  data: any
) {
  if (!requestId || !promises[requestId]) return

  log(
    `native > web | response to pending async call`,
    JSON.stringify({ requestId, err, data })
  )

  const promise = promises[requestId]
  if (!promise) return

  if (err) {
    error(`native > web | ERROR`, { err })
    delete promises[requestId]
    promise.reject && promise.reject(err)
  } else if (promise.condition) {
    // promise has condition for resolving
    log(`found condition for promise resolving`, { requestId })
    const conditionMatch = promise.condition(data)
    if (conditionMatch) {
      log(`condition matched. resolving.`, { requestId })
      delete promises[requestId]
      promise.resolve && promise.resolve(data)
    } else {
      log(`condition is not matched. ignoring.`, { requestId })
    }
  } else {
    delete promises[requestId]
    promise.resolve && promise.resolve(data)
  }
}

export async function send(
  route: string,
  requestId = "",
  data: any = {},
  responseCondition?: Function
): Promise<any | undefined> {
  if (!requestId || !promises[requestId]) {
    log(
      `web > native | route: "${route}" | requestId: ${requestId}" | data: "${JSON.stringify(
        data
      )}" | hasCondition: ${!!responseCondition}`
    )
  }

  if (!requestPort) {
    throw new Error("No request port")
  }

  if (!requestId) {
    requestPort.postMessage(JSON.stringify({ route, requestId, data }))
    return
  }

  if (!promises[requestId]) {
    promises[requestId] = {}
    promises[requestId].promise = new Promise(async (resolve, reject) => {
      promises[requestId].resolve = resolve
      promises[requestId].reject = reject
      promises[requestId].condition = responseCondition

      requestPort.postMessage(JSON.stringify({ route, requestId, data }))
    })
  }
  return promises[requestId].promise
}

export function streamSubscribe(
  route: string,
  eventName: string,
  data: any
): Promise<any> {
  return new Promise(resolve => {
    const requestId = `stream:${eventName}`
    log(
      `web > native | route: "${route}" | requestId: ${requestId}" | data: "${JSON.stringify(
        data
      )}"`
    )

    // Listen for the first event and resolve the promise
    function handleFirstEvent(data: any) {
      log(`web > native | route: "${route}" | first event received`)
      resolve(data)
      eventsManager.removeListener(eventName, handleFirstEvent)
    }
    eventsManager.addListener(eventName, handleFirstEvent)

    // Send the request
    requestPort.postMessage(
      JSON.stringify({
        route,
        requestId,
        data,
      })
    )
  })
}
