import { useEffect } from "react"

export default function useWindowBackNavigation(
  onBack: () => void,
  state: boolean
) {
  useEffect(() => {
    function handlePopState() {
      if (state) {
        window.history.forward()
        onBack()
      }
    }
    window.addEventListener("popstate", handlePopState)

    return () => {
      window.removeEventListener("popstate", handlePopState)
    }
  }, [state])
}
