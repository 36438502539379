import classNames from "classnames";
import { FC, ReactElement, useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { GlobalStateContext } from "./GlobalState";

// routes
import { BottomNavBar } from "components/BottomNavBar";
import { AnimatePresence, motion } from "framer-motion";
import AppIAPStoreCallbackPage from "pages/AppIAPStoreCallbackPage";
import AppIAPStorePage from "pages/AppIAPStorePage";
import { AppInfoPage } from "pages/AppInfoPage";
import ArticlePage from "pages/ArticlePage";
import { CollectionPage } from "pages/CollectionPage";
import { DiscoverPage } from "pages/DiscoverPage";
import { Error404Page } from "pages/Error404Page";
import { SearchPage } from "pages/SearchPage";
import { AboutPage } from "pages/Settings/AboutPage";
import { OpenSourceLicensePage } from "pages/Settings/OpenSourceLicensePage";
import { PrivacyPolicyPage } from "pages/Settings/PrivacyPolicyPage";
import { TermsOfServicePage } from "pages/Settings/TermsOfServicePage";

import SettingsMenu from "components/SettingsMenu";
import { StatusBar } from "components/StatusBar";
import DownloadOverWifiModal from "components/modals/DownloadOverWifiModal";
import InstallPermissionsModal from "components/modals/InstallPermissionsModal";
import InsufficientStorageModal from "components/modals/InsufficientStorageModal";
import NonPartnerInstallModal from "components/modals/NonPartnerInstallModal";
import UnknownErrorModal from "components/modals/UnknownErrorModal";
import { ModalTypes } from "consts";
import PrivacyManagementPage from "pages/Settings/PrivacyManagementPage";
import "./Modal";
import { IapItemOfferDetailsModal } from "./pages/AppIAPStorePage";
import { StripeCheckout } from "./pages/CheckoutForm";

interface AnimatedPageProps {
  el: ReactElement;
  navBar?: boolean;
}

const AnimatedPage: FC<AnimatedPageProps> = ({ el, navBar = true }) => {
  const {
    scrollRestorationRoutes,
    setScrollRestorationRoute,
    scrollContainerRef,
    shouldScroll,
    setShouldScroll,
    setShowBottomNavBar,
    tabId,
  } = useContext(GlobalStateContext);
  const [prevTabId, setPrevTabId] = useState(tabId);

  const location = useLocation();

  useEffect(() => {
    const isTabIdChanged = prevTabId !== tabId;
    if (isTabIdChanged && scrollContainerRef.current) {
      setTimeout(() => {
        scrollContainerRef.current.scrollTo(0, 0);
        setPrevTabId(tabId);
      }, 300);
    }
  }, [tabId, prevTabId]);

  function onAnimationStart(e: any) {
    if (e.opacity === 1) {
      // Page entering - restore scroll pos
      setTimeout(() => {
        if (scrollContainerRef.current) {
          const scrollY = scrollRestorationRoutes[location.pathname] || 0;
          scrollContainerRef.current.scrollTo(0, scrollY);
        }
      }, 1);

      setShowBottomNavBar(navBar);
      setShouldScroll(true); // Restore scroll settings
    } else {
      // Page exited - store scroll pos for later
      let { scrollTop } = scrollContainerRef.current;
      if (scrollTop < 20) scrollTop = null;
      setScrollRestorationRoute(location.pathname, scrollTop);
    }
  }

  return (
    <motion.div
      ref={scrollContainerRef}
      className={classNames("animatedRouteWrapper", {
        withoutScroll: !shouldScroll,
      })}
      initial={{ opacity: 0, transform: "translateX(1rem)" }}
      animate={{ opacity: 1, transform: "translateX(0rem)" }}
      exit={{ opacity: 0, transform: "translateX(-1rem)" }}
      transition={{ duration: 0.25 }}
      onAnimationStart={onAnimationStart}
    >
      {el}
    </motion.div>
  );
};

const AnimatedRouter = () => {
  const location = useLocation();

  // Routes
  const routes = [
    { path: "discover", el: <DiscoverPage /> },
    { path: "search", el: <SearchPage /> },
    { path: "app/:appId", el: <AppInfoPage />, navBar: false },
    { path: "app/:appId/store", el: <AppIAPStorePage />, navBar: true },
    {
      path: "app/:appId/store/on_game",
      el: <AppIAPStorePage />,
      navBar: false,
    },
    {
      path: "app/:appId/store/checkout/:playerUid/:itemId",
      el: <StripeCheckout />,
      navBar: false,
    },
    {
      path: "app/:appId/store/checkout/:playerUid/:itemId/on_game",
      el: <StripeCheckout />,
      navBar: false,
    },
    {
      path: "app/:appId/store/purchase_res/:purchaseId",
      el: <AppIAPStoreCallbackPage />,
      navBar: false,
    },
    {
      path: "app/:appId/store/purchase_res/:purchaseId/on_game",
      el: <AppIAPStoreCallbackPage />,
      navBar: false,
    },
    { path: "articles/:articleId", el: <ArticlePage />, navBar: false },
    { path: "articles/preview", el: <ArticlePage />, navBar: false },
    { path: "collection/:collectionId", el: <CollectionPage /> },

    // settings
    { path: "terms_of_service", el: <TermsOfServicePage />, navBar: false },
    { path: "privacy_policy", el: <PrivacyPolicyPage />, navBar: false },
    { path: "about", el: <AboutPage />, navBar: false },
    {
      path: "open_source_license",
      el: <OpenSourceLicensePage />,
      navBar: false,
    },
    {
      path: "privacy_management",
      el: <PrivacyManagementPage />,
      navBar: false,
    },

    // other
    { path: "*", el: <Error404Page /> },
  ];

  console.log("hi from App.tsx  " + location.pathname);
  return (
    <div className="page-content">
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={<Navigate to="/discover" replace={true} />}
          />

          {/* @ts-ignore */}
          {routes.map(({ path, el, navBar }) => (
            <Route
              key={path}
              path={path}
              element={<AnimatedPage el={el} navBar={navBar} />}
            />
          ))}
        </Routes>
      </AnimatePresence>
    </div>
  );
};

const Modals: FC = () => {
  const { currentModal, currentModalParams = {} } =
    useContext(GlobalStateContext);

  return (
    <>
      {currentModal === ModalTypes.INSTALL_PERMISSIONS && (
        <InstallPermissionsModal {...currentModalParams} />
      )}
      {currentModal === ModalTypes.INSUFFICIENT_STORAGE && (
        <InsufficientStorageModal {...currentModalParams} />
      )}
      {currentModal === ModalTypes.DOWNLOAD_OVER_WIFI && (
        <DownloadOverWifiModal {...currentModalParams} />
      )}
      {currentModal === ModalTypes.UNKNOW_ERROR && (
        <UnknownErrorModal {...currentModalParams} />
      )}
      <IapItemOfferDetailsModal />
      <NonPartnerInstallModal />
    </>
  );
};

export default function App() {
  return (
    <AnimatePresence mode="wait">
      (
      <>
        <StatusBar />
        <AnimatedRouter />
        <BottomNavBar />
        <SettingsMenu />
        <Modals />
      </>
      )
    </AnimatePresence>
  );
}
