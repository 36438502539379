import { Button } from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import classNames from "classnames";
import Icon from "components/Icon";
import { CSSProperties } from "react";
import { generateDataTestId } from "../modules/utils";
import colors from "../styles/colors";
import css from "./CTABtn.module.scss";

interface CTABtnProps {
  children: any;
  wide?: boolean;
  type?: "large" | "small";
  iconLeft?: string;
  iconRight?: string;
  onClick?: Function;
  onClickDelay?: number;
  style?: CSSProperties;
  className?: string;
  iconSize?: number;
  dataTestId?: string;
  isDisabled?: boolean;
}

export default function CTABtn({
  children,
  wide = false,
  type = "small",
  iconLeft,
  iconRight,
  onClick,
  onClickDelay = 200,
  style,
  className,
  iconSize = 22,
  dataTestId,
  isDisabled,
}: CTABtnProps) {
  // Give the Ripple a chance to show before moving on
  function handleOnClick() {
    if (!onClick) return;

    if (onClickDelay > 0) {
      setTimeout(onClick, onClickDelay);
    } else {
      onClick();
    }
  }

  return (
    <Button
      type={onClick ? "button" : "submit"}
      className={classNames(className, css.ctaBtn, css[type], "ellipsis", {
        [css.wide]: wide,
        [css.iconOnly]: !children,
        [css.isDisabled]: isDisabled,
      })}
      onClick={handleOnClick}
      style={style}
      data-testid={generateDataTestId({ prefix: "CTABtn", dataTestId })}
    >
      {iconLeft && <Icon name={iconLeft} size={iconSize} />}
      {children && children}
      {iconRight &&
        (iconRight.includes("progress") ? (
          <CircularProgress
            variant={"indeterminate"}
            sx={{
              color: colors.white,
              animationDuration: "550ms",
              position: "absolute",
              right: 16,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: "round",
              },
            }}
            size={iconSize}
            thickness={3.5}
          />
        ) : iconRight.includes("/img/") ? (
          <img
            style={{ position: "absolute", right: "16px" }}
            src={iconRight}
            width={iconSize}
            height={iconSize}
          />
        ) : (
          <Icon name={iconRight} size={iconSize} />
        ))}
    </Button>
  );
}
