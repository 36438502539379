import { createTheme } from "@mui/material/styles";

import "./base.scss"; // base and global overrides
import "./mui-overrides.scss"; // utilities for alignment
import "./simple-flex.scss"; // utilities for alignment

// Build and customize the theme using:
// https://bareynol.github.io/mui-theme-creator/
export const themeOptions = {
  palette: {
    type: "dark",
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#3a2f6d",
    },
    background: {
      default: null,
      paper: "#2d2552",
    },
    text: {
      primary: "#9b96bb",
      secondary: "#d5ceff",
      disabled: "#9b96bb",
    },
    divider: "#2d2552",
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    fontSize: 12,
    body1: {
      fontSize: 12,
    },
    body2: {
      fontSize: "0.8rem",
    },
  },
  shape: {
    borderRadius: 12,
  },
  overrides: {
    MuiButton: {
      root: {
        background: "linear-gradient(180deg, #6877FF 0%, #5E4DF0 100%)",
        border: 0,
        borderRadius: "1rem",
        color: "white",
        height: "2rem",
        padding: "0 2rem",
      },
    },
  },
}

export default createTheme(themeOptions)
