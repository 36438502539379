import Box from "@mui/material/Box"
import CircularProgress, {
  CircularProgressProps,
  circularProgressClasses,
} from "@mui/material/CircularProgress"
import colors from "styles/colors"

export default function ProgressBar(props: CircularProgressProps) {
  const variant = props.variant || "indeterminate"
  const thickness = props.thickness || 4
  const size = props.size || 40

  return (
    <Box sx={{ position: "relative" }} data-testid="progress-bar">
      <CircularProgress
        variant="determinate"
        sx={{
          color: colors.secondary,
          position: "absolute",
          top: 0,
          left: 0,
        }}
        size={size}
        thickness={thickness}
        {...props}
        value={100}
      />

      <CircularProgress
        variant={variant}
        sx={{
          color: colors.primary,
          animationDuration: "550ms",
          position: "absolute",
          top: 0,
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={size}
        thickness={thickness}
        {...props}
      />
    </Box>
  )
}
