import { Chip } from "@mui/material"
import { useState } from "react"
import colors from "styles/colors"
import texts from "styles/texts"

export default function AppCardDebug({ app }: { app: any }) {
  const [fullView, setFullView] = useState(false)

  return <div
    style={{
      position: "absolute",
      bottom: 4,
      left: 4,
      fontSize: 10,
      padding: 4,
      color: "black",
      backgroundColor: "rgba(255,255,255,0.95)",
      borderRadius: 5,
      boxShadow: "0 0 5px 1px rgba(0,0,0,0.7)",
    }}
    onClick={() => setFullView(!fullView)}
  >
    <div style={{ display: "flex", gap: 4 }}>
      <Chip
        label={app.id}
        size="small"
        sx={{
          font: texts.caption,
          textTransform: "capitalize",
          background: colors.yellow,
          color: colors.black90,
          height: 16,
        }}
      />
      {app.isPartnerApp && <Chip
        label={"P"}
        size="small"
        sx={{
          font: texts.caption,
          textTransform: "capitalize",
          wordWrap: "break-word",
          background: colors.blue,
          color: colors.textWhite,
          height: 16,
        }}
      />}
      {app.campaign && <Chip
        label={fullView ? `Campaign Id: ${app.campaign.id}` : "C"}
        size="small"
        sx={{
          font: texts.caption,
          textTransform: "capitalize",
          background: colors.secondary,
          color: colors.textWhite,
          height: 16,
        }}
      />}
      {app.isSpecific && <Chip
        label={"S"}
        size="small"
        sx={{
          font: texts.caption,
          textTransform: "capitalize",
          background: colors.secondary,
          color: colors.textWhite,
          height: 16,
        }}
      />}
    </div>
    {fullView &&
      <>
        <div style={{ maxWidth: 150 }} className="ellipsis">
          {app.packageName}
        </div>
        {app.versionCode && <div>Delivery versionCode: {app.versionCode}</div>}
      </>
    }
  </div>
}