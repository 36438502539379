import { reportConversion } from "modules/reporting"
import { useEffect, useState } from "react"
import { IApp, AppInfo, ICampaign } from "types"

export default function useReportGamePageEvents({
  app,
  campaign,
}: {
  app?: IApp | AppInfo
  campaign?: ICampaign
}) {
  const [enterPageTime] = useState(new Date())

  useEffect(() => {
    if (app) {
      reportConversion(app.packageName, "game page shown", { app, campaign })
    }

    return () => {
      if (app) {
        reportConversion(app.packageName, "game page leave", {
          app,
          campaign,
          event_duration: new Date().getTime() - enterPageTime.getTime(),
        })
      }
    }
  }, [app, enterPageTime])
}
