export enum EVENTS {
  DELIVERY_STATUS = "deliveriesStatus",
  PACKAGES_STATUS = "packagesStatus",
  MODAL_OPEN = "modalOpen",
  MODAL_CLOSE = "modalClose",
}

export enum DELIVERY_STATES {
  sentToNative = "sentToNative",
  queued = "queued",
  failed = "failed",
  downloading = "downloading",
  installing = "installing",
  installed = "installed",
  launched = "launched",
  update = "update",
  cancelled = "cancelled",
  error = "error",
}

export enum ERROR_CODES {
  MALFORMED_REQUEST = 100,
  UNKNOWN_ROUTE = 101,
  DELIVERY_ERROR = 200,
  INSUFFICIENT_STORAGE = 201,
}

export enum CHANNELS {
  MAIN = "main_communication_channel",
}

export enum ROUTES {
  INIT = "init",
  OPEN_URL = "nav/openUrl",
  DEVICE_NETWORK = "device/network",
  PACKAGE_DELIVER = "packages/deliveries/deliver",
  PACKAGE_DELIVERY_CANCEL = "packages/deliveries/cancel",
  PACKAGE_LAUNCH = "packages/launch",
  ANALYTICS_EVENT_REPORT = "analytics/events/report",
  ANALYTICS_CONVERSION_REPORT = "analytics/conversions/report",
  ANALYTICS_CONVERSION_ADD_MAPPING = "analytics/conversions/addMapping",
  ANALYTICS_USER_ADD_DIMENSION = "analytics/user/addDimension",
  ANALYTICS_SESSION_ADD_DIMENSION = "analytics/session/addDimension",
  PERMISSION_STATUS = "permission/status",
  PERMISSION_GRANT = "permission/grant",
  CLOSE_WINDOW = "window/close",
  OPEN_STORE = "nav/openStore",
  DISPLAY_INFO = "display/getInfo",
}

export enum REPONSE_TYPE {
  STREAM = "stream",
}

export enum STREAMS {
  PACKAGE_STATUS = "packages/status/subscribe",
  PACKAGE_DELIVERY_STATUS = "packages/deliveries/status/subscribe",
}

export enum PERMISSION {
  INSTALL = "install",
}

export enum PERMISSION_STATUS {
  GRANTED = "granted",
}

export enum Layout {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
  CHART = "chart",
}
export enum CardType {
  DEFAULT = "default",
  LARGE = "large",
}

export enum TabLayout {
  DEFAULT = "default",
  TABS = "tabs",
}

export enum PodiumType {
  first = "first",
  second = "second",
  third = "third",
}

export enum ModalTypes {
  INSTALL_PERMISSIONS = "InstallPermissions",
  INSUFFICIENT_STORAGE = "InsufficientStorage",
  DOWNLOAD_OVER_WIFI = "DownloadOverWifi",
  UNKNOW_ERROR = "UnknownError",
  NON_PARTNER_INSTALL = "NonPartnerInstall",
  IAP_ITEM_OFFER_DETAILS_MODAL = "IapItemOfferDetailsModal",
}

export enum ArticleContentType {
  QUICK_SUMMARY = "QuickSummary",
  GAME_FEATURES = "GameFeatures",
  BOTTOM_LINE = "BottomLine",
  VIDEO = "Video",
  SCREENSHOTS = "Screenshots",
  APP_CARD = "AppCard",
  APP_SCREENSHOTS = "AppScreenshots",
  APP_BLOCK = "AppBlock",
  APP_VIDEO = "AppVideo",
  APP_CHART = "AppChart",
  RELATED_GAMES = "RelatedGames",
  RELATED_ARTICLES = "RelatedArticles",
}

export enum PlacementType {
  PROMO_CARD = "PROMO_CARD",
  ARTICLE = "ARTICLE",
  APP = "APP",
  VIDEO = "VIDEO",
  AURA_APP_EVENT = "AURA_APP_EVENT",
  SPECIFIC_APP = "SPECIFIC_APP",
}
