import Icon from "components/Icon"
import { AnimatePresence, motion } from "framer-motion"
import useWindowBackNavigation from "hooks/useWindowBackNavigation"
import * as Communication from "modules/communication/communication"
import { generateDataTestId } from "modules/utils"
import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import colors from "styles/colors"
import { t } from "translations"
import { Column } from "./Layout"
import css from "./SettingsMenu.module.scss"
import { Subtitle, Title } from "./base/Typography"

/**
 * The minimum user api version that supports the privacy management page
 * see {@link Communication.getUserApiVersion} for more details
 */
const gdprSupportMinUserApiVersion = 10200

type Link = { to: string, label: string, icon: string, type?: string }
type Separator = { type: string }

const SettingsMenu = () => {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [currentPath, setCurrentPath] = useState(location.pathname)
  const navigate = useNavigate()

  const showSettingsMenu = searchParams.get("showSettingsMenu") === 'true'

  const handleClose = () => navigate(location.pathname, { replace: true })

  useWindowBackNavigation(handleClose, showSettingsMenu)

  // When pressing links in the menu we should close it
  useEffect(() => {
    if (currentPath !== location.pathname) {
      setCurrentPath(location.pathname)
    }
  }, [location])

  const userApiVersion = Communication.getUserApiVersion()

  const links: (Link | Separator)[] = [
    { to: "/terms_of_service", label: "Terms & Conditions", icon: "terms" },
    { type: "separator" },
    { to: "/privacy_policy", label: "Privacy Policy", icon: "privacy" },
    { type: "separator" },
    { to: "/about", label: "About", icon: "read" },
    ...(userApiVersion < gdprSupportMinUserApiVersion ?
      [] :
      [
        { type: "separator" },
        { to: "/privacy_management", label: "Privacy Management", icon: "info" }
      ]),
  ]

  return (
    <AnimatePresence>
      {showSettingsMenu && (
        <div className={css.settingsMenu} data-testid="settings-menu">
          <motion.div
            key="wash"
            className={css.wash}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
            onClick={handleClose}
          />

          <motion.div
            key="menu"
            className={css.menu}
            initial={{ x: 238 }}
            animate={{ x: 0 }}
            exit={{ x: 238 }}
            transition={{ duration: 0.3 }}
          >
            <Icon
              style={{ alignSelf: "flex-end" }}
              name="close"
              size={22}
              onClick={handleClose}
              dataTestId="settings-menu-button-close"
            />

            <Column gap={24}>
              <Title variant="h3" size="s">{t("settings_menu.title")}</Title>
              <Column gap={16}>
                {links.map((link, i) => {
                  if (link.type === "separator")
                    return <div className={css.separator} key={i} />

                  const { to, icon, label } = link as Link
                  return (
                    <Link
                      sf="msb"
                      to={to}
                      key={i}
                      data-testid={generateDataTestId({ prefix: 'settings-menu-button', properties: [label] })}
                    >
                      <Icon
                        name={icon}
                        bg="circle"
                        bgColor={colors.white10}
                        size={25}
                      />
                      <Subtitle size="m" variant="h5" col={1} style={{ marginLeft: 12 }}>{label}</Subtitle>
                      <Icon name="chevron-right" size={25} />
                    </Link>
                  )
                })}
              </Column>
            </Column>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}

export default SettingsMenu
