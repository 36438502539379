import CTABtn from "components/CTABtn"
import Img from "components/Img"
import { Column, Row } from "components/Layout"
import * as gamesAPI from "modules/gamesAPI"
import { getIapItems, getPurchase, Purchase, StoreItem } from "modules/paymentsApi";
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useAsync, useEffectOnce } from "react-use";
import { IApItemCardLarge } from "./AppIAPStorePage"
import colors from "../styles/colors"
import { Subtitle, Title } from "../components/base/Typography"
import { closeWindow, packageLaunch } from "../modules/nativeAPI";

export default function AppIAPStoreCallbackPage() {
  const queryParams = new URLSearchParams(window.location.search);

  const navigate = useNavigate();
  const { appId, purchaseId } = useParams();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const success = params.get("success") === "true";
  const canceld = params.get("canceld") === "true";
  const iapItemId = parseInt(params.get("iapItemId") as string);
  const isOnGame = useLocation().pathname.includes("on_game");
  const isVipStore = queryParams.get("vipStore") === "true";
  useEffectOnce(() => {
    sessionStorage.purchaseSucceeded = "true";
  });

  // Load app info from API
  const {
    loading,
    error,
    value,
  } = useAsync(async () => {

    const app = await gamesAPI.getAppInfo(appId as string)
    const items: StoreItem[] = await getIapItems(parseInt(appId!), isVipStore);
    const purchase: Purchase = await getPurchase(purchaseId!);
    const item = items.find((item: StoreItem) => item.id === iapItemId)

    const paymentSucceededAt = purchase?.paymentSucceededAt

    const date = paymentSucceededAt
      ? new Date(Date.parse(purchase?.paymentSucceededAt))
      : new Date(); 
    const dateString = date.toLocaleTimeString("en-GB", {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "Asia/Jerusalem" , // current timezone
    });

    return { app, item , dateString}
  })

  const app = value?.app
  const item = value?.item
  return (
    <>
      {error ? (
        <div>Error: {error.message}</div>
      ) : (
        <Column align="m" col={1} gap={24} padding="24px">
          <Column align="m" gap={16}>
            <Img
              threshold={0}
              src={"/img/purchase_success_logo.png"}
              style={{
                width: "102px",
                height: "72px",
                background: colors.transparent,
              }}
            />

            {success ? (
              <>
                <Column gap={8} align={"mc"}>
                  <Title size={"s"}>Purchase Successful!</Title>
                  <Subtitle
                    size={"m"}
                    style={{ color: colors.textLight, textAlign: "center" }}
                  >
                    You can go back to the game, your purchase is waiting for
                    you there
                  </Subtitle>
                </Column>
              </>
            ) : canceld ? (
              <h1>Purchase Canceld!</h1>
            ) : (
              <h1>Purchase Failed!</h1>
            )}

            <IApItemCardLarge
              item={item!}
              onCardClicked={() => navigate(`/app/${appId}/store`)}
              buyBtnDisabled={true}
              showTag={false}
              appId={appId as string}
            />
          </Column>
          <Column style={{ width: "100%" }} gap={16}>
            <img
              src={"/img/dashed_line.svg"}
              alt={"dashed line"}
              style={{ width: "100%" }}
            />
            <Row
              align={"tsb"}
              style={{
                width: "100%",
              }}
            >
              <Subtitle size={"s"}>Credit Card</Subtitle>
              <Subtitle size={"s"} style={{ color: colors.textLight }}>
                **** **** **** 5678
              </Subtitle>
            </Row>

            <Row
              align={"tsb"}
              style={{
                width: "100%",
              }}
            >
              <Subtitle size={"s"}>Total</Subtitle>
              <Subtitle size={"s"} style={{ color: colors.textLight }}>
                ${item?.price}
              </Subtitle>
            </Row>
            <img
              src={"/img/dashed_line.svg"}
              alt={"dashed line"}
              style={{ width: "100%" }}
            />

            <Row
              align={"tsb"}
              style={{
                width: "100%",
              }}
            >
              <Subtitle size={"s"}>Date</Subtitle>
              <Subtitle size={"s"} style={{ color: colors.textLight }}>
                {value?.dateString}
              </Subtitle>
            </Row>
            <Row
              align={"tsb"}
              style={{
                width: "100%",
              }}
            >
              <Subtitle size={"s"}>Shop Name</Subtitle>
              <Subtitle size={"s"} style={{ color: colors.textLight }}>
                {app?.locale.title}
              </Subtitle>
            </Row>
          </Column>
          <CTABtn
            type={"large"}
            style={{ width: "100%" }}
            onClick={async () =>
              isOnGame ? closeWindow() : packageLaunch("com.dcohenb.Barbie")
            }
          >
            {isOnGame ? "Back To Game" : "Launch Sweet Sugar"}
          </CTABtn>
        </Column>
      )}
    </>
  );
}
