import CTABtn from "components/CTABtn"
import { Column, Row } from "components/Layout"
import Modal from "react-modal"
import colors from 'styles/colors'

export default function DownloadOverWifiModal() {
  function installClick() {

  }

  function laterClick() {

  }

  return <Modal
    isOpen={true}
    shouldCloseOnOverlayClick={false}
    style={{ content: { textAlign: "center" } }}
  >
    <Row align="mc" style={{ height: '100%' }} padding="2rem">
      <Column align="mc" style={{ textAlign: 'center', maxWidth: 260 }} gap="1rem">
        <h3>This game is over 150 MB</h3>
        <h4 style={{ color: colors.textMedium }}>Do you still want to install it using your Cellular Data?</h4>

        <Row gap="1rem">
          <CTABtn type="large" onClick={installClick}>Install</CTABtn>
          <CTABtn type="large" onClick={laterClick}>Later On WiFi</CTABtn>
        </Row>
      </Column>
    </Row>
  </Modal>
}