import classNames from "classnames"
import { generateDataTestId } from "modules/utils"
import { CSSProperties, FC, useEffect, useRef, useState } from "react"
import colors from "styles/colors"
import css from "./Img.module.scss"
import Skeleton from "./Skeleton"

interface ImgProps {
  src?: string
  fallbackImg?: string
  width?: number
  height?: number
  alt?: string
  className?: string
  style?: CSSProperties
  dataTestId?: string
  threshold?: number
  onClick?: () => void
}

const Img: FC<ImgProps> = ({
  src,
  fallbackImg,
  width,
  height,
  alt = "",
  className,
  style = {},
  dataTestId,
  threshold = 0.5,
  onClick,
}) => {
  const imgRef = useRef<HTMLImageElement>(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [imgSrc, setImgSrc] = useState<string>()

  useEffect(() => {
    const imgEl = imgRef?.current

    if (!imgEl) return

    const observer = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        if (isIntersecting && !imgSrc) {
          setImgSrc(src)
        }
      },
      { threshold }
    )

    observer.observe(imgEl)

    // 👇️ remove the event listener when component unmounts
    return () => {
      observer.unobserve(imgEl)
    }
  }, [src, imgRef])

  if (!src) {
    return <Skeleton
      style={style}
      variant="rounded"
      width={width || "100%"}
      height={height || "100%"}
      dataTestId={dataTestId}
      sx={{ borderRadius: 'unset' }}
    />
  }

  return (
    <img
      ref={imgRef}
      src={
        imgSrc ||
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
      }
      alt={alt}
      onLoad={() => {
        if (imgSrc) setIsLoaded(true)
      }}
      onError={() => setImgSrc(fallbackImg)}
      className={classNames(css.img, className)}
      style={{
        background: colors.white20,
        ...style,
        opacity: imgSrc && isLoaded ? 1 : 0,
      }}
      width={width}
      height={height}
      referrerPolicy="no-referrer"
      data-testid={generateDataTestId({ prefix: "img", dataTestId })}
      onClick={onClick}
    />
  )
}

export default Img
