import CTABtn from "components/CTABtn"
import DebugPanel from "components/debugging/DebugPanel"
import Icon from "components/Icon"
import { Column, Row } from "components/Layout"
import useReportPageEvents from "hooks/useReportPageEvents"
import * as gamesAPI from "modules/gamesAPI"
import * as utils from "modules/utils"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useList } from "react-use"
import colors from "styles/colors"

export function AboutPage() {
  const [debugClickSequence, { push, reset }] = useList([]);
  const [showDebugPanel, setShowDebugPanel] = useState(false)
  const navigate = useNavigate()
  useReportPageEvents()

  useEffect(() => {
    if (utils.isProd()) {
      // in prod we have a special sequence to open the debug panel
      setShowDebugPanel(debugClickSequence.slice(-6).join(',') === "logo,logo,logo,version,version,logo")
    } else {
      // Double tap to open in dev
      setShowDebugPanel(debugClickSequence.length >= 2)
    }
  }, [debugClickSequence])

  return (
    <Column style={{ height: "100%", position: "relative" }}>
      <Row align="ml" padding="0 1rem" gap="1rem" style={{ height: 70 }}>
        <Icon name="arrow-left" onClick={() => navigate(-1)} />
        <h3>About</h3>
      </Row>

      <Column align="mc" style={{ flex: 1 }} gap={24}>
        <img
          src="/img/logo.svg"
          alt="Logo"
          onClick={() => push('logo' as never)}
        />

        <h5 style={{ color: colors.textMedium, width: '100%', textAlign: 'center' }} data-testid="version">
          Version {process.env.REACT_APP_VERSION}
        </h5>

        <CTABtn
          onClick={() => navigate("/open_source_license")}
          style={{ background: colors.secondary }}
          dataTestId="licenses"
        >
          Licenses
        </CTABtn>
      </Column>

      <Column align="mc" style={{ color: colors.textMedium, height: 70 }} dataTestId="version-code">
        <small onClick={() => push('version' as never)}>Version Code: {gamesAPI.versionCode()}</small>
      </Column>

      {showDebugPanel && <DebugPanel onClose={() => {
        console.log("DebugPanel onClose")
        reset()
      }} />}
    </Column>
  )
}
