import { GlobalStateContext } from "GlobalState"
import { RefObject, useContext, useEffect, useState } from "react"

export default function useScrollTopSnap(elRef: RefObject<HTMLDivElement>, isElRefInitialized: boolean) {
  const { scrollContainerRef } = useContext(GlobalStateContext)
  const [headTopSnap, setHeadTopSnap] = useState<boolean>()

  useEffect(() => {
    const scrollContainerEl = scrollContainerRef?.current
    const el = elRef?.current

    if (!scrollContainerEl || !el) return

    const updatePosition = () => {
      const scrollContainerY = scrollContainerEl.getBoundingClientRect().y
      const elTop = el.getBoundingClientRect().top
      const top = elTop <= scrollContainerY
      setHeadTopSnap(top)
    }

    scrollContainerEl.addEventListener("scroll", updatePosition)
    updatePosition()

    // 👇️ remove the event listener when component unmounts
    return () => {
      scrollContainerEl.removeEventListener("scroll", updatePosition)
    }
  }, [isElRefInitialized])

  return headTopSnap
}
